import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Home from './home';
import AvatarPage from './avatar';
import FaceclaimPage from './faceclaim';

const Test = (): JSX.Element => {
  return (
    <>Ceci est un test</>
  );
};

const App = (): JSX.Element => {
  return (
    <BrowserRouter basename="">
      <Switch>
        <Route path="/avatars" component={AvatarPage} />
        <Route path="/faceclaims" component={FaceclaimPage} />
        {/* <Route path="/test" component={Home} /> */}
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
