import React from 'react';
import styled from 'styled-components';

export interface TagProps {
  tag: string,
}

const Tag = ({tag}:TagProps): JSX.Element => {
  const getTagName = (name: string) => {
    let str = name;

    str = str.replace('c-', 'cheveux ');
    str = str.replace('y-', 'yeux ');
    if (!str.endsWith('s')) {
      str = str + 's';
    }
    return (str);
  }
  const renderTag = () => {
    switch (true) {
      case (tag === 'c-noir' || tag === 'y-noir'): return <div className="tag noir">{getTagName(tag)}</div>;
      case (tag === 'c-brun' || tag === 'y-marron'): return <div className="tag marron">{getTagName(tag)}</div>;
      case (tag === 'c-blond' || tag === 'y-jaune'): return <div className="tag jaune">{getTagName(tag)}</div>;
      case (tag === 'c-blanc' || tag === 'y-blanc'): return <div className="tag blanc">{getTagName(tag)}</div>;
      case (tag === 'c-rose' || tag === 'y-rose'): return <div className="tag rose">{getTagName(tag)}</div>;
      case (tag === 'c-violet' || tag === 'y-violet'): return <div className="tag violet">{getTagName(tag)}</div>;
      case (tag === 'c-bleu' || tag === 'y-bleu'): return <div className="tag bleu">{getTagName(tag)}</div>;
      case (tag === 'c-roux' || tag === 'y-orange'): return <div className="tag orange">{getTagName(tag)}</div>;
      case (tag === 'c-vert' || tag === 'y-vert'): return <div className="tag vert">{getTagName(tag)}</div>;
      case (tag === 'c-gris' || tag === 'y-gris'): return <div className="tag gris">{getTagName(tag)}</div>;
      case (tag === 'c-rouge' || tag === 'y-rouge'): return <div className="tag rouge">{getTagName(tag)}</div>;
      case (tag === 'fantastique' || tag === 'moderne'): return <div className="tag gris">{`style ${tag}`}</div>;
      case (tag === 'clair'): return <div className="tag beige">{`peau claire`}</div>;
      case (tag === 'sombre'): return <div className="tag marron">{`peau ${tag}`}</div>;
      case (tag === 'coloree'): return <div className="tag rose">{`peau colorée`}</div>;
      case (tag === 'oreilles'): return <div className="tag gris">{`oreilles pointues`}</div>;
      case (tag === 'animal'): return <div className="tag gris">{`traits animaliers`}</div>;
      case (tag === 'cacheoeil'): return <div className="tag gris">{`cache-œil`}</div>;
      case (tag === 'longs' || tag === 'courts' || tag === 'moyens'): return <div className="tag gris">{`cheveux ${tag}`}</div>;
      default: return <div className="tag gris">{tag}</div>;
    }
  };
  return (
    <>
      { renderTag() }
    </>
  );
};

export default Tag;