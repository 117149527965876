import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Tag from '../../components/tags';
import Partenariats from '../../components/partenariats';
import { charactersData, getNbOfFaceclaims } from '../../data/data';
import Isotope from 'isotope-layout';
import $ from 'jquery';
import './index.css';

const FaceclaimPage = (): JSX.Element => {
  const [isotope, setIsotope] = React.useState(null);
  const [filterKeys, setFilterKeys] = React.useState({
    format: '*',
    genre: '*',
    age: '*',
    peau: '*',
    couleur_cheveux: '*',
    couleur_yeux:'*',
    cheveux: '*',
    style: '*',
    caracteristiques: '*',
  });

  useEffect(() => {
    let grid = document.querySelector('.grid');
    // @ts-ignore
    let iso = new Isotope( grid, {
      itemSelector: '.grid-item',
      getSortData: {
        ava: '.nb-ava parseInt',
        name: function (elem) {
          var name = $(elem).find('.name').text();
          var t = name.replace(/\s/g, '');
          return t.replace( /[\(\)]/g, '');
        },
        // source: '.source',
        source: function (elem) {
          var source = $(elem).find('.source').text();
          var t = source.replace(/\s/g, '');
          return t.replace( /[\(\)]/g, '');
        },
      },
    });
    // @ts-ignore
    setIsotope(iso);
    iso.arrange({
      sortBy : ['name', 'source'],
      sortAscending: true,
      // stagger: 10,
    })
  }, []);

  const onFilterClick = (groupKey: string, value: string) => {
    let filters = {...filterKeys};
    // @ts-ignore
    filters[groupKey] = value;
    setFilterKeys(filters);
    console.log('value is: ', value);
    let valuefilters = `${filters.genre}${filters.format}${filters.age}${filters.cheveux}${filters.peau}${filters.style}${filters.couleur_cheveux}${filters.couleur_yeux}${filters.caracteristiques}`;
    valuefilters = valuefilters.replace(/\*/g, '');
    console.log('valuefilters: ', valuefilters);
    if (valuefilters === '') {
      valuefilters = '*';
    }
    console.log('setting to: ', valuefilters);
    // @ts-ignore
    isotope.arrange({ filter: `${valuefilters}`});
  };

  const sortItems = (sortType: string) => {
    switch (sortType) {
      // @ts-ignore
      case 'source': isotope.arrange({sortBy: ['source', 'name']}); break;
      // @ts-ignore
      case 'name': isotope.arrange({sortBy: ['name', 'source']}); break;
      // @ts-ignore
      case 'ava': isotope.arrange({sortBy: ['ava', 'source', 'name']}); break;
      // @ts-ignore
      case 'ascending': isotope.arrange({ sortAscending: true}); break;
      // @ts-ignore
      case 'descending': isotope.arrange({ sortAscending: false}); break;
      // @ts-ignore
      case 'random': isotope.reloadItems(); isotope.arrange({ sortBy: 'random'}); break;
      default: console.log('what u doin here');
    }
  };

  return (
    <MainContainer>
      <div className="partenariats">
        <div className="partenariats-bouton">
          ⇵
        </div>
        <div className="partenariats-container">
          <div className="button-group sort-by-button-group">
            <div className="row">
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div className="partenariats-title">Trier par :</div>
                <button className="sort-button" data-sort-by="source" onClick={() => {sortItems('source')}}>Nom de la Source</button>
                <button className="sort-button" data-sort-by="name" onClick={() => {sortItems('name')}}>Nom du Personnage</button>
                <button className="sort-button" data-sort-by="random" onClick={() => {sortItems('random')}}>Aléatoire</button>
              </div>
              <div style={{marginLeft: '50px', display: 'flex', flexDirection: 'column'}}>
                <div className="partenariats-title">Ordre :</div>
                <button className="sort-button" data-sort-by="ascending" onClick={() => {sortItems('ascending')}}>Croissant</button>
                <button className="sort-button" data-sort-by="descending" onClick={() => {sortItems('descending')}}>Décroissant</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partenariats />
      <SidebarContainer>
        <a href="https://hall-of-fame.fr">
          <GoBackLink>
            ⬅ Hall of Fame
          </GoBackLink>
        </a>
        <Title>Répertoire</Title>
        <Description>
        Marre de galérer à trouver des visages pour incarner vos idées ? Ce répertoire de faceclaims est fait pour vous !<br /><br />
        Vous pouvez sélectionner un filtre par catégorie ci-dessous et l'affichage se mettra à jour pour afficher les références correspondant à vos critères.<br /><br />
          <strong>Nombre de références: </strong>{getNbOfFaceclaims()}
        </Description>
        <SidebarOverflow>
          <div className="filters">
            {/* genre */}

            <div className="button-group" data-filter-group="genre">
              <FilterTitle>Genre</FilterTitle>
              <button className={`button ${filterKeys.genre === '*' ? 'is-checked' : ''}`} data-filter="" onClick={() => {onFilterClick('genre', '*')}}>tous</button>
              <button className={`button ${filterKeys.genre === '.homme' ? 'is-checked' : ''}`} data-filter=".homme" onClick={() => {onFilterClick('genre', '.homme')}}>homme</button>
              <button className={`button ${filterKeys.genre === '.femme' ? 'is-checked' : ''}`} data-filter=".femme" onClick={() => {onFilterClick('genre', '.femme')}}>femme</button>
              <button className={`button ${filterKeys.genre === '.non-binaire' ? 'is-checked' : ''}`} data-filter=".non-binaire" onClick={() => {onFilterClick('genre', '.non-binaire')}}>non-binaire</button>
            </div>

            {/* age */}

            <div className="button-group" data-filter-group="age">
              <FilterTitle>Age</FilterTitle>
              <button className={`button ${filterKeys.age === '*' ? 'is-checked' : ''}`} data-filter="" onClick={() => {onFilterClick('age', '*')}}>tous</button>
              <button className={`button ${filterKeys.age === '.enfant' ? 'is-checked' : ''}`} data-filter=".enfant" onClick={() => {onFilterClick('age', '.enfant')}}>enfant</button>
              <button className={`button ${filterKeys.age === '.jeune' ? 'is-checked' : ''}`} data-filter=".jeune" onClick={() => {onFilterClick('age', '.jeune')}}>jeune</button>
              <button className={`button ${filterKeys.age === '.mature' ? 'is-checked' : ''}`} data-filter=".mature" onClick={() => {onFilterClick('age', '.mature')}}>mature</button>
            </div>

            {/* peau */}

            <div className="button-group" data-filter-group="peau">
              <FilterTitle>Couleur de peau</FilterTitle>
              <button className={`button ${filterKeys.peau === '*' ? 'is-checked' : ''}`} data-filter="" onClick={() => {onFilterClick('peau', '*')}}>tous</button>
              <button className={`button ${filterKeys.peau === '.clair' ? 'is-checked' : ''}`} data-filter=".clair" onClick={() => {onFilterClick('peau', '.clair')}}>claire</button>
              <button className={`button ${filterKeys.peau === '.sombre' ? 'is-checked' : ''}`} data-filter=".sombre" onClick={() => {onFilterClick('peau', '.sombre')}}>sombre</button>
              <button className={`button ${filterKeys.peau === '.coloree' ? 'is-checked' : ''}`} data-filter=".coloree" onClick={() => {onFilterClick('peau', '.coloree')}}>colorée</button>
            </div>

            {/* cheveux */}

            <div className="button-group" data-filter-group="couleur_cheveux">
              <FilterTitle>Couleur des cheveux</FilterTitle>
              <CircleContainer>
                <button className={`button ${filterKeys.couleur_cheveux === '*' ? 'is-checked' : ''}`} data-filter="" onClick={() => {onFilterClick('couleur_cheveux', '*')}}>
                  <div className="multicolor color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_cheveux === '.c-noir' ? 'is-checked' : ''}`} data-filter=".c-noir" onClick={() => {onFilterClick('couleur_cheveux', '.c-noir')}}>
                  <div className="color-circle noir"></div>
                </button>
                <button className={`button ${filterKeys.couleur_cheveux === '.c-blanc' ? 'is-checked' : ''}`} data-filter=".c-blanc" onClick={() => {onFilterClick('couleur_cheveux', '.c-blanc')}}>
                  <div className="blanc color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_cheveux === '.c-gris' ? 'is-checked' : ''}`} data-filter=".c-gris" onClick={() => {onFilterClick('couleur_cheveux', '.c-gris')}}>
                  <div className="gris color-circle"></div>
                </button><br />
                <button className={`button ${filterKeys.couleur_cheveux === '.c-blond' ? 'is-checked' : ''}`} data-filter=".c-blond" onClick={() => {onFilterClick('couleur_cheveux', '.c-blond')}}>
                  <div className="jaune color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_cheveux === '.c-roux' ? 'is-checked' : ''}`} data-filter=".c-roux" onClick={() => {onFilterClick('couleur_cheveux', '.c-roux')}}>
                  <div className="orange color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_cheveux === '.c-brun' ? 'is-checked' : ''}`} data-filter=".c-brun" onClick={() => {onFilterClick('couleur_cheveux', '.c-brun')}}>
                  <div className="marron color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_cheveux === '.c-rouge' ? 'is-checked' : ''}`} data-filter=".c-rouge" onClick={() => {onFilterClick('couleur_cheveux', '.c-rouge')}}>
                  <div className="rouge color-circle"></div>
                </button><br />
                <button className={`button ${filterKeys.couleur_cheveux === '.c-vert' ? 'is-checked' : ''}`} data-filter=".c-vert" onClick={() => {onFilterClick('couleur_cheveux', '.c-vert')}}>
                  <div className="vert color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_cheveux === '.c-violet' ? 'is-checked' : ''}`} data-filter=".c-violet" onClick={() => {onFilterClick('couleur_cheveux', '.c-violet')}}>
                  <div className="violet color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_cheveux === '.c-rose' ? 'is-checked' : ''}`} data-filter=".c-rose" onClick={() => {onFilterClick('couleur_cheveux', '.c-rose')}}>
                  <div className="rose color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_cheveux === '.c-bleu' ? 'is-checked' : ''}`} data-filter=".c-bleu" onClick={() => {onFilterClick('couleur_cheveux', '.c-bleu')}}>
                  <div className="bleu color-circle"></div>
                </button><br />
              </CircleContainer>
            </div>

            {/* yeux */}

            <div className="button-group" data-filter-group="couleur_yeux">
              <FilterTitle>Couleur des yeux</FilterTitle>
              <CircleContainer>
                <button className={`button ${filterKeys.couleur_yeux === '*' ? 'is-checked' : ''}`} data-filter="" onClick={() => {onFilterClick('couleur_yeux', '*')}}>
                  <div className="multicolor color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_yeux === '.y-noir' ? 'is-checked' : ''}`} data-filter=".y-noir" onClick={() => {onFilterClick('couleur_yeux', '.y-noir')}}>
                  <div className="color-circle noir"></div>
                </button>
                <button className={`button ${filterKeys.couleur_yeux === '.y-blanc' ? 'is-checked' : ''}`} data-filter=".y-blanc" onClick={() => {onFilterClick('couleur_yeux', '.y-blanc')}}>
                  <div className="blanc color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_yeux === '.y-gris' ? 'is-checked' : ''}`} data-filter=".y-gris" onClick={() => {onFilterClick('couleur_yeux', '.y-gris')}}>
                  <div className="gris color-circle"></div>
                </button><br />
                <button className={`button ${filterKeys.couleur_yeux === '.y-jaune' ? 'is-checked' : ''}`} data-filter=".y-jaune" onClick={() => {onFilterClick('couleur_yeux', '.y-jaune')}}>
                  <div className="jaune color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_yeux === '.y-orange' ? 'is-checked' : ''}`} data-filter=".y-orange" onClick={() => {onFilterClick('couleur_yeux', '.y-orange')}}>
                  <div className="orange color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_yeux === '.y-marron' ? 'is-checked' : ''}`} data-filter=".y-marron" onClick={() => {onFilterClick('couleur_yeux', '.y-marron')}}>
                  <div className="marron color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_yeux === '.y-rouge' ? 'is-checked' : ''}`} data-filter=".y-rouge" onClick={() => {onFilterClick('couleur_yeux', '.y-rouge')}}>
                  <div className="rouge color-circle"></div>
                </button><br />
                <button className={`button ${filterKeys.couleur_yeux === '.y-vert' ? 'is-checked' : ''}`} data-filter=".y-vert" onClick={() => {onFilterClick('couleur_yeux', '.y-vert')}}>
                  <div className="vert color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_yeux === '.y-violet' ? 'is-checked' : ''}`} data-filter=".y-violet" onClick={() => {onFilterClick('couleur_yeux', '.y-violet')}}>
                  <div className="violet color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_yeux === '.y-rose' ? 'is-checked' : ''}`} data-filter=".y-rose" onClick={() => {onFilterClick('couleur_yeux', '.y-rose')}}>
                  <div className="rose color-circle"></div>
                </button>
                <button className={`button ${filterKeys.couleur_yeux === '.y-bleu' ? 'is-checked' : ''}`} data-filter=".y-bleu" onClick={() => {onFilterClick('couleur_yeux', '.y-bleu')}}>
                  <div className="bleu color-circle"></div>
                </button><br />
              </CircleContainer>
            </div>

            {/* cheveux */}
            <div className="button-group" data-filter-group="cheveux">
              <FilterTitle>Cheveux</FilterTitle>
              <button className={`button ${filterKeys.cheveux === '*' ? 'is-checked' : ''}`} data-filter=""  onClick={() => {onFilterClick('cheveux', '*')}}>tous</button>
              <button className={`button ${filterKeys.cheveux === '.longs' ? 'is-checked' : ''}`} data-filter=".longs" onClick={() => {onFilterClick('cheveux', '.longs')}}>longs</button>
              <button className={`button ${filterKeys.cheveux === '.moyens' ? 'is-checked' : ''}`} data-filter=".moyens"  onClick={() => {onFilterClick('cheveux', '.moyens')}}>moyens</button>
              <button className={`button ${filterKeys.cheveux === '.courts' ? 'is-checked' : ''}`} data-filter=".courts"  onClick={() => {onFilterClick('cheveux', '.courts')}}>courts</button>
              <button className={`button ${filterKeys.cheveux === '.chauve' ? 'is-checked' : ''}`} data-filter=".chauve"  onClick={() => {onFilterClick('cheveux', '.chauve')}}>chauve</button>
            </div>

            {/* style */}
            <div className="button-group" data-filter-group="style">
              <FilterTitle>Style vestimentaire</FilterTitle>
              <button className={`button ${filterKeys.style === '*' ? 'is-checked' : ''}`} data-filter=""  onClick={() => {onFilterClick('style', '*')}}>tous</button>
              <button className={`button ${filterKeys.style === '.moderne' ? 'is-checked' : ''}`} data-filter=".moderne" onClick={() => {onFilterClick('style', '.moderne')}}>moderne</button>
              <button className={`button ${filterKeys.style === '.fantastique' ? 'is-checked' : ''}`} data-filter=".fantastique"  onClick={() => {onFilterClick('style', '.fantastique')}}>fantastique / historique</button>
            </div>

            {/* style */}
            <div className="button-group" data-filter-group="caracteristiques">
              <FilterTitle>caractéristiques</FilterTitle>
              <button className={`button ${filterKeys.caracteristiques === '*' ? 'is-checked' : ''}`} data-filter="" onClick={() => {onFilterClick('caracteristiques', '*')}}>tous</button>
              <button className={`button ${filterKeys.caracteristiques === '.oreilles' ? 'is-checked' : ''}`} data-filter=".oreilles" onClick={() => {onFilterClick('caracteristiques', '.oreilles')}}>oreilles pointues</button>
              <button className={`button ${filterKeys.caracteristiques === '.cornes' ? 'is-checked' : ''}`} data-filter=".cornes" onClick={() => {onFilterClick('caracteristiques', '.cornes')}}>cornes</button>
              <button className={`button ${filterKeys.caracteristiques === '.ailes' ? 'is-checked' : ''}`} data-filter=".ailes" onClick={() => {onFilterClick('caracteristiques', '.ailes')}}>ailes</button>
              <button className={`button ${filterKeys.caracteristiques === '.animal' ? 'is-checked' : ''}`} data-filter=".animal" onClick={() => {onFilterClick('caracteristiques', '.animal')}}>traits animaliers</button>
              <button className={`button ${filterKeys.caracteristiques === '.androgyne' ? 'is-checked' : ''}`} data-filter=".androgyne" onClick={() => {onFilterClick('caracteristiques', '.androgyne')}}>androgyne</button>
              <button className={`button ${filterKeys.caracteristiques === '.lunettes' ? 'is-checked' : ''}`} data-filter=".lunettes" onClick={() => {onFilterClick('caracteristiques', '.lunettes')}}>lunettes</button>
              <button className={`button ${filterKeys.caracteristiques === '.cacheoeil' ? 'is-checked' : ''}`} data-filter=".cacheoeil" onClick={() => {onFilterClick('caracteristiques', '.cacheoeil')}}>cacheoeil</button>
            </div>
            
          </div>
        </SidebarOverflow>
      </SidebarContainer>
      <GridContainer>
        <div className="margin-grid">
        <div className="grid">
      {
        charactersData.map((item) => (
          <>{ item.name !== 'oc' && item.name !== 'original characters' && (
            <div className={`grid-item ${item.tags.join(' ')}`}>
              <img src={item.img} />
              <div className="name">
                {item.name}
              </div>
              <span className="source">
              {item.source}
              </span>
            </div>
          )}
          </>
        ))
      }
      </div></div>
      </GridContainer>
    </MainContainer>
  );
};

interface SidepanelProps {
  show: boolean,
}

const CircleContainer = styled.div`
  display: inline-block;
  text-align: center;
`;

const FilterTitle = styled.div`
  background: #f8f8f8;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 15px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  border: 1px solid #f3f3f3;
  padding: 6px;
`;

const SidebarOverflow = styled.div`
  position: absolute;
  width: 200px;
  padding-right: 20px;
  height: calc(100vh - 300px);
  overflow: auto;
`;

const Description = styled.div`
  font-size: 11px;
  text-align: justify;
  margin: 20px 0px;
`;

const Title = styled.div`
  font-family: 'Montserrat',Helvetica,cambria,sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
`;

const GoBackLink = styled.div`
  font-size: 10px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 40px;
  width: 200px;
  height: 100%;
  background: white;
  border: 1px solid #f3f3f3;
  padding-bottom: 300px;

  font-family: 'Open Sans', Helvetica, sans-serif;
  font-weight: 300;

  a {
    text-decoration: none !important;
    color: #828282;
    transition: 0.2s all;
  }

  a:hover {
    color: black;
    transition: 0.2s all;
  }
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 0;
  bottom: 0;
  height: 100vh;
  margin: 0px 0px 0px 280px;
  overflow-y: auto;
  overflow-x: hidden;
  /* background: red; */
  /* padding-bottom: 20px; */
`;

const MainContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  background: #f5f5f5;
  color: black;
`;

export default FaceclaimPage;