import React from 'react';
import '../../pages/avatar/index.css';

const Partenariats = (): JSX.Element => {
  return (
    <div className="partenariats sorting">
      <div className="partenariats-bouton">
        ☆
      </div>
      <div className="partenariats-container">
        <div className="partenariats-title">Partenaires</div>
        <div className="row-container">
          <a href="https://aurumregis.forumactif.com/" target="_blank"><img src="https://i.imgur.com/3z4zUTv.png"/></a>
          <a href="https://chroma.forumsrpg.com/" target="_blank"><img src="https://zupimages.net/up/21/01/6ljh.png"/></a>
          <a href="https://spiritedaway.forumactif.com/" target="_blank"><img src="https://i.imgur.com/NacxZJt.png"/></a>
          <a href="https://mahoutokoro.forumactif.com/"><img src="https://zupimages.net/up/21/08/3lz9.png" /></a><br/>
          <a href="https://sandman.forumactif.com/"><img src="https://i.servimg.com/u/f30/18/70/23/91/tm/50x50_10.png" /></a>
          <a title="kobehs" href="https://www.kobehs.org/" target="_blank"><img src="https://sig.grumpybumpers.com/host/Kobehs50x50.gif" /></a>
          <a href="https://aurora-millenarius.forumactif.org/"><img src="https://i.imgur.com/ArrVQ1d.png" /></a>
          <a href="https://dusk-lumiris.forumactif.com/"><img src="https://www.zupimages.net/up/21/06/qwvt.png"/></a><br/>
          <a href="https://striders.forumactif.com/"><img src="https://i.imgur.com/a4Kz4cZ.png"/></a>
          <a href="https://fe-legions.forumactif.com/" target="blank"><img src="https://www.aht.li/3602617/felparto5050.png"/></a>
          <a href="https://hyleros.forumactif.com/" target="_blank"><img src="https://i.imgur.com/OfaNb6k.png"/></a><br/><br/>
  
          <a target="_blank" href="https://netovia.forumactif.com/">netovia</a> ; 
          <a href="https://le-chat-noir.forumactif.com/forum" target="_blank"> le chat noir</a> ;
          <a href="http://zheroes.forumactif.com" target="_blank">{' (z)heroes'}</a> ;
          <a href="https://damnatio-memoriae.forumsrpg.com/" target="_blank">{' damnatio memoriae'}</a> ;
          <a href="https://swish.forumactif.com/" target="_blank">{' swish'}</a> ;
          <a href="https://exesus.forumactif.com/" target="_blank">{' exesus'}</a> ;
        </div>
      </div>
    </div>
  );
};

export default Partenariats;