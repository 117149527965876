import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export interface imageWithLoadingProps {
  url: string,
  width: string,
  height: string,
}

const ImageWithLoading = ({url, width, height}: imageWithLoadingProps): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.onload = () => setIsLoaded(true);
    image.src = url;
  }, []);

  return (
    <>
      <LoadingBox width={width} height={height} />
      <Pic src={url} width={width} height={height} style={isLoaded ? {opacity: '1'} : { opacity: '0'}} onLoad={() => {setIsLoaded(true)}} />
    </>
  );
};

interface LoadingBoxProps {
  height: string,
  width: string,
}

const Pic = styled.img`
  transition: 0.5s all;
  position: absolute;
  margin-top: ${(props) => `-${props.height}px`};
`;

const LoadingBox = styled.div<LoadingBoxProps>`
  background: #f5f5f5;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
`;

export default ImageWithLoading;