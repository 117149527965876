import React from 'react';

export let charactersData = [
  {
    name: 'he tian',
    source: '19 days',
    nickname: '',
    img: 'https://i.imgur.com/RAYdhdx.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/OWRL9cJ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tRrtkiH.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7R1urM8.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
          link: 'https://i.imgur.com/q36sCER.gif',
          credit: 'cheshireftw',
          source: '',
          source_url: '',
          },
          ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'c-noir',
      'y-marron',
      'moderne'
    ]
  },
  {
    name: 'lumpy space princess',
    source: 'adventure time',
    nickname: '',
    img: 'https://i.imgur.com/3Ilqxs6.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/PU5VCnn.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'coloree',
      'longs',
      'c-violet',
      'y-violet',
      'y-noir',
      'fantastique'
    ]
  },
  {
    name: 'bonnibel bubblegum',
    source: 'adventure time',
    nickname: '',
    img: 'https://i.imgur.com/zk0sEfX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/fd9QueJ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'coloree',
      'longs',
      'c-rose',
      'y-bleu',
      'y-noir',
      'fantastique'
    ]
  },
  {
    name: 'marceline abadeer',
    source: 'adventure time',
    nickname: '',
    img: 'https://i.imgur.com/3NhcOPL.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/qV99F2V.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/cP6GDU0.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'coloree',
      'longs',
      'c-noir',
      'y-rouge',
      'y-noir',
      'moderne'
    ]
  },
  {
    name: 'estelle',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/DNE10jJ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/WWmAaeC.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-jaune',
      'fantastique',
      'cornes',
      'animal',
    ]
  },
  {
    name: 'nian',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/79ff6f0.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/yfDApaB.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xBIhgPg.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/jTBVsAd.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/SEah68l.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/8Qc1bVE.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/JKc1n6n.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/bIN6ZZR.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-violet',
      'fantastique',
      'cornes',
      'animal',
      'oreilles',
    ]
  },
   {
    name: 'reed',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/tHib0gK.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/2s8i66a.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HwIh1N0.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/UE8pP6v.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/VgclIYs.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-bleu',
      'fantastique',
      'cornes',
      'animal',
    ]
  },
  {
    name: 'surtr',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/fkKHEGv.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0gljIHh.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gqxRh1b.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Pg1gwJB.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2z7IyT6.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Zflsn4W.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7fSu378.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hxQ1xYJ.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://ahp.li/8fb485f7f40bb643605b.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/MlV339R.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/sJVxypL.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/SBxu2LY.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/AL1ou9w.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/aGZsnYV.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/bABC2X5.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Za5Sg3k.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/x6vUvwL.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/e5I9wHO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/bZbnEsL.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-violet',
      'fantastique',
      'cornes'
    ]
  },
  {
    name: 'christa renz',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/lJDlaXS.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/iMZukTO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne'
    ]
  },
  {
    name: 'angelina',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/HcZlDeo.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/wtApnOV.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/45yv7C7.gif',
            credit: 'azria',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/ghPHlhF.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/V97ysep.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/JbFUZTl.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/skYmW5F.gif',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-orange',
      'fantastique',
      'animal'
    ]
  },
  {
    name: 'ryoko shinonome',
    source: '13 sentinels',
    nickname: '',
    img: 'https://i.imgur.com/nnh3PiC.png',
    avatars: [
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/ftUtSV4.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'ikki',
    source: 'air gear',
    nickname: '',
    img: 'https://i.imgur.com/ruJyr79.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/LJEOSRO.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Gz6pps3.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/hnHhW5E.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/i10jc07.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
       {
          width: '250',
          height: '350',
          data: [
            {
              link: 'https://i.imgur.com/5ZNSotU.png',
              credit: 'cheshireftw',
              source: ''
            },
            {
              link: 'https://i.imgur.com/4L9RWqU.png',
              credit: 'cheshireftw',
              source: ''
            },
          ]
        },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'kazu',
    source: 'air gear',
    nickname: '',
    img: 'https://i.imgur.com/0Wy6UDv.png',
    avatars: [
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/rFluU1o.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/aBMgGlW.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'shin',
    source: 'amnesia',
    nickname: '',
    img: 'https://i.imgur.com/W8oPDr3.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/27IhsZ5.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-rouge',
      'moderne',
    ]
  },
   {
    name: 'hiruma youichi',
    source: 'eyeshield 21',
    nickname: '',
    img: 'https://i.imgur.com/qWZdKWJ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/WX7I4q2.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-noir',
      'moderne',
      'oreilles',
    ]
  },
  {
    name: 'futaba yoshioka',
    source: 'ao haru ride',
    nickname: '',
    img: 'https://i.imgur.com/jCUIfBA.png',
    avatars: [
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/CCJN1n0.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'amiya',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/5iaPyxr.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/RhuQyld.png',
            credit: 'morgyn',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-bleu',
      'moderne',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'bertholdt hoover',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/atSubRq.png',
    avatars: [
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/QNaTMpb.png',
            credit: 'grimy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'c-brun',
      'y-gris',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'eren jaeger',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/IYUEnGA.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/JvuJpPF.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6DLR6na.png',
            credit: 'star',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/6DLR6na.png',
            credit: 'star',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/31UVhXC.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/P5y7sFF.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'moyens',
      'c-brun',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'erwin smith',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/ekXj7Gh.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/VjweQpA.png',
            credit: 'taku',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xLrOjey.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PFlVzb2.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/MBLjQk5.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/EzZ5Qz7.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zSaSdiT.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'levi ackerman',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/GUGP2AI.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/kyQlmnU.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/4CAcMLp.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/y2288FX.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i90.servimg.com/u/f90/20/31/20/16/00110.png',
            credit: 'momosha',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'azula',
    source: 'avatar: the last airbender',
    nickname: '',
    img: 'https://i.imgur.com/8A0kESX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/3OCLrYX.png',
            credit: 'kenzatosaure',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6SNyJBB.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'belarus',
    source: 'axis power hetalia',
    nickname: 'natalia arlovskaya',
    img: 'https://i.imgur.com/3v2cG6T.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/TwB9jlA.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xugljLD.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CwroW3A.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'denmark',
    source: 'axis power hetalia',
    nickname: '',
    img: 'https://i.imgur.com/jZwBJfB.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/aMzOPqW.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'france',
    source: 'axis power hetalia',
    nickname: '',
    img: 'https://i.imgur.com/vls1Cnw.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xzJwhiJ.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'prussia (female)',
    source: 'axis power hetalia',
    nickname: '',
    img: 'https://i.imgur.com/LbC1RYy.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/R1JxTQw.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/uESuMns.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'united kingdom',
    source: 'axis power hetalia',
    nickname: 'arthur kirkland',
    img: 'https://i.imgur.com/682Pfbs.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/yk2ySqn.png',
            credit: 'taku',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-vert',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'ash lynx',
    source: 'banana fish',
    nickname: '',
    img: 'https://i.imgur.com/QttS1eM.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SkN5cLR.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'kotoishi naru',
    source: 'barakamon',
    nickname: '',
    img: 'https://i.imgur.com/nv5FDa4.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/l2vlpM1.gif',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'moyens',
      'c-blond',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'tadashi',
    source: 'big hero 6',
    nickname: '',
    img: 'https://i.imgur.com/pUcq1Sq.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/EmoKWtI.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'elizabeth',
    source: 'bioshock',
    nickname: '',
    img: 'https://i.imgur.com/zugeeGb.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/jvYBgjk.gif',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CR2hi0A.gif',
            credit: 'sed',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/TAX9guK.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'longs',
      'c-noir',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'balalaika',
    source: 'black lagoon',
    nickname: '',
    img: 'https://i.imgur.com/1It6aul.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/UNFnWAQ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'mature',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'erik killmonger',
    source: 'black panther',
    nickname: '',
    img: 'https://i.imgur.com/Z7rwqvu.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SpUzfl2.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/wrwyP1x.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GutI5Kc.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'moyens',
      'c-noir',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'lisa',
    source: 'blackpink',
    nickname: '',
    img: 'https://i.imgur.com/YCAYgJ2.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/JqOMbcx.png',
            credit: 'star',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xgNdz08.png',
            credit: 'tarik',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'moderne',
    ]
  },
  {
    name: 'dead master',
    source: 'black rock shooter',
    nickname: '',
    img: 'https://i.imgur.com/qKCwv1T.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Mwyo1cx.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://zupimages.net/up/20/48/p28j.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'c-vert',
      'y-vert',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'grimmjow jaggerjack',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/0PujK2b.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i59.servimg.com/u/f59/20/16/89/62/hyun-s10.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'inoue orihime',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/sgM21Gi.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/USegSLF.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-gris',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'neliel tu oderschvank',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/DT7Lv4H.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/EBZQCOa.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'aizawa shouta',
    source: 'boku no hero academia',
    nickname: '',
    img: 'https://i.imgur.com/KJnybXL.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/JABlCNs.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/SuaSMmL.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gBMqTW4.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'moyens',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'bakugou katsuki',
    source: 'boku no hero academia',
    nickname: '',
    img: 'https://i.imgur.com/2jJfXcw.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/MEbCjUm.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/qkwmjeJ.gif',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/YQqSlHi.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LM3WYU7.gif',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NORHYhT.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LCYjmEM.png',
            credit: 'diraxy',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tCt7tzG.png',
            credit: 'diraxy',
            source: 'りょう',
            source_url: 'https://twitter.com/PIGGERworld/status/1384547614205485056?s=20',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/0WZ5kRe.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ep256CH.png',
            credit: 'diraxy',
            source: 'りょう',
            source_url: 'https://twitter.com/PIGGERworld/status/1384547614205485056?s=20',
          },
        ]
      },
      {
        width: '170',
        height: '170',
        data: [
          {
            link: 'https://i.imgur.com/VA0qxO5.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
       {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/vxzii8K.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-rouge',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'dabi',
    source: 'boku no hero academia',
    nickname: '',
    img: 'https://i.imgur.com/nGpoXxh.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/mqTNjNr.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/gViicRn.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/eQpqtrr.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
           {
            link: 'hhttps://i.imgur.com/SCDaUh1.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'eijirou kirishima',
    source: 'boku no hero academia',
    nickname: '',
    img: 'https://i.imgur.com/h951gjY.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0gA7F2w.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tDjKLwg.png',
            credit: 'grimy',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oSN6c1Y.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/iE9a6D8.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-rouge',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'izuku midoriya',
    source: 'boku no hero academia',
    nickname: '',
    img: 'https://i.imgur.com/HV4FRH1.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CSUEPpR.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HjUs1NR.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2oqwlr1.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zKj5AzF.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/cN6pFL6.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/3wQbjfA.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-vert',
      'y-vert',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'tomura shigaraki',
    source: 'boku no hero academia',
    nickname: '',
    img: 'https://i.imgur.com/qXsaYJF.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Sm5KYa6.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/y5PSmLE.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/mxROjFj.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/av8qhX0.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/shigar10.jpg',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'uraraka ochako',
    source: 'boku no hero academia',
    nickname: '',
    img: 'https://i.imgur.com/v2RF9Ys.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/7SYUQ6U.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'cloe',
    source: 'bratz',
    nickname: '',
    img: 'https://i.imgur.com/RqqPIiu.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/zi5QX7e.jpg',
            credit: 'moineau',
            source: 'tasiams',
            source_url: 'https://twitter.com/_tasiams',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'jungkook',
    source: 'bts',
    nickname: '',
    img: 'https://i.imgur.com/WHUjLum.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/vLZpvEz.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tu6Q8nM.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '220',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/G4vcx1v.png',
            credit: 'star',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'suga',
    source: 'bts',
    nickname: '',
    img: 'https://i.imgur.com/NBFECjf.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/XGLsD2n.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'v',
    source: 'bts',
    nickname: '',
    img: 'https://i.imgur.com/WoXS63l.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xsZE42l.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/15oF3ZG.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'dazai osamu',
    source: 'bungou stray dogs',
    nickname: '',
    img: 'https://i.imgur.com/O3pfDNV.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/dazai410.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vq0t8qt.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/k9kjaBg.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'nicaise',
    source: 'captive prince',
    nickname: '',
    img: 'https://i.imgur.com/X2ODonx.png',
    avatars: [
      {
        width: '200',
        height: '370',
        data: [
          {
            link: 'https://i.imgur.com/BhQ2dyA.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/W5UjO3j.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/C7UZXmx.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'veloce vircin',
    source: 'carciphonia',
    nickname: '',
    img: 'https://i.imgur.com/qtJmia0.png',
    avatars: [
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/M6dkd0X.png',
            credit: 'fcksleeping',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'sakura kinomoto',
    source: 'card captor sakura',
    nickname: '',
    img: 'https://i.imgur.com/EA7SuKB.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/AxZaFB1.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'moyens',
      'c-brun',
      'y-vert',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'syaoran li',
    source: 'card captor sakura',
    nickname: 'shaolan li',
    img: 'https://i.imgur.com/n7hxeOR.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/yJtjCTG.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-brun',
      'y-brun',
      'y-jaune',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'c.c',
    source: 'code geass',
    nickname: '',
    img: 'https://i.imgur.com/kwFRDjE.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/IhnJPlX.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/AwkLSXL.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Py83XBw.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LBezt5O.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VVZHC3a.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/qHDtvkU.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QBDLVmC.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/p6bAEmz.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/E1Hxx6h.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/f4T3zmD.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-jaune',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'lelouch lamperouge',
    source: 'code geass',
    nickname: 'lelouche vi britannia',
    img: 'https://i.imgur.com/sEVFt44.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/dr2drD8.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-violet',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'ashley parks',
    source: 'crew of cuties',
    nickname: '',
    img: 'https://i.imgur.com/xGabwDy.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/fa4gIwn.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QBIV5tx.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VV6nhiL.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'jasper asano',
    source: 'crew of cuties',
    nickname: '',
    img: 'https://i.imgur.com/H7RnoIC.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/9vvMOlR.png',
            credit: 'tarik',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'allen walker',
    source: 'd.gray man',
    nickname: '',
    img: 'https://i.imgur.com/74NES0F.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xp87Hij.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hhwMlGl.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/rzeOVrJ.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ressn4Z.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/tkXWVWx.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'lavi',
    source: 'd.gray man',
    nickname: '',
    img: 'https://i.imgur.com/bKOMbOG.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/3Uj0n89.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fIgPSZG.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7fYvSVH.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/j3gchif.jpg',
            credit: 'diraxy',
            source: '_火凛苍',
            source_url: 'https://www.pixiv.net/en/artworks/54304037',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-roux',
      'c-rouge',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'neah d. campbell',
    source: 'd.gray man',
    nickname: 'neah walker',
    img: 'https://i.imgur.com/arNXen0.png',
    avatars: [
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/AmAEDkC.gif',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/d4GHl5l.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-noir',
      'c-violet',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'tyki mikk',
    source: 'd.gray man',
    nickname: '',
    img: 'https://i.imgur.com/IjYFjqH.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/T8y3dtl.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/JYl4fiD.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/boltZoZ.jpeg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0e5Rxh5.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HumP3R8.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-noir',
      'c-violet',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'oc',
    source: 'daisuke richards',
    nickname: '',
    img: 'https://i.imgur.com/MKQp4sb.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/oWIXMzp.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/UTuooFe.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/AxbutPt.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7j1dCbM.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'jeune',
      'moderne',
    ]
  },
  {
    name: 'celestia ludenberg',
    source: 'dangan ronpa',
    nickname: '',
    img: 'https://i.imgur.com/kGFUDqs.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/iUi3EYH.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'harukawa maki',
    source: 'dangan ronpa',
    nickname: '',
    img: 'https://i.imgur.com/oE94kNp.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/t29prev.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'ibuki mioda',
    source: 'dangan ronpa',
    nickname: '',
    img: 'https://i.imgur.com/PZ0pwUn.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/kivUi53.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'c-blanc',
      'c-rose',
      'c-bleu',
      'y-rose',
      'moderne',
    ]
  },
  {
    name: 'kokichi ouma',
    source: 'dangan ronpa',
    nickname: '',
    img: 'https://i.imgur.com/WLnfdsw.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/kokich12.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/kokich10.jpg',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'sonia nevermind',
    source: 'dangan ronpa',
    nickname: '',
    img: 'https://i.imgur.com/KQItc6H.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/07hwpg8.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'yin',
    source: 'darker than black',
    nickname: '',
    img: 'https://i.imgur.com/ZdMdHlU.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/aSdjSwA.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'c-blanc',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'catwoman',
    source: 'dc comics',
    nickname: 'selina kyle',
    img: 'https://i.imgur.com/HeSg3F7.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/A40VRTe.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'harley quinn',
    source: 'dc comics',
    nickname: 'harleen frances quinzel',
    img: 'https://i.imgur.com/aBDaLXD.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/bL7Edlr.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'c-rose',
      'c-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'joker',
    source: 'dc comics',
    nickname: '',
    img: 'https://i.imgur.com/6gKm9Wt.png',
    avatars: [
      {
        width: '200',
        height: '370',
        data: [
          {
            link: 'https://i.imgur.com/LsPTee7.png',
            credit: 'moineau',
            source: '',
            source_url: 'https://mrredbutcher.deviantart.com/',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-vert',
      'moderne',
    ]
  },
  {
    name: 'balord',
    source: 'demonslayer',
    nickname: '',
    img: 'https://i.imgur.com/PiZsUEP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/9I7z5Fp.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-bleu',
      'moderne',
      'fantastique',
      'androgyne',
    ]
  },
  {
    name: 'kokushibo',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i90.servimg.com/u/f90/19/40/94/67/bloggi65.jpg',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/kokush11.jpg',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'akaza',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/C8w7n2m.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/oUHnhaG.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
       {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/pgBlPnE.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-jaune',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'mitsuri kanroji',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/CP3Gf9p.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/6L3D2JE.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GjKQBzo.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/UlARUia.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oy9pCrn.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/l0R5fU2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ySH9fJ1.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/dL9ziko.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LHcoKEA.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'c-vert',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'nezuko kamado',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/u7SgBke.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/cS07Q6o.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/A1JG5RN.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QndurHA.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/akRLpls.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Tx2aSzk.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/uTnTs7t.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PvYSBJ4.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2OL9at2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0FVwacO.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/TjfyVOj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/N9yystS.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://sd-6.archive-host.com/membres/up/fb421ef8c974ec7441e9b542aa5f10b206b1c639/PRD/nezukoprd1.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/m9yFofs.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/MSuha62.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'c-noir',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'shinobu kocho',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/7HlLIDk.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/vIPi0ja.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xRdFpfk.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZywoRBB.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VoAH0vA.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/EtOpYgE.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6nsColN.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7GoxsaE.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Fv11J88.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/szo4Wnp.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/563zgDk.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/FEvdubt.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/UaqoNNb.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/TNXEk7p.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ODQi5Eh.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oGudf3y.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vvh6f3z.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'tomioka giyuu',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/erCCJeX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SmAlWV8.png',
            credit: 'sweety',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/nAwHB4q.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'connor',
    source: 'detroit: become human',
    nickname: '',
    img: 'https://i.imgur.com/lXtohIG.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/c7gfwub.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5LJmh3q.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'ryo asuka',
    source: 'devilman crybaby',
    nickname: '',
    img: 'https://i.imgur.com/OA2itIv.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/T8e3MXR.jpg',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
      'fantastique',
      'ailes',
    ]
  },
  {
    name: 'akira fudo',
    source: 'devilman crybaby',
    nickname: '',
    img: 'https://i.imgur.com/iFJkLW3.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/TlySywe.jpg',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/lVP9NSd.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/l1Qoptt.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/nc6S4MH.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'badou nails',
    source: 'dogs: bullets & carnage',
    nickname: '',
    img: 'https://i.imgur.com/FyNxa6u.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/4wUrgsO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/bzWTbDi.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-vert',
      'moderne',
      'cacheoeil',
    ]
  },
  {
    name: 'haine rammsteiner',
    source: 'dogs: bullets & carnage',
    nickname: '',
    img: 'https://i.imgur.com/K5hywh3.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/x5ERH73.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'naoto fuyumine',
    source: 'dogs: bullets & carnage',
    nickname: '',
    img: 'https://i.imgur.com/lK3SCB8.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/c0s7cAg.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7kwEjF1.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'suramaki sumo',
    source: 'donten ni warau',
    nickname: '',
    img: 'https://i.imgur.com/Og2VwHd.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/e4vE52R.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'hyakkimaru',
    source: 'dororo',
    nickname: '',
    img: 'https://i.imgur.com/S6MHl2a.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/OUTOxAr.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/RYujf7w.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/7vjrCQw.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'cassandra penthagast',
    source: 'dragon age',
    nickname: '',
    img: 'https://i.imgur.com/GFD06by.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/zk8DfSE.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'flemeth',
    source: 'dragon age',
    nickname: '',
    img: 'https://i.imgur.com/pet2GEX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/vGxeDrv.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'mature',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'dorian pavus',
    source: 'dragon age',
    nickname: '',
    img: 'https://i.imgur.com/cJRF5uT.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/AY14IpU.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sxGqeyL.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'marian hawke',
    source: 'dragon age',
    nickname: '',
    img: 'https://i.imgur.com/HpLhKyA.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/mQJLb0i.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gaKegDp.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'vivienne de fer',
    source: 'dragon age',
    nickname: '',
    img: 'https://i.imgur.com/8YZY9UB.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/p7qtWJC.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'chauve',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'android 17',
    source: 'dragon ball',
    nickname: '',
    img: 'https://i.imgur.com/ckxMsjQ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/HuKBoVq.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'android 18',
    source: 'dragon ball',
    nickname: '',
    img: 'https://i.imgur.com/nZWdRSj.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ZskE2RO.jpg',
            credit: 'sweety',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/x35a6Ru.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'celty sturluson',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/P8wYUKe.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Z2Yv7FN.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/aH8l1JY.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/iheooq4.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/T80EmYo.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CtKFs2a.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'mature',
      'moderne',
    ]
  },
  {
    name: 'izaya orihara',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/8FzCzrX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/TShGmaN.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/FAp1DAu.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'shizuo heiwajima',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/DSA5Lzu.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/jdFB0bx.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2bl2MKE.jpg',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/sybvhVR.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-marron',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'oc',
    source: 'egon schiele',
    nickname: '',
    img: 'https://i.imgur.com/MfsaGSh.png',
    avatars: [
      {
        width: '200',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/1b13Uvo.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/g2yjh2D.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GR4ArgX.png',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'moderne',
    ]
  },
  {
    name: 'aisha rendar',
    source: 'elsword',
    nickname: '',
    img: 'https://i.imgur.com/rjeBoM2.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/tXfxfMr.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/kwrPHHI.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'koga oogami',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/ROXhnfq.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Vcug3Bd.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-gris',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'anastasia romanova',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/s2PQfc0.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/BsSaj2N.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/FG8BUBb.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/mdGonF6.jpg',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'arjuna',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/WarNqlf.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/4cdSppf.png',
            credit: 'sweety',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Rzq9IED.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hMF12kH.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Y4dJg8N.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/2ZswWVs.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tThJCud.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-brun',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'enkidu',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/74ENffU.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/VXqyzJL.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/SQ0JiwJ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XaAzBw4.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/dHDtKKQ.png',
            credit: 'diraxy',
            source: 'Rrrrrrice',
            source_url: 'https://twitter.com/Rrrrrrice0303/status/1091306719475466240?s=20',
          },
          {
            link: 'https://i.imgur.com/gSDXG12.jpg',
            credit: 'diraxy',
            source: 'Kuroihato',
            source_url: 'https://www.zerochan.net/1145552',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/0Ls1bew.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/S29naWQ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/WGBLaLf.png',
            credit: 'diraxy',
            source: 'Rrrrrrice',
            source_url: 'https://twitter.com/Rrrrrrice0303/status/1091306719475466240?s=20',
          },
        ]
      },
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-vert',
      'y-violet',
      'y-jaune',
      'fantastique',
      'androgyne',
    ]
  },
  {
    name: 'gudako',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/ZoBHJlf.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/qTjAXtg.jpeg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-roux',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'ibaraki-douji',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/FcRFM1J.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Iri27iK.png',
            credit: 'diraxy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-jaune',
      'y-jaune',
      'moderne',
      'cornes',
      'oreilles',
    ]
  },
  {
    name: 'irisviel von einzbern',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/5r3BCAw.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/bldLyVd.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/kwOZVfu.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XURloCn.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IGFhdOC.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3FffZGK.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ocjKR0a.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/dULnymF.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jhWYwmL.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8AJEuQX.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/BsZ9D16.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'james moriarty',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/u1hqLSb.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/NluDTkY.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'joan of ark',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/t4kWuzT.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Ma9eLTZ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/mtBAY55.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/1P7gj9C.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/S9ewcSr.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/V3y7OC1.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sLUiLzc.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/KewPE0h.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gzHYKrt.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zYniAyH.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/wFzSJ8n.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/nqQ6G0B.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/utGC0rZ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'karna',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/FSq7mmO.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/7E1RN6d.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/mPHUIJQ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6eXKNbM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/qNb26gE.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Do1CdEZ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/25E8UcP.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/X4FtiaV.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/JnaViq0.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Era3rkn.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/cJRO2rg.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/T7kfOWU.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DvquaL0.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9kLunfs.jpg',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/F39YcPw.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'c-blond',
      'y-bleu',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'okita',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/4McqXLo.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/k8eEe5X.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'courts',
      'c-blanc',
      'y-orange',
      'fantastique',
    ]
  },
  {
    name: 'artoria pendragon',
    source: 'fate/series',
    nickname: 'saber',
    img: 'https://i.imgur.com/pJfMhyD.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CCRYOyw.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/FdQTvc0.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/31zrW01.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ajHrjI2.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/cxCtzfO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PXlwZie.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/I8bgbL6.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DjK2QYZ.png',
            credit: 'diraxy',
            source: 'ASK',
            source_url: 'https://www.pixiv.net/en/artworks/78491726',
          },
          {
            link: 'https://i.imgur.com/jd6uTvk.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
           {
            link: 'https://ahp.li/3cc018f1ac71b2263ccf.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/BZsdNe0.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/19ZvSfW.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ttU3RFw.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/cuB79AO.png',
            credit: 'diraxy',
            source: 'ASK',
            source_url: 'https://www.pixiv.net/en/artworks/78491726',
          },
          {
            link: 'https://i.imgur.com/L8jFhXC.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'suzuka gouzen',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/p2ORvxQ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/pJL2RmP.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-orange',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'tohsaka rin',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/omTHJpL.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/vH8fjPR.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/FHGY6UU.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/cX94pHf.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-rouge',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'reno sinclair',
    source: 'final fantasy',
    nickname: '',
    img: 'https://i.imgur.com/gYsr7mY.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/VTqzwqF.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'lightning',
    source: 'final fantasy xiii',
    nickname: 'claire farron',
    img: 'https://i.imgur.com/br0cdzO.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/1F6ZbF2.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'y\'shtola rhul',
    source: 'final fantasy xiv',
    nickname: '',
    img: 'https://i.imgur.com/iQCeF53.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/UbKPUvK.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'courts',
      'c-blanc',
      'y-blanc',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'emet-selch',
    source: 'final fantasy xiv',
    nickname: '',
    img: 'https://i.imgur.com/Pmn3FMZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SUJbUGZ.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-blanc',
      'c-noir',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'noctis lucis caelum',
    source: 'final fantasy xv',
    nickname: '',
    img: 'https://i.imgur.com/CvKeeUP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/rtCzRQp.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.ibb.co/Yt6jZNL/fgfhgf.jpg',
            credit: 'wisukii',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'prompto argentum',
    source: 'final fantasy xv',
    nickname: '',
    img: 'https://i.imgur.com/8eEszy0.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/aF5nsiN.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0i2wgH8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Wdepj6o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/S72gYby.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gD01XoO.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XYAMAls.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/H2yQvIu.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/TxJQidn.gif',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/dCzBpYU.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/duVnrrx.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/F3wtUto.gif',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZbKo895.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'azura',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/G99HyoH.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/37sE1Do.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7lyEeVa.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CUoKPla.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6QnYcG4.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QECHYKs.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/Hq3y0PO.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'corrin (female)',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/d8xrIpz.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/bnhs2bp.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-gris',
      'c-blanc',
      'y-rouge',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'felicia',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/L5rwKaC.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/y4uZlh6.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'laslow',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/4UZlH6W.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/OFbWQ18.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-gris',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'lukas',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/CqhUwgp.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/qsBDRPG.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/i7v3MHM.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'severa',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/d6hSIrT.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/eROgmcB.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'soren',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/YNAJxeD.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/BLAG5nT.jpg',
            credit: 'diraxy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-vert',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'byleth eisner (male)',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/T6wdR3p.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/DDghG5U.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/I60q6cb.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://sd-6.archive-host.com/membres/up/fb421ef8c974ec7441e9b542aa5f10b206b1c639/PRD/bybyprd1.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'c-vert',
      'y-vert',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'byleth eisner (female)',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/umwAbLF.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Z2QDXk4.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XxVJ08b.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/aarLRXG.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/YYBxZSQ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://sd-6.archive-host.com/membres/up/fb421ef8c974ec7441e9b542aa5f10b206b1c639/PRD/bylethprd3.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'c-vert',
      'y-vert',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'claude von riegan',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/yo9PE4d.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/sfCtS8Q.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/wSHv7Ur.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/6bDXkqf.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/bmBb2KH.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-brun',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'dimitri alexandre blaiddyd',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/WbcOpVG.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/7QwI0bg.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/SyeVQ0s.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/RWofVmd.png',
            credit: 'tarik',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/T1ecfJf.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/dMd3TXZ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Z067ou9.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HKUPYPf.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fb4yx2s.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Hw3rT1l.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2g1ronX.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ObcxyhC.png',
            credit: 'star',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/unU3Z1c.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://ahp.li/72fe67890b7fec67ed86.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
          {
            link: 'https://ahp.li/1ea15f9fb7faa1521d26.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
          {
            link: 'https://ahp.li/8117b6849410886710dd.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/p7ybnts.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/yXgI1t7.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'edelgard von hræsvelgr',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/UAzBUp2.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/KswBizM.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ldd6RQx.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/u3XH2O1.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ANwoTBj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zREhAoB.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QUyY027.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZqNmwRV.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZgQLLba.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GBzTJem.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6d72nnh.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/31dcvuY.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/p5S1R7I.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://sd-6.archive-host.com/membres/up/fb421ef8c974ec7441e9b542aa5f10b206b1c639/PRD/edel200320e.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
          {
            link: 'https://sd-6.archive-host.com/membres/up/fb421ef8c974ec7441e9b542aa5f10b206b1c639/PRD/edel3.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
          {
            link: 'https://sd-6.archive-host.com/membres/up/fb421ef8c974ec7441e9b542aa5f10b206b1c639/PRD/edel200320.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/i0SaUTW.jpg',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'felix hugo fraldarius',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/elXtZ0j.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/2cs0gPj.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'hapi',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/19prq4R.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/qmqbgnn.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-rose',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'hilda von goneril',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/Alb85xZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/UYRR0uY.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/r0CY8q9.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sc4zNQf.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5nUxkM8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/s5MMmqG.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gOqLCEf.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/86AD7wd.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'lysithea von ordelia',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/SGTDRYn.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/dh4g0E8.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'marianne von edmund',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/hk9iSSW.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/a1i3X5Y.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'mercedes von martritz',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/HH5AduZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/2kn476d.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/4ffT4g8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/u2vxoVH.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/g6oc8fH.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gXy9Qwk.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PyABX0b.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2nxwmK4.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CfGdLN2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/YLgBoT8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/77SsBIg.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tzGbcSZ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ni02efD.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/y5eGaFH.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'courts',
      'c-blond',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'petra macneary',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/CpxSsSV.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Uc2HOCy.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8z5U6Fx.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/dJjMzMo.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/TpoHZ35.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'rin matsuoka',
    source: 'free!',
    nickname: '',
    img: 'https://i.imgur.com/CZKnHfZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Mhk3AZN.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IEQjIYb.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/x1jlx0D.png',
            credit: 'sweety',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'yamazaki sosuke',
    source: 'free!',
    nickname: '',
    img: 'https://i.imgur.com/2yWK3oX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/MmGnivL.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'edward elric',
    source: 'fullmetal alchemist',
    nickname: '',
    img: 'https://i.imgur.com/BNqRGcG.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/2UOOWpQ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/WxlE7Q4.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3SsPQjH.png',
            credit: 'azria',
            source: '@unchaumo',
            source_url: 'https://twitter.com/unchaumo/status/1349483610575835136',
          },
          {
            link: 'https://i.imgur.com/E4XAtrr.png',
            credit: 'azria',
            source: '@unchaumo',
            source_url: 'https://twitter.com/unchaumo/status/1349483610575835136',
          },
          {
            link: 'https://i.imgur.com/VnktT8G.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vhHqu1o.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/3c6EYF1.png',
            credit: 'azria',
            source: '@unchaumo',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/YyAOBrY.png',
            credit: 'azria',
            source: '@unchaumo',
            source_url: 'https://twitter.com/unchaumo/status/1349483610575835136',
          },
          {
            link: 'https://i.imgur.com/biRikpY.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/YWAg8kV.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'lust',
    source: 'fullmetal alchemist',
    nickname: '',
    img: 'https://i.imgur.com/SbAgWcJ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/MYBd9Gx.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/V85UnkF.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'mature',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'arya stark',
    source: 'game of thrones',
    nickname: '',
    img: 'https://i.imgur.com/KtrZOpS.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/MkrQE3p.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'alex benedetto',
    source: 'gangsta.',
    nickname: '',
    img: 'https://i.imgur.com/liEzR2n.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CVkQI1p.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/3vfyZKL.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'nicolas brown',
    source: 'gangsta.',
    nickname: '',
    img: 'https://i.imgur.com/RUY3Smi.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/u3pqahD.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'barbara',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/PEQrwVg.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://zupimages.net/up/20/48/e2um.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
          {
            link: 'https://zupimages.net/up/20/48/8fhb.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
   {
    name: 'eula',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/Ix4ilIJ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/wgQ4gdK.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-jaune',
      'y-violet',
      'fantastique',
    ]
  },
    {
    name: 'albedo',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/AKw2mdV.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/W6ldx72.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/dXXphRc.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'childe',
    source: 'genshin impact',
    nickname: 'tartaglia',
    img: 'https://i.imgur.com/lj9CycE.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/7u8GRQI.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/K7HMVS8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/DLCibDg.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/aDPHYsQ.png',
            credit: 'takeitezy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-roux',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'dainsleif',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/R3Q8ptK.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/wqWHs58.jpg',
            credit: 'star',
            source: '',
            source_url: '',
          },
         ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
      'cacheoeil',
    ]
  },
  {
    name: 'diluc',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/MqYZjcb.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/WXcz1bC.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/lKJmEt4.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/532BzTx.png',
            credit: 'cheshire',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/J2wSFmZ.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'hu tao',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/1Jdb9bP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/pfZiVEJ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/kKFiMzG.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/2anLeBj.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/f4BpW9b.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'klee',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/huWfBaL.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/OSu8cNa.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/HESc5da.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7WrIb6w.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'moyens',
      'c-blond',
      'y-rouge',
      'fantastique',
      'oreilles',
    ]
  },
   {
    name: 'noelle',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/408cqM2.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/CDfgfOM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/LsUD2R5.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-gris',
      'y-jaune',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'qiqi',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/KWBY8f9.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/mLSZKq2.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/m5ch9ld.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'moyens',
      'c-violet',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'scaramouche',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/9Koy6WV.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/o1RSkrv.png',
            credit: 'sed',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0RnGVH1.gif',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/jGvqMQd.png',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'xiao',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/YXztd3K.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/FNox6II.png',
            credit: 'sed',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PXGj8w4.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/rLVfGvg.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CSAqQIu.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/rBLy6Cf.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tcurnGY.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Uf4lW4k.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/s2nkedv.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-vert',
      'c-bleu',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'zhongli',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/VSimqOx.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/6BvyEcS.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/E83hLMz.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zLEhQQ7.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/ko9cKGV.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https/i.imgur.com/lH8dgCK.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https/i.imgur.com/wl0XasP.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/V9BD9gg.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'fuuchouin kazuki',
    source: 'get backers',
    nickname: '',
    img: 'https://i.imgur.com/mWYH4kU.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/na0LZgY.gif',
            credit: 'fcksleeping',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/4gmEpbp.png',
            credit: 'fcksleeping',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'kagura',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/4AxZtzZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ZYi819d.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LI7v9bv.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'sakata gintoki',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/pdyHz10.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/HqA4p5Z.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/wAy0Xwl.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/Hf9q0hm.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/YPf9LjC.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'c-bleu',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'uenoyama ritsuka',
    source: 'given',
    nickname: '',
    img: 'https://i.imgur.com/5jb1jNQ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/pi3w29i.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/eiGf5MP.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fa41Ile.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'albert',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/f7G3DoW.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/e6CTtM2.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/KlT7mEa.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'zooey',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/f2sHbw0.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/9qhJeHQ.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'alexiel',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/vThUTZy.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/eJ4AoGE.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9ZyuEZX.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oImDSfC.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/mlUiObc.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/F7iZqND.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/US59NiY.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Rnvp0s3.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/B37qIB9.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sXm593I.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/q8OlZl6.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sqUBPzb.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Gy3dSSm.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'yuel',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/ks1HZWJ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/zjlLyY9.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/LjDhVyb.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-marron',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'ayer',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/7hRc2Ip.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/buNVTjI.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'beatrix',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/mkjJxm0.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Msn5z20.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NPRVCat.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'jeanne d\'arc',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/TKlhcoF.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/OKtFAap.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'percival',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/plX98th.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Zj3NKuA.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oFQuRWE.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0QGt1Ia.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hgGd8wZ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/n0sV1Kv.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GVAODzu.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PGu5J1b.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jRhandh.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/lMvkloj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/MshP0UC.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/F6YqwRG.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/A23jjgA.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'vane',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/Hupnr1o.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/2HKVKHF.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/KqEVDXe.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Kc65Nyt.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Mf89prT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6kqlFE8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ra5JqSp.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LtzyR8H.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'gris',
    source: 'gris',
    nickname: '',
    img: 'https://i.imgur.com/J9FOSfS.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Vewy2u8.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'fantastique',
    ]
  },
   {
    name: 'amity',
    source: 'the owl house',
    nickname: '',
    img: 'https://i.imgur.com/1HWn6aT.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/edLNNu2.gif',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-vert',
      'y-jaune',
      'moderne',
      'oreilles',
    ]
  },
   {
    name: 'fern',
    source: 'adventure time',
    nickname: '',
    img: 'https://i.imgur.com/j556WUI.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/8Zeq6YH.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'coloree',
      'longs',
      'c-vert',
      'c-blond',
      'y-vert',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'inori yuzuriha',
    source: 'guilty crown',
    nickname: '',
    img: 'https://i.imgur.com/YDq6A53.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/4Rbemdo.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DPgMSiI.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/37DDyu2.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ca2mvP1.jpg',
            credit: 'star',
            source: '',
            source_url: '',
          },
          ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'hypnos',
    source: 'hades',
    nickname: '',
    img: 'https://i.imgur.com/IVVDR5L.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/nbPahVo.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/bLCUx2D.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/gQcOChD.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/roZbaz5.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'coloree',
      'courts',
      'c-blanc',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'thanatos',
    source: 'hades',
    nickname: '',
    img: 'https://i.imgur.com/8e6YRhS.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://zupimages.net/up/20/48/g486.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/e1MSu5Q.gif',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ahx1o2D.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://www.zupimages.net/up/21/18/dpee.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://zupimages.net/up/21/18/8kgs.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'coloree',
      'sombre',
      'courts',
      'c-blanc',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'zagreus',
    source: 'hades',
    nickname: '',
    img: 'https://i.imgur.com/W11eqhl.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/QONQ4Fj.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/4GDBBdc.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CwwzERG.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-vert',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'perséphone',
    source: 'hades holiday',
    nickname: '',
    img: 'https://i.imgur.com/4HwIVka.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/zRl1se4.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'c-brun',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'akaashi keiji',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/RJptNUE.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/9fhsvBp.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/8Sp2fL5.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'iwaizumi hajime',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/IFNs0KU.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/AtWNqoM.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Pjp5zW8.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/27WuLgl.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/KHJmJFM.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'kiyoko shimizu',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/nZ6Qrk3.png',
    avatars: [
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/BmOSA4v.jpg',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-gris',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'hana kurusu',
    source: 'jujutsu kaisen',
    nickname: '',
    img: 'https://i.imgur.com/LcnBpQw.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/HGNq5cc.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-gris',
      'moderne',
      'ailes',
    ]
  },
  {
    name: 'thorfinn',
    source: 'vinland saga',
    nickname: '',
    img: 'https://i.imgur.com/Cz8Q8JY.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/YDgUCDF.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'ross',
    source: 'senyuu.',
    nickname: '',
    img: 'https://i.imgur.com/qEpDDYT.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/ZXmTGRh.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'nishinoya yuu',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/FEqZ9Pa.png',
    avatars: [
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/jwPMFZu.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CVzysDF.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sSsODeh.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5TjVGJ9.gif',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'c-blond',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'oikawa tooru',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/NLwCGTE.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/NHaBOtg.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/1jD6Qpm.png',
            credit: 'star',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/B8GZ8yh.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/bquSBDJ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/M8bUlXZ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/cwgYvW5.jpg',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'rintaro suna',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/O1I3UgZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/RyZWSTU.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'terushima yuuji',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/UfPcUYx.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/wM4LAzG.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Kr3utiw.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'tetsuro kuroo',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/NzZ5KpS.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/TzjEc53.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oKYAVvz.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vCAsZ0L.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/weUp44Y.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Hu19uJO.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/ynaGqnv.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jBt3xHz.jpg',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/KJmjPrG.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6iFbDmE.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'tobio kageyama',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/eIbGb5R.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/QkZRXWA.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'yaku morisuke',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/krPyNRW.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/7C1kyF7.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'flaky',
    source: 'happy tree friends',
    nickname: '',
    img: 'https://i.imgur.com/EU1bnNP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/6t9CGS1.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'charlie',
    source: 'hazbin hotel',
    nickname: '',
    img: 'https://i.imgur.com/tMAIvep.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/k6zLQQD.png',
            credit: 'sweety',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-jaune',
      'y-rouge',
      'moderne',
      'cornes',
    ]
  },
  {
    name: 'megara',
    source: 'hercules',
    nickname: '',
    img: 'https://i.imgur.com/NFq6seo.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/EbBymoG.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'calypso',
    source: 'heroes of olympus',
    nickname: '',
    img: 'https://i.imgur.com/bZ7zAfg.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/u00ePOg.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-brun',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'hades',
    source: 'lore olympus',
    nickname: '',
    img: 'https://i.imgur.com/UDLIPRn.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/zvVqsGk.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'coloree',
      'courts',
      'c-blanc',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
   {
    name: 'minthe',
    source: 'lore olympus',
    nickname: '',
    img: 'https://i.imgur.com/CMFovnu.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/AfSPriu.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'longs',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'persephone',
    source: 'lore olympus',
    nickname: '',
    img: 'https://i.imgur.com/JhNWfyR.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/MylnKzt.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'longs',
      'courts',
      'c-rose',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'hecate',
    source: 'lore olympus',
    nickname: '',
    img: 'https://i.imgur.com/yC8eyUI.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/1ZEoWZJ.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'moyens',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'hera',
    source: 'lore olympus',
    nickname: '',
    img: 'https://i.imgur.com/jNVBxFl.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/jpjk6Jj.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'longs',
      'c-blond',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'eridan ampora',
    source: 'homestuck',
    nickname: '',
    img: 'https://i.imgur.com/Tjk41hA.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/XR9BSGO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'coloree',
      'courts',
      'c-noir',
      'c-violet',
      'y-jaune',
      'fantastique',
      'cornes',
      'animal',
    ]
  },
  {
    name: 'rose lalonde',
    source: 'homestuck',
    nickname: '',
    img: 'https://i.imgur.com/VjI8Nf5.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/5wQJBUN.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/m8gABcJ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QRVEyzU.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'roxy lalonde',
    source: 'homestuck',
    nickname: '',
    img: 'https://i.imgur.com/6boLRrm.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0hGOWCg.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-rose',
      'moderne',
    ]
  },
  {
    name: 'vriska serket',
    source: 'homestuck',
    nickname: '',
    img: 'https://i.imgur.com/tvvcVIF.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/BQhFU46.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/aDEQNzY.png',
            credit: 'diraxy',
            source: 'xamag',
            source_url: 'https://www.zerochan.net/2073145',
          },
          {
            link: 'https://i.imgur.com/j9eZis0.png',
            credit: 'diraxy',
            source: 'nogutsnoglory',
            source_url: 'https://www.zerochan.net/1005081',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/wzUdkV2.png',
            credit: 'diraxy',
            source: 'xamag',
            source_url: 'https://www.zerochan.net/2073145',
          },
          {
            link: 'https://i.imgur.com/RlIw7hq.png',
            credit: 'diraxy',
            source: 'nogutsnoglory',
            source_url: 'https://www.zerochan.net/1005081',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'longs',
      'c-bleu',
      'y-jaune',
      'moderne',
      'cornes',
    ]
  },
  {
    name: 'antarcticite',
    source: 'houseki no kuni',
    nickname: '',
    img: 'https://i.imgur.com/w5ZYO81.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/antarc11.jpg',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/z4LOKvs.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/h1DsjXW.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZIFNrto.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-blanc',
      'moderne',
    ]
  },
  {
    name: 'chrysoberyl',
    source: 'houseki no kuni',
    nickname: '',
    img: 'https://i.imgur.com/RDx0O05.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/kau09Sq.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Jcn4VpO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'diamond',
    source: 'houseki no kuni',
    nickname: '',
    img: 'https://i.imgur.com/MJPudgr.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/UfOC7Q7.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'c-rose',
      'c-vert',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'ghost quartz',
    source: 'houseki no kuni',
    nickname: '',
    img: 'https://i.imgur.com/BVRhqkz.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/JwSh4Da.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/G1bfVQS.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'lapis lazuli',
    source: 'houseki no kuni',
    nickname: '',
    img: 'https://i.imgur.com/pk4qm5t.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/yewHdRH.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zWARXFa.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oQrzTIG.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Kn4rSrN.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
       {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/WVbVNna.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'phosphophyllite',
    source: 'houseki no kuni',
    nickname: '',
    img: 'https://i.imgur.com/tW9u8p6.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/eVm9SxG.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Kafwc1P.jpg',
            credit: 'sed',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3NLon8k.jpg',
            credit: 'diraxy',
            source: 'Shakehouseki',
            source_url: 'https://www.zerochan.net/2234239',
          },
          {
            link: 'https://i.imgur.com/RBQKfgC.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/AyeiRjH.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/6zKEXA4.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'c-vert',
      'y-bleu',
      'c-vert',
      'moderne',
    ]
  },
  {
    name: 'howl pendragon',
    source: 'howl\'s moving castle',
    nickname: '',
    img: 'https://i.imgur.com/H0w2FWa.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/QHIqcK6.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/4vOQCgF.gif',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/howl10.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/WlgkgRx.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/LsrT4hf.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'hiccup',
    source: 'how to train your dragon',
    nickname: 'HICCUP HORRENDOUS HADDOCK III',
    img: 'https://i.imgur.com/5vwoq95.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/dxeQxLS.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'chrollo lucifer',
    source: 'hunter x hunter',
    nickname: '',
    img: 'https://i.imgur.com/6pBMM2o.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/7c3uI9v.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/BND5Jxb.png',
            credit: 'sweety',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sb86HyX.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'hisoka morow',
    source: 'hunter x hunter',
    nickname: '',
    img: 'https://i.imgur.com/Jp4l5CX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/OkMRn8P.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'killua zoldyck',
    source: 'hunter x hunter',
    nickname: '',
    img: 'https://i.imgur.com/UVlBSbK.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/F0FIjHJ.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/4wFG9cu.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZLr2nTN.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'courts',
      'c-blanc',
      'y-violet',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'kurapika',
    source: 'hunter x hunter',
    nickname: '',
    img: 'https://i.imgur.com/hyIz1Jh.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/TZvKgX5.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6rVo4K6.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/kIjFohD.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jhesq5l.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/uoU4t4p.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VVl2vR0.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'y-rouge',
      'fantastique',
      'androgyne',
    ]
  },
  {
    name: 'doppo kannonkaza',
    source: 'hypnosis mic',
    nickname: '',
    img: 'https://i.imgur.com/xXPGlXa.png',
    avatars: [
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/ldpnA4Q.png',
            credit: 'sed',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fTZvxse.png',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'garry',
    source: 'ib',
    nickname: '',
    img: 'https://i.imgur.com/szPj55R.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/cNgrg7v.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-violet',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'ib',
    source: 'ib',
    nickname: '',
    img: 'https://i.imgur.com/3Pv0giT.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/rywv3Vh.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/yOuXOp4.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-brun',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'mary',
    source: 'ib',
    nickname: '',
    img: 'https://i.imgur.com/XKVJJYl.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/676Zbj0.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'oc',
    source: 'ilya kuvshinov',
    nickname: '',
    img: 'https://i.imgur.com/hqMfa50.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/WJuBMQx.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ynK1h7f.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zoEte5G.png',
            credit: 'kenzatosaure',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'moderne',
    ]
  },
  {
    name: 'afuro terumi',
    source: 'inazuma eleven',
    nickname: '',
    img: 'https://i.imgur.com/xX6ghW6.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/kyE3QbQ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZKg6vOz.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-rouge',
      'moderne',
      'fantastique',
      'androgyne',
      'ailes',
    ]
  },
  {
    name: 'shiirakin ririchiyo',
    source: 'inu x boku ss',
    nickname: '',
    img: 'https://i.imgur.com/SnqtYhN.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/gTpH6mc.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'c-violet',
      'y-violet',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'kikiyo',
    source: 'inuyasha',
    nickname: '',
    img: 'https://i.imgur.com/g6Yq1GH.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/f2odPWt.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'hanako-kun',
    source: 'jibaku shonen hanako-kun',
    nickname: '',
    img: 'https://i.imgur.com/GIWw0Bf.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/4lCnWZ2.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/766W3jh.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'courts',
      'c-noir',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'yashiro nene',
    source: 'jibaku shonen hanako-kun',
    nickname: '',
    img: 'https://i.imgur.com/WPQOdpU.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/3WzI2if.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0PHRgKX.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'courts',
      'c-blond',
      'c-rose',
      'y-rose',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'jolyne cujoh',
    source: 'jojo\'s bizarre adventure',
    nickname: '',
    img: 'https://i.imgur.com/TDgu9fr.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/yXj5ExN.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/nJcDKJh.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/6uaSrY3.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/WOf3WuA.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'c-vert',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'narancia ghirga',
    source: 'jojo\'s bizarre adventure',
    nickname: '',
    img: 'https://i.imgur.com/aNPJyFP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/33DlHrC.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'trish una',
    source: 'jojo\'s bizarre adventure',
    nickname: '',
    img: 'https://i.imgur.com/IhdTPjR.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ZyDMSFT.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'gojo satoru',
    source: 'jujutsu kaisen',
    nickname: '',
    img: 'https://i.imgur.com/4bR20Im.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/C2MlXlb.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IejBo6y.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HUtKqaA.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oIbPKOG.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/eYfl6tw.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gTfhuZQ.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CROoHPA.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NEjWUsp.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/rNP50In.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7FlIqTO.png',
            credit: 'azria',
            source: '@dmsco1803',
            source_url: 'https://twitter.com/dmsco1803/status/1381965265617911821/photo/1',
          },
          {
            link: 'https://i.imgur.com/jpjKvfi.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9UTIIlP.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/GtEBJhZ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/C3FyjGA.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/4iZPtgg.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/J87RQYc.png',
            credit: 'azria',
            source: '@dmsco1803',
            source_url: 'https://twitter.com/dmsco1803/status/1381965265617911821/photo/1',
          },
          {
            link: 'https://i.imgur.com/GOo0UDJ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'c-blanc',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'toji fushiguro',
    source: 'jujutsu kaisen',
    nickname: '',
    img: 'https://i.imgur.com/2glTwx9.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/rOfLhqD.jpg',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'ieiri shouko',
    source: 'jujutsu kaisen',
    nickname: '',
    img: 'https://i.imgur.com/pDDwooM.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xgYTGHm.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/FfdIYDV.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'megumi fushigiro',
    source: 'jujutsu kaisen',
    nickname: '',
    img: 'https://i.imgur.com/SpY4kM7.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0afyqXU.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/SpUKfjb.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/35edIFk.png',
            credit: 'azria',
            source: '@li_chesnuts',
            source_url: 'https://twitter.com/li_chestnuts/status/1325470142566817793/photo/2',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/YypXpsX.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/OEM2RvW.png',
            credit: 'azria',
            source: '@li_chesnuts',
            source_url: 'https://twitter.com/li_chestnuts/status/1325470142566817793/photo/2',
          },
          {
            link: 'https://i.imgur.com/gazrNkV.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'suguru geto',
    source: 'jujutsu kaisen',
    nickname: '',
    img: 'https://i.imgur.com/rxNRazK.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/lshU008.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ot1R5AZ.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QcXLuae.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'sukuna',
    source: 'jujutsu kaisen',
    nickname: '',
    img: 'https://i.imgur.com/HXgWFud.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Jws1BPd.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DueK1oP.png',
            credit: 'felix geyer',
            source: 'dollacn404',
            source_url: 'https://twitter.com/DollacN404',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'toge inumaki',
    source: 'jujutsu kaisen',
    nickname: '',
    img: 'https://i.imgur.com/zBQ0qZy.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/QnFcCWg.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'ayano tateyama',
    source: 'kagerou project',
    nickname: '',
    img: 'https://i.imgur.com/jRrQaO2.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/WtoOQ5i.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XCrlGKO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VituTFC.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'enomoto takane',
    source: 'kagerou project',
    nickname: '',
    img: 'https://i.imgur.com/CQxdOUR.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/K5HTc61.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'c-bleu',
      'y-rouge',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'kido tsubomi',
    source: 'kagerou project',
    nickname: '',
    img: 'https://i.imgur.com/OF1biYe.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/B3f2mHQ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/UTUxUum.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'mary kozakura',
    source: 'kagerou project',
    nickname: '',
    img: 'https://i.imgur.com/2GpHZI3.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/4x7avQn.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3dAMuJ4.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/G1ZBHOg.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hZ25oN7.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rose',
      'moderne',
    ]
  },
  {
    name: 'fujiwara chika',
    source: 'kaguya-sama - love is war',
    nickname: '',
    img: 'https://i.imgur.com/18bSoRK.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Al3egjn.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'gareki',
    source: 'karneval',
    nickname: '',
    img: 'https://i.imgur.com/wKYyY0q.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/1U0U0AG.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xfCOC5L.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'gokudera hayato',
    source: 'katekyou hitman reborn',
    nickname: '',
    img: 'https://i.imgur.com/D7lxxBk.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/9yLBXes.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-gris',
      'y-gris',
      'moderne',
    ]
  },
   {
    name: 'chrome dokuro',
    source: 'katekyou hitman reborn',
    nickname: '',
    img: 'https://i.imgur.com/3ya2si9.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ANhU8xJ.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-violet',
      'c-bleu',
      'y-violet',
      'moderne',
      'cacheoeil',
    ]
  },
  {
    name: 'bluebell',
    source: 'katekyou hitman reborn',
    nickname: '',
    img: 'https://i.imgur.com/0l9GaC8.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/gtGamfo.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'yuni',
    source: 'katekyou hitman reborn',
    nickname: '',
    img: 'https://i.imgur.com/ntElLPz.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/qDs7z9V.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'hibari kyouya',
    source: 'katekyou hitman reborn',
    nickname: '',
    img: 'https://i.imgur.com/g18ueXs.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/6eGJUsc.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6qMDSSh.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xV8Jz5r.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/BCvTU0G.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'belphegor',
    source: 'katekyou hitman reborn',
    nickname: '',
    img: 'https://i.imgur.com/7nAwQdU.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/yIxerUB.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'moderne',
    ]
  },
   {
    name: 'fran',
    source: 'katekyou hitman reborn',
    nickname: '',
    img: 'https://i.imgur.com/gYmTQfp.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/Zy78l7d.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-vert',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'takeshi yamamoto',
    source: 'katekyou hitman reborn',
    nickname: '',
    img: 'https://i.imgur.com/CmcTlEt.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/cg6Dxhw.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'tsunayoshi sanada',
    source: 'katekyou hitman reborn',
    nickname: '',
    img: 'https://i.imgur.com/4UCglyW.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Zg6CpyB.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'zapp renfro',
    source: 'kekkai sensen',
    nickname: '',
    img: 'https://i.imgur.com/SRMVIQZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/M9k7wmP.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-blanc',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'fay (oc)',
    source: 'kelps',
    nickname: '',
    img: 'https://i.imgur.com/uVfbVDn.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CKKED3w.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/pr0Lr4E.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VVWyjSU.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'palutena',
    source: 'kid icarus',
    nickname: '',
    img: 'https://i.imgur.com/YyDHGZD.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/JrqTtie.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'satsuki kiryuuin',
    source: 'kill la kill',
    nickname: '',
    img: 'https://i.imgur.com/wj4xm0S.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Sq0gphz.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/m1gEXSK.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'ren',
    source: 'kkojika',
    nickname: '',
    img: 'https://i.imgur.com/LUYVr5Z.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ErfddqA.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'kobato hanato',
    source: 'kobato',
    nickname: '',
    img: 'https://i.imgur.com/TVinX3f.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/75bJU8F.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3x2Zcfc.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'c-rose',
      'y-rose',
      'moderne',
    ]
  },
  {
    name: 'kasamatsu yukio',
    source: 'kuroko no basket',
    nickname: '',
    img: 'https://i.imgur.com/NcPGduW.png',
    avatars: [
      {
        width: '170',
        height: '170',
        data: [
          {
            link: 'https://i.imgur.com/NSUtTxE.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'akashi seijuuro',
    source: 'kuroko no basket',
    nickname: '',
    img: 'https://i.imgur.com/6IfBdn4.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/6TAtzG7.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Gk1lsyb.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-rouge',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'ahri',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/R9lu94i.jpg',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://zupimages.net/up/20/48/lmqx.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
          {
            link: 'https://zupimages.net/up/20/48/xtbe.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QoxYkzr.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-jaune',
      'fantastique',
      'animal',
    ]
  },
   {
    name: 'gwen',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/GVJDJ8K.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CsRlcxP.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/afOIi61.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LmiwzQf.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2dxnU04.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/bWwXjBj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DvWTEj5.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'ezreal',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/KbJXRYJ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/2QaF2kL.png',
            credit: 'takeitezy',
            source: '',
            source_url: '',
          },
         ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'akali',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/b3xizvF.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/WCV7c1c.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.ibb.co/3pkZFpK/fdgddf.jpg',
            credit: 'wisukii',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/lRc8qRH.png',
            credit: 'fcksleeping',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9AzyxYr.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'jinx',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/X9FtEjy.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Zo3Hpll.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0VjnVjT.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/vEb8I6j.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'qiyana',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/lobLKRw.png',
    avatars: [
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/FqvgTz1.jpg',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'seraphine',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/LZUSLYG.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/73QQWE3.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'yone',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/eJGOwSF.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/xQjxDgG.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'zyra',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/vN9oaif.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ZUhq5FB.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'asami sato',
    source: 'legend of korra',
    nickname: '',
    img: 'https://i.imgur.com/Tu6mTgR.jpg',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/KbJFvnw.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-vert',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'korra',
    source: 'legend of korra',
    nickname: '',
    img: 'https://i.imgur.com/urDHyKg.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/S9WFUDt.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'courts',
      'c-brun',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'diana cavendish',
    source: 'little witch academia',
    nickname: '',
    img: 'https://i.imgur.com/zjAs7wm.png',
    avatars: [
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/0uuLgxF.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-blond',
      'c-vert',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'takami chika',
    source: 'love live!',
    nickname: '',
    img: 'https://i.imgur.com/blx60fh.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/DzoggxR.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-roux',
      'y-rose',
      'moderne',
    ]
  },
  {
    name: 'sheryl nome',
    source: 'macross frontier',
    nickname: '',
    img: 'https://i.imgur.com/2ErkIZN.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/KdLJGsE.png',
            credit: 'sweety',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DdpLnyM.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/6eSQnKp.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'judal',
    source: 'magi - the labyrinth of magic',
    nickname: '',
    img: 'https://i.imgur.com/aYcMtIM.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/NoB5Zhc.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/judar310.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/uO3bTOr.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/WOj2lzm.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'kassim',
    source: 'magi - the labyrinth of magic',
    nickname: '',
    img: 'https://i.imgur.com/pD75NmY.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/8ODCxcv.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'longs',
      'c-violet',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'sinbad',
    source: 'magi - the labyrinth of magic',
    nickname: '',
    img: 'https://i.imgur.com/u47zSLG.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ZZwXTon.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Yz8aKOf.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'titus alexius',
    source: 'magi - the labyrinth of magic',
    nickname: '',
    img: 'https://i.imgur.com/bscuXRI.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/1Jstf2B.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/titus210.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'craig',
    source: 'martina saviane',
    nickname: '',
    img: 'https://i.imgur.com/8zKdX0W.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/1YVGsRB.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'black widow',
    source: 'marvel cinematic universe',
    nickname: 'natasha romanoff',
    img: 'https://i.imgur.com/JilZQxi.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/D9LuW3k.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/BMUx99F.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-roux',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'peggy carter',
    source: 'marvel cinematic universe',
    nickname: 'natasha romanoff',
    img: 'https://i.imgur.com/xAvlRg8.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/w6teGdj.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'himari takakura',
    source: 'mawaru penguindrum',
    nickname: 'princess of crystal',
    img: 'https://i.imgur.com/ezfssG5.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xOfBdpi.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'c-roux',
      'y-rose',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'marinette dupain-cheng',
    source: 'miraculous ladybug',
    nickname: 'ladybug',
    img: 'https://i.imgur.com/4qEaT24.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/v6dxE6W.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'oc',
    source: 'miriam',
    nickname: '',
    img: 'https://i.imgur.com/38kDQ9z.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/gRrAaoP.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hn2ePs9.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/H2APME4.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'moderne',
    ]
  },
  {
    name: 'reigen arataka',
    source: 'mob psycho 100',
    nickname: '',
    img: 'https://i.imgur.com/JFewgiw.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/4ZdmJzV.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts', 
      'c-roux',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'shigeo kageyama',
    source: 'mob psycho 100',
    nickname: '',
    img: 'https://i.imgur.com/BiBXuoN.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Jb2B8vK.png',
            credit: 'kenzatosaure',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/JWuuPIe.png',
            credit: 'kenzatosaure',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Nec0qcU.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/82f8fzV.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens', 
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'wei wuxian',
    source: 'mo dao zu shi',
    nickname: '',
    img: 'https://i.imgur.com/jR6JXdh.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://zupimages.net/up/20/32/hypl.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/wei_wu10.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sVN0OP7.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/bZKyC9W.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/BXbV1wD.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/KizsR6S.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/lqYr5q1.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/KSOi2y2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hzoWIPw.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/91UeCtp.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/jLL547v.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs', 
      'c-noir',
      'y-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'kusuriuri',
    source: 'mononoke',
    nickname: '',
    img: 'https://i.imgur.com/GQbsuI5.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/eZabeUf.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jh2IpnQ.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'http://i.imgur.com/PGS71jX.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/cLs47VD.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/iCBihL9.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs', 
      'c-blond',
      'y-bleu',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'ashitaka',
    source: 'mononoke hime',
    nickname: '',
    img: 'https://i.imgur.com/UaNZs1Y.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xEVZJks.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/e2JSKwn.png',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/80mB5Jv.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZIq8PA2.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts', 
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'deidara',
    source: 'naruto',
    nickname: '',
    img: 'https://i.imgur.com/27o6SfX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/jVvA7eY.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs', 
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'gaara',
    source: 'naruto',
    nickname: '',
    img: 'https://i.imgur.com/Mh2pme1.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/S1MHH5Z.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts', 
      'c-rouge',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'hinata hyuuga',
    source: 'naruto',
    nickname: '',
    img: 'https://i.imgur.com/Tth5DoZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/auExOGH.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'longs',
      'c-violet',
      'c-noir',
      'y-violet',
      'y-blanc',
      'fantastique',
    ]
  },
  {
    name: 'madara uchiha',
    source: 'naruto',
    nickname: '',
    img: 'https://i.imgur.com/ZqcGLYd.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/lyANoje.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/K96e43D.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QFOyzoK.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/pB5dkPd.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'naruto uzumaki',
    source: 'naruto',
    nickname: '',
    img: 'https://i.imgur.com/pRKdNMC.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/h9gh6nQ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jQzn9Wz.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/yX0GRRf.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'rin nohara',
    source: 'naruto',
    nickname: '',
    img: 'https://i.imgur.com/cjKkIDK.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/aAIcCaA.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'sakura haruno',
    source: 'naruto',
    nickname: '',
    img: 'https://i.imgur.com/3xjPDbr.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/WBuogiU.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/TCf1Hyx.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ifx3KxG.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/qD4gKkw.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/SUeNZW0.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'shikamaru nara',
    source: 'naruto',
    nickname: '',
    img: 'https://i.imgur.com/QZj33wo.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://imgur.com/nAkf09Q.png',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'takashi natsume',
    source: 'natsume yuujinchou',
    nickname: '',
    img: 'https://i.imgur.com/odpngjg.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ct5NQOB.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'kaia (oc)',
    source: 'onasugar',
    nickname: '',
    img: 'https://i.imgur.com/odmi689.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/5IpSjUu.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-violet',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'nobara kugisaki',
    source: 'jujutsu kaisen',
    nickname: '',
    img: 'https://i.imgur.com/FYStN1K.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/vEyTnOQ.png',
            credit: 'felix geyer',
            source: '',
            source_url: 'https://www.pixiv.net/users/1445969',
          },
          {
            link: 'https://i.imgur.com/FpeOrqp.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tkk9Bxo.jpg',
            credit: 'star',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gcELQfq.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/PsldkGO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-roux',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'yuji itadori',
    source: 'jujutsu kaisen',
    nickname: '',
    img: 'https://i.imgur.com/tDJcKLk.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/DIDJVRg.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/1WBCnsC.png',
            credit: 'azria',
            source: '@hinna_hinna_hin',
            source_url: 'https://twitter.com/hinna_hinna_hin/status/1302378025938382850',
          },
          {
            link: 'https://i.imgur.com/wBaQRG5.png',
            credit: 'felix geyer',
            source: '@hinna_hinna_hin',
            source_url: 'https://twitter.com/hinna_hinna_hin/status/1302378025938382850',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/3MLmDiy.png',
            credit: 'azria',
            source: '@hinna_hinna_hin',
            source_url: 'https://twitter.com/hinna_hinna_hin/status/1302378025938382850',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'asuka langley',
    source: 'neon genesis evangelion',
    nickname: '',
    img: 'https://i.imgur.com/EmRlmeC.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/gD6ZqRl.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HV6XH1P.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/qrgNYCU.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/yrUrQb9.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Gl6t0Vp.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5j8870u.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/w52wYjQ.jpg',
            credit: 'diraxy',
            source: '',
            source_url: '',
          },
          {
            link: 'https://zupimages.net/up/20/50/h4hk.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2WUFKf6.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/nKAsZpF.png',
            credit: 'diraxy',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/s6dMwFI.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/KJHa6KX.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ePFsMpZ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PN21Wfh.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/UMv5fVx.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3bLMLTv.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GAcqQjh.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XPVDcdo.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/KzYYnWE.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-bleu',
      'moderne',
      'fantastique',
      'cacheoeil',
    ]
  },
  {
    name: 'nagisa kaworu',
    source: 'neon genesis evangelion',
    nickname: '',
    img: 'https://i.imgur.com/tscPA0i.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ONxPchN.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/T6uiBC0.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/TV8QfjZ.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'rei ayanami',
    source: 'neon genesis evangelion',
    nickname: '',
    img: 'https://i.imgur.com/plLm0kD.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/jAUzwMh.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Q9gVtNT.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/kuXu3Bo.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/oT6D1NL.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/nK53Zez.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-rouge',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'yorha 2b',
    source: 'nier automata',
    nickname: '',
    img: 'https://i.imgur.com/cpH19yM.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/DCdF6Nk.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vuUwF6b.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/mcCt5bZ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://zupimages.net/up/20/48/3h9b.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6CPF6Os.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/kJtvcDM.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/Emx7Skd.jpg',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'c-blond',
      'fantastique',
      'cacheoeil'
    ]
  },
  {
    name: 'kaine',
    source: 'nier replicant',
    nickname: '',
    img: 'https://i.imgur.com/5kZBEbr.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/PKTyzBG.png',
            credit: 'takeitezy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: '9s',
    source: 'nier automata',
    nickname: '',
    img: 'https://i.imgur.com/0k0ZnPO.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/tseZQKW.png',
            credit: 'aekko',
            source: '',
            source_url: '',
          },
        ]
      },
       {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/3l2p0lx.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-gris',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'yato',
    source: 'noragami',
    nickname: '',
    img: 'https://i.imgur.com/jSiIZ7J.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/mbWm0Wu.png',
            credit: 'milou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'moyens',
      'c-noir',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'yukine',
    source: 'noragami',
    nickname: '',
    img: 'https://i.imgur.com/EHa0cFV.png',
    avatars: [
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/RpmqO4D.png',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'moyens',
      'c-blond',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'gwendolyn',
    source: 'odin sphere',
    nickname: '',
    img: 'https://i.imgur.com/d5REzBA.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i97.servimg.com/u/f97/13/41/18/10/capuci10.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/y3C2iQM.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6zZ62o2.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/yf86StV.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hTBhnpH.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'oswald',
    source: 'odin sphere',
    nickname: '',
    img: 'https://i.imgur.com/0Z0FXXg.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/rgDbRNn.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'velvet',
    source: 'odin sphere',
    nickname: '',
    img: 'https://i.imgur.com/K7w2zxa.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/03hba02.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/YBY66Rx.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'yoo joonghyuk',
    source: 'omniscient reader\'s viewpoint',
    nickname: '',
    img: 'https://i.imgur.com/Aedvbs8.png',
    avatars: [
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/QWRXMnI.png',
            credit: 'star',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'corazon',
    source: 'one piece',
    nickname: 'rocinante donquixote',
    img: 'https://i.imgur.com/z8Rogiy.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/hlPsdi2.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xxJypTm.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Is8jrJI.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'crocodile',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/egnyFzX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/k3OfbF7.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'mature',
      'clair',
      'moyens',
      'c-noir',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'sabo',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/841VC0V.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/2cydFhI.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'shanks',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/cAMH0FH.png',
    avatars: [
      {
        width: '220',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/cgOao3L.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'moyens',
      'c-rouge',
      'c-roux',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'smoker',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/lRRLInu.png',
    avatars: [
      {
        width: '220',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/wcS1phr.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-blanc',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'trafalgar law',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/AYx2JIg.png',
    avatars: [
      {
        width: '220',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CqYHyZ9.png',
            credit: 'kenzatosaure',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'mature',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'linne',
    source: 'oninaki',
    nickname: '',
    img: 'https://i.imgur.com/sq3M1cw.png',
    avatars: [
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/HE9d625.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'moyens',
      'c-blond',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'lorna',
    source: 'over the garden wall',
    nickname: '',
    img: 'https://i.imgur.com/Dxof4EL.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Fd6yNaH.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'ana amari',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/uTlUygN.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/h4f8Gp4.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'mature',
      'sombre',
      'longs',
      'c-brun',
      'c-blanc',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'd.va',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/49tOKau.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/AoiZgm9.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7sLyDYY.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Zt5b0NR.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/SOIr4V2.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/AVhC9ZG.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/D1BA8fK.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/wNPOeFI.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/cDlGROY.png',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'genji',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/Zr6Kp6c.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/T8VfADE.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-vert',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'junkrat',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/B0kodIP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0Z0S3AB.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'mercy',
    source: 'overwatch',
    nickname: 'angela ziegler',
    img: 'https://i.imgur.com/qJo1YHo.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/tLA8H09.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Lw4Pnc7.jpg',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/2mqVp0u.jpg',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/RqBSwc4.jpg',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
      'ailes',
    ]
  },
  {
    name: 'pharah',
    source: 'overwatch',
    nickname: 'fareeha amari',
    img: 'https://i.imgur.com/IEMyCcz.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SJkJ4MR.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NqSsXgl.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2tuBBww.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/C0zeZqw.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8lrcDLj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/AUjgCcl.png',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/jvlPa53.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0VOVPMv.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'moyens',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'anarchy panty',
    source: 'panty & stocking with garterbelt',
    nickname: '',
    img: 'https://i.imgur.com/g0uzPNN.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Czbiy6e.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/idCqR2Q.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5J8x6jZ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HfRGcoe.png',
            credit: 'taku',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
      'ailes',
    ]
  },
  {
    name: 'anarchy stocking',
    source: 'panty & stocking with garterbelt',
    nickname: '',
    img: 'https://i.imgur.com/QcZAgLU.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/W5RsBwn.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/A8LGF1v.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'c-violet',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'akihiko sanada',
    source: 'persona 3',
    nickname: '',
    img: 'https://i.imgur.com/OcgRynW.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/v0qRn1C.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '170',
        height: '170',
        data: [
          {
            link: 'https://i.imgur.com/3qcxGaN.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-gris',
      'c-bleu',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'elizabeth',
    source: 'persona 3',
    nickname: '',
    img: 'https://i.imgur.com/XiW7qw2.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/izPREYY.jpg',
            credit: 'diraxy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'kirijou mitsuru',
    source: 'persona 3',
    nickname: '',
    img: 'https://i.imgur.com/BzIof0J.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/rE4q6jx.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'makoto yuuki',
    source: 'persona 3',
    nickname: 'minato arisato',
    img: 'https://i.imgur.com/2CyiWFd.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/M2Zo1is.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5jRlZar.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ul8VMBk.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hw8qrii.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zqHFPsP.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zBchglF.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/vLRR8xR.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'minako arisato',
    source: 'persona 3',
    nickname: '',
    img: 'https://i.imgur.com/6xWbCxq.png',
    avatars: [
      {
        width: '170',
        height: '170',
        data: [
          {
            link: 'https://i.imgur.com/KJjBFEZ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'adachi tohru',
    source: 'persona 4',
    nickname: '',
    img: 'https://i.imgur.com/EEhuAcs.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/adaach12.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'akira kurusu',
    source: 'persona 5',
    nickname: 'joker',
    img: 'https://i.imgur.com/oZxGcAa.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/795jPce.gif',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tZDK1F2.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/EseUaxW.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/j7qNNqh.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sLQZ4P6.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/qo4cDsd.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Aspx47i.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/O9HD3gr.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gXPtqPm.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://imgur.com/hRWRK8K.png',
            credit: 'sed',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5TmerlW.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/SwPqrAJ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'y-rouge',
      'fantastique',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'ann takamaki',
    source: 'persona 5',
    nickname: 'panther',
    img: 'https://i.imgur.com/EUdFIJs.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/y9cqSX2.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'futaba sakura',
    source: 'persona 5',
    nickname: 'oracle',
    img: 'https://i.imgur.com/Ag3fi0U.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/RiRuYfO.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-marron',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'kasumi yoshizawa',
    source: 'persona 5',
    nickname: 'sumire yoshizawa, violet',
    img: 'https://i.imgur.com/BQ5vTS2.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/k2HxVje.jpg',
            credit: 'sed',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'makoto niijima',
    source: 'persona 5',
    nickname: 'queen',
    img: 'https://i.imgur.com/fHnvmzk.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Alsw8BK.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-rouge',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'kuranosuke shiraishi',
    source: 'prince of tennis',
    nickname: '',
    img: 'https://i.imgur.com/tSw6k3C.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/7kB0Gm6.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'clive dove',
    source: 'professeur layton',
    nickname: '',
    img: 'https://i.imgur.com/Afby1DK.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/Z528oQs.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-brun',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'lio fotia',
    source: 'promare',
    nickname: '',
    img: 'https://i.imgur.com/ebltR8z.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/9NW8nVb.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Zk53VzG.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/pGsGj7G.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://www.zupimages.net/up/21/18/emvo.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/bJuzhgG.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-vert',
      'y-violet',
      'fantastique',
      'androgyne',
    ]
  },
  {
    name: 'shogo makishima',
    source: 'psycho-pass',
    nickname: '',
    img: 'https://i.imgur.com/RxQELKM.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'http://i.imgur.com/YmjNAaI.png',
            credit: 'taku',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/8ob7ysx.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'kougami shinya',
    source: 'psycho-pass',
    nickname: '',
    img: 'https://i.imgur.com/KdDsJzB.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/WF917nM.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DHbxxZR.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'miki sayaka',
    source: 'puella magi madoka magica',
    nickname: '',
    img: 'https://i.imgur.com/sgbEC9A.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xz4tCSb.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/28IalMF.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'tenjou utena',
    source: 'revolutionary girl utena',
    nickname: '',
    img: 'https://i.imgur.com/hK94QJB.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/5Ey5UVP.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'oc',
    source: 're°',
    nickname: '',
    img: 'https://i.imgur.com/m5Md4MO.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/O04B2EE.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/d2NX5Qs.png',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0v4JAz1.png',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/h8WweDJ.png',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/iEQSkVY.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/tp4GDhW.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'moderne',
    ]
  },
  {
    name: 'evil morty',
    source: 'rick and morty',
    nickname: '',
    img: 'https://i.imgur.com/rAwKRuj.png',
    avatars: [
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/vftDH32.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LIxHVn5.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-brun',
      'y-noir',
      'moderne',
      'cacheoeil',
    ]
  },
  {
    name: 'miami morty',
    source: 'rick and morty',
    nickname: '',
    img: 'https://i.imgur.com/C3R0ejj.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SaZpAPN.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'sombre',
      'moyens',
      'c-blond',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'rick sanchez',
    source: 'rick and morty',
    nickname: '',
    img: 'https://i.imgur.com/9sv0kjv.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xzFTgd1.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-bleu',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'jack frost',
    source: 'rise of the guardians',
    nickname: '',
    img: 'https://i.imgur.com/od0xvxf.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/JKOA0FJ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/rXTbo7y.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'jughead jones',
    source: 'riverdale',
    nickname: '',
    img: 'https://i.imgur.com/8ss3PNg.png',
    avatars: [
      {
        width: '200',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/BpmKTXb.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/htJPDFD.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'pyrrha nikos',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/6UDB4XM.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/c0yrOZU.jpg',
            credit: 'diraxy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'weiss schnee',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/c0s2MsD.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/wbkn16F.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Cv5noI6.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CPWouYo.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'black lady',
    source: 'sailor moon',
    nickname: '',
    img: 'https://i.imgur.com/swgrBzx.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/LQMi85w.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-rouge',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'sailor moon',
    source: 'sailor moon',
    nickname: 'usagi tsukino',
    img: 'https://i.imgur.com/025cotM.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SNcuolw.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oIfQ2Yc.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'sailor neptune',
    source: 'sailor moon',
    nickname: 'michiru kaioh',
    img: 'https://i.imgur.com/P0HjbPa.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Xw5EgXf.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/gJIupQT.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-vert',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'sailor mars',
    source: 'sailor moon',
    nickname: 'rei hino',
    img: 'https://i.imgur.com/JYqzeuS.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Ehnv8zz.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/iZSc6ZM.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-violet',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'sailor saturn',
    source: 'sailor moon',
    nickname: 'tomoe hotaru',
    img: 'https://i.imgur.com/cbVdP1o.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ViSDatO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/R5VcjKx.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/2WOJmJm.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-violet',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'sailor venus',
    source: 'sailor moon',
    nickname: 'minako aino',
    img: 'https://i.imgur.com/EszbyQk.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/vTteOUN.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HE46NHv.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VGvJX1L.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'saphir',
    source: 'sailor moon',
    nickname: '',
    img: 'https://i.imgur.com/vwUxFUY.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/R8sIY84.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'luna',
    source: 'sailor moon',
    nickname: '',
    img: 'https://i.imgur.com/ZqDqIax.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/RKaMwca.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'sunako kirishiki',
    source: 'shiki',
    nickname: '',
    img: 'https://i.imgur.com/D9uYBtY.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/JJZ2BNR.png',
            credit: 'taku',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-violet',
      'y-noir',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'towa',
    source: 'slow damage',
    nickname: '',
    img: 'https://i.imgur.com/rS74Ull.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/3lRLnFz.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Vni46if.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NzU8645.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/c4gmzsY.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'courts',
      'c-noir',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'gwen stacy',
    source: 'spider-man',
    nickname: '',
    img: 'https://i.imgur.com/T2633NL.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/gPtstos.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'cain',
    source: 'starfighter',
    nickname: '',
    img: 'https://i.imgur.com/aJKaXlc.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/LEeDLlu.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'c-bleu',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'obi-wan kenobi',
    source: 'star wars',
    nickname: '',
    img: 'https://i.imgur.com/pSoAx6n.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/m46PN2A.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
   {
    name: 'kylo ren',
    source: 'star wars',
    nickname: '',
    img: 'https://i.imgur.com/MxD3RWX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/tRxWrXl.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'rey skywalker',
    source: 'star wars',
    nickname: '',
    img: 'https://i.imgur.com/1zndcjO.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/9ArpCnZ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/IqNZgvC.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/vyb92qA.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/L5jF6Zb.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'choi mochimazzi',
    source: 'tamago market',
    nickname: '',
    img: 'https://i.imgur.com/t7H0VK6.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/zPPqhwk.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'sombre',
      'moyens',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'qiu tong',
    source: 'tamen de gushi',
    nickname: '',
    img: 'https://i.imgur.com/wfee7zO.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/qNKYBLn.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ao0Bbzd.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'rapunzel',
    source: 'tangled',
    nickname: 'raiponce',
    img: 'https://i.imgur.com/FpDOfmH.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ZNJgdnj.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'tank girl',
    source: 'tank girl',
    nickname: 'rebecca buck',
    img: 'https://i.imgur.com/MNmVFSc.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xCxboQP.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'moderne',
    ]
  },
  {
    name: 'raven',
    source: 'teen titans',
    nickname: '',
    img: 'https://i.imgur.com/wtRI3yv.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/DnwOmRv.png',
            credit: 'kenzatosaure',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/WCQEi3I.png',
            credit: 'fcksleeping',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'c-violet',
      'y-violet',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'jin mori',
    source: 'the god of highschool',
    nickname: '',
    img: 'https://i.imgur.com/8CkMnyk.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/XvDlSJy.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'shibuya rin',
    source: 'the idolmaster',
    nickname: '',
    img: 'https://i.imgur.com/0jHbV8k.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/m9egD4k.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'ghirahim',
    source: 'the legend of zelda',
    nickname: '',
    img: 'https://i.imgur.com/zpcnMz9.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/8HdAcxv.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9kMJ35R.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'link',
    source: 'the legend of zelda',
    nickname: '',
    img: 'https://i.imgur.com/auFNho5.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/mbTHoTT.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/kBRdbo1.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oekFU56.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QzR2eGj.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tOb95nj.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/WS8dPb5.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/yyAnuqT.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9ILZNGg.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/N45LX68.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/a3ht7he.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'zelda',
    source: 'the legend of zelda',
    nickname: '',
    img: 'https://i.imgur.com/5ie1Dl5.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/g9MoQ6j.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/r16kklO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VvRns3x.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GNS8gmI.png',
            credit: 'morgyn',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/lZWmKGp.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/TjROjsG.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/ke5S0iI.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/l2GH3ub.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/S4YaqdB.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'impa',
    source: 'the legend of zelda',
    nickname: '',
    img: 'https://i.imgur.com/TxzfW0F.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/1r57Pj8.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CbRtEW2.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/xbTx2Ju.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HM2pdm0.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-marron',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'thranduil',
    source: 'the lord of the ring',
    nickname: '',
    img: 'https://i.imgur.com/8fUvc4s.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/bGW0lSZ.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/F4V0oxS.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'emma',
    source: 'the promised neverland',
    nickname: '',
    img: 'https://i.imgur.com/SIvg3ie.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xuCIc1l.png',
            credit: 'fcksleeping',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-roux',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'norman',
    source: 'the promised neverland',
    nickname: '',
    img: 'https://i.imgur.com/wZE8PYb.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/5mbHXXI.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-blanc',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'ray',
    source: 'the promised neverland',
    nickname: '',
    img: 'https://i.imgur.com/ut3WdTZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/6Pwj00b.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/yjA3MoL.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'minato kirigaya',
    source: 'therapy game',
    nickname: '',
    img: 'https://i.imgur.com/lF8apdT.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/ApouIGJ.png',
            credit: 'wiisuki',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'ivan krasny',
    source: 'the red king',
    nickname: '',
    img: 'https://i.imgur.com/D1JKn55.png',
    avatars: [
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/Rbk4E9O.png',
            credit: 'fcksleeping',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-marron',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'kaneki ken',
    source: 'tokyo ghoul',
    nickname: '',
    img: 'https://i.imgur.com/0YdF3jm.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/XGrHftB.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zOyEcuI.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/1oVAZxb.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/N0WNLId.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2Mn4k6o.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HijwT7V.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/escS0Pe.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/UPRTpyj.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/V1kRaAf.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/iLG0gUa.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/G9f2l7R.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/boiewwB.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/pvfkge3.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/x8JXBSF.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ealwMNy.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/rp9708f.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gdAEpyH.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/wHOTy1u.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'c-blanc',
      'y-noir',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'suzuya juuzou',
    source: 'tokyo ghoul',
    nickname: '',
    img: 'https://i.imgur.com/vOUWtQj.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/rD8lkPE.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ELs4hq0.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/yspw93f.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'moyens',
      'c-blanc',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'touka kirishima',
    source: 'tokyo ghoul',
    nickname: '',
    img: 'https://i.imgur.com/gZjCBP6.png',
    avatars: [
      {
        width: '170',
        height: '170',
        data: [
          {
            link: 'https://i.imgur.com/QMuIX2f.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-violet',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'tsukiyama shuu',
    source: 'tokyo ghoul',
    nickname: '',
    img: 'https://i.imgur.com/IB3C1WZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/3WhuK2k.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-violet',
      'y-violet',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'uta',
    source: 'tokyo ghoul',
    nickname: '',
    img: 'https://i.imgur.com/bFMr375.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/8dJ4wtK.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/RFtuObu.gif',
            credit: 'fcksleeping',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'eto yoshimura',
    source: 'tokyo ghoul:re',
    nickname: '',
    img: 'https://i.imgur.com/nmtDjoI.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Ej0H4hC.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-vert',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'sasaki haise',
    source: 'tokyo ghoul:re',
    nickname: 'kaneki ken',
    img: 'https://i.imgur.com/KdfrW24.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ZScd7wc.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'c-blanc',
      'y-noir',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'urie kuki',
    source: 'tokyo ghoul:re',
    nickname: '',
    img: 'https://i.imgur.com/qsIG0r8.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/uGKJgHi.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-violet',
      'y-noir',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'alice margatroid',
    source: 'touhou project',
    nickname: '',
    img: 'https://zupimages.net/up/20/50/drpg.png',
    avatars: [
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://zupimages.net/up/20/50/4dby.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
   {
    name: 'hong meiling',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/CZ1jBf7.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://www.zupimages.net/up/21/16/cnw4.png',
            credit: 'sixtine',
            source: 'monety',
            source_url: 'https://twitter.com/monety1285',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-vert',
      'moderne',
      'fantastique',
    ]
  },
   {
    name: 'yuyuko saigyouji',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/LrgYhLh.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/WcFj811.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/qzBmDu4.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-rouge',
      'fantastique',
    ]
  },
   {
    name: 'yakumo ran',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/iCApkhC.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://www.zupimages.net/up/21/16/k6su.png',
            credit: 'sixtine',
            source: 'dodoko',
            source_url: 'https://twitter.com/dodoko0724',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-jaune',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'rin kaenbyou',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/wrXUN3x.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://www.zupimages.net/up/21/16/xctd.png',
            credit: 'sixtine',
            source: 'garan co',
            source_url: 'https://www.pixiv.net/stacc/garan_co',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-rouge',
      'moderne',
      'fantastique',
      'animal',
      'oreilles',
    ]
  },
  {
    name: 'aya shameimaru',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/fv9V8Kh.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0gfNQPg.jpg',
            credit: 'diraxy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-rouge',
      'fantastique',
      'ailes',
      'oreilles',
    ]
  },
  {
    name: 'fujiwara no mokou',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/9XDmZ76.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/itvLv6E.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Q6dAX5M.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vm6B2SZ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ge9xaSh.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/eBG11Hi.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/CwMwzKh.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'izayoi sakuya',
    source: 'touhou project',
    nickname: '',
    img: 'https://zupimages.net/up/20/48/1g72.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/dyUIw1i.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://zupimages.net/up/20/48/eban.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
          {
            link: 'https://zupimages.net/up/20/48/uwle.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/M6vB4km.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/vXQ54dB.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'konpaku youmu',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/hAJIl9w.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ZZRGJxP.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'patchouli knowledge',
    source: 'touhou project',
    nickname: '',
    img: 'https://www.zupimages.net/up/20/48/9dta.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://zupimages.net/up/20/48/4ydx.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
          {
            link: 'https://zupimages.net/up/20/48/k2j2.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
          {
            link: 'https://zupimages.net/up/20/49/cit0.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '400',
        data: [
          {
            link: 'https://zupimages.net/up/20/49/8xgb.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'remilia scarlet',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/Wmlfxq1.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://zupimages.net/up/20/48/0ri2.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-rouge',
      'y-violet',
      'fantastique',
      'ailes',
    ]
  },
   {
    name: 'emilia',
    source: 're-zero',
    nickname: '',
    img: 'https://i.imgur.com/6uNkUfV.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/nB0seiW.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6NkYzgb.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-violet',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'sanae kochiya',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/Zbw6PEV.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/2A5lnBc.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'chloe price',
    source: 'life is strange',
    nickname: '',
    img: 'https://i.imgur.com/3GqFV7T.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/gAKH4Nh.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'courts',
      'c-bleu',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'ichigo hitofuri',
    source: 'touken ranbu',
    nickname: '',
    img: 'https://i.imgur.com/QMrG54H.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/kP20LP7.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'izumi no kami kanesada',
    source: 'touken ranbu',
    nickname: '',
    img: 'https://i.imgur.com/8OKyATv.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/aQHFOUO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'kashuu kiyomitsu',
    source: 'touken ranbu',
    nickname: '',
    img: 'https://i.imgur.com/4GwurDB.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/EzWplJQ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/WnEsl0o.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'kogitsunemaru',
    source: 'touken ranbu',
    nickname: '',
    img: 'https://i.imgur.com/ZBt0bXF.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/D3An7rb.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ojzXT98.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/q5Pik9K.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'mikazuki munechika',
    source: 'touken ranbu',
    nickname: '',
    img: 'https://i.imgur.com/8T8VE3Z.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/o2lXpoE.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'shokudaikiri mitsutada',
    source: 'touken ranbu',
    nickname: '',
    img: 'https://i.imgur.com/0OxHDvc.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/lRCMvqj.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IEeQY4H.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fI8mvF4.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/O6lB6Y5.gif',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/or8jyoN.jpg',
            credit: 'moineau',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-jaune',
      'fantastique',
      'cacheoeil',
    ]
  },
  {
    name: 'tsurumaru kuninaga',
    source: 'touken ranbu',
    nickname: '',
    img: 'https://i.imgur.com/7Cmtbc6.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/g35agjp.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'red',
    source: 'transistor',
    nickname: '',
    img: 'https://i.imgur.com/GkAWl9Q.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/UydIHx5.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QBqjrwo.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'five',
    source: 'umbrella academy',
    nickname: '',
    img: 'https://i.imgur.com/AAqr8u1.png',
    avatars: [
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/TgcPczF.png',
            credit: 'star',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'klaus hargreeves',
    source: 'umbrella academy',
    nickname: '',
    img: 'https://i.imgur.com/nqWscxe.png',
    avatars: [
       {
        width: '250',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/wgZ3uxz.png',
            credit: 'takeitezy',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/gBq6Jh4.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'chibana mio',
    source: 'umibe no etranger',
    nickname: '',
    img: 'https://i.imgur.com/gUkQVBX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0uYTBzg.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'luka crosszeria',
    source: 'uragiri',
    nickname: '',
    img: 'https://i.imgur.com/mH9pvZN.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/olZCSfo.png',
            credit: 'krakhot',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'dominique de sade',
    source: 'vanitas no carte',
    nickname: '',
    img: 'https://i.imgur.com/oqJfLG4.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Q07m0Ty.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sF33gGg.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'ui',
    source: 'ui',
    nickname: '',
    img: 'https://i.imgur.com/q2UxfYS.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CPETnmE.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DqlOfls.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ilejx0j.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/y2wfYwA.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/v6pFXHl.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/RgIfB5R.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/i7xRD2O.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
   {
    name: 'vincent nightray',
    source: 'pandora hearts',
    nickname: '',
    img: 'https://i59.servimg.com/u/f59/20/16/89/62/icon10.jpg',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/vincen11.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-jaune',
      'fantastique',
    ]
  },
  {
   name: 'axel',
   source: 'kingdom hearts',
   nickname: '',
   img: 'https://i.imgur.com/WpXKYdM.png',
   avatars: [
     {
       width: '200',
       height: '320',
       data: [
         {
           link: 'https://i.imgur.com/79Mku7n.png',
           credit: 'espérance',
           source: '',
           source_url: '',
         },
       ]
     },
   ],
   tags: [
     'homme',
     'jeune',
     'clair',
     'moyens',
     'c-rouge',
     'y-bleu',
     'fantastique',
   ]
 },
 {
  name: 'tobi',
  source: 'naruto',
  nickname: '',
  img: 'https://i.imgur.com/FOaslg6.png',
  avatars: [
    {
      width: '200',
      height: '320',
      data: [
        {
          link: 'https://i.imgur.com/l7T0WiY.png',
          credit: 'caldinia',
          source: '',
          source_url: '',
        },
      ]
    },
  ],
  tags: [
    'homme',
    'jeune',
    'clair',
    'courts',
    'c-noir',
    'fantastique',
  ]
},
{
 name: 'aomine daiki',
 source: 'kuroko no basket',
 nickname: '',
 img: 'https://i.imgur.com/uhPzkVn.png',
 avatars: [
   {
     width: '200',
     height: '320',
     data: [
       {
         link: 'https://i.imgur.com/NyuBHUD.png',
         credit: 'momosha',
         source: '',
         source_url: '',
       },
       {
         link: 'https://i.imgur.com/MulrFJ7.png',
         credit: 'momosha',
         source: '',
         source_url: '',
       },
     ]
   },
 ],
 tags: [
   'homme',
   'jeune',
   'sombre',
   'courts',
   'c-bleu',
   'y-bleu',
   'moderne',
 ]
},
{
 name: 'hinata shoyo',
 source: 'haikyuu!!',
 nickname: '',
 img: 'https://i.imgur.com/qIp9d5w.png',
 avatars: [
   {
     width: '200',
     height: '320',
     data: [
       {
         link: 'https://i.imgur.com/Hbe1K9X.png',
         credit: 'cheshireftw',
         source: '',
         source_url: '',
       },
     ]
   },
 ],
 tags: [
   'homme',
   'jeune',
   'clair',
   'courts',
   'c-roux',
   'y-orange',
   'moderne',
 ]
},
{
 name: 'bokuto koutarou',
 source: 'haikyuu!!',
 nickname: '',
 img: 'https://i.imgur.com/QtUCkSn.png',
 avatars: [
   {
     width: '200',
     height: '320',
     data: [
       {
         link: 'https://i.imgur.com/QfAPdw5.gif',
         credit: 'cheshireftw',
         source: '',
         source_url: '',
       },
     ]
   },
 ],
 tags: [
   'homme',
   'jeune',
   'clair',
   'courts',
   'c-noir',
   'c-blanc',
   'y-jaune',
   'moderne',
 ]
},
{
 name: 'ichimoku ren',
 source: 'onmyouji',
 nickname: '',
 img: 'https://i.imgur.com/xgTxCzE.png',
 avatars: [
   {
     width: '200',
     height: '320',
     data: [
       {
         link: 'https://i.imgur.com/NVVEKS5.png',
         credit: 'espérance',
         source: '',
         source_url: '',
       },
     ]
   },
 ],
 tags: [
   'homme',
   'jeune',
   'clair',
   'longs',
   'c-blanc',
   'y-noir',
   'y-jaune',
   'fantastique',
   'cornes',
   'oreilles',
 ]
},
  {
    name: 'original characters',
    source: 'various artists',
    nickname: '',
    img: 'https://i.imgur.com/76dTFai.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/dLxuUK1.png',
            credit: 'palourde',
            source: 'asukaziye',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/MLRgHxp.png',
            credit: 'clyde',
            source: 'hananohara_cano',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GUeD8zf.png',
            credit: 'clyde',
            source: 'manabu-chan',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LHvpCQM.gif',
            credit: 'moineau',
            source: 'waricka',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DbumT99.gif',
            credit: 'moineau',
            source: 'kaneoya sachiko',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/o0csBXW.jpg',
            credit: 'moineau',
            source: 'sillvi',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2KRFFwg.png',
            credit: 'kooerry',
            source: 'punziella',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VXNTLC3.jpg',
            credit: 'nana',
            source: 'caidychen',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Z3cSMui.jpg',
            credit: 'moineau',
            source: 'est-em',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
    ]
  },
  {
    name: 'gumi',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/VzaR9As.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/36Enm9C.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2aPTOAd.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
            {
            link: 'https://i.imgur.com/iSxRA0m.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-vert',
      'y-jaune',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'miku hatsune',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/HfmbdG6.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/b8zBDpA.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ju6Ppx0.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sYYHrWP.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/L9Cc6t3.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/07v2xmx.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8G5psNJ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/SPD6Bx1.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9VSK8Jc.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/1rkM3jH.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/f3rl41E.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/BtlDkgw.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/F2zK1AY.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Pu6SiIx.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/X5prqr6.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LdPCTz9.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7j61UNG.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/qN601cu.jpg',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/O2uiWXi.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/t90kgd4.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/aM24Zqc.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/c5LjlaX.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/BpcU5H8.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2ba4JNR.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'megurine luka',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/OVJnMME.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/DSZK9lF.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3H1N6z2.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i39.servimg.com/u/f39/13/41/18/10/aleth11.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hLjFMcz.jpg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.ibb.co/y5jcRCd/Avatar-Luka.jpg',
            credit: 'wisukii',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'oliver',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/2wsGmWb.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/E8kvL6t.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'courts',
      'c-blond',
      'y-jaune',
      'moderne',
      'fantastique',
      'cacheoeil'
    ]
  },
  {
    name: 'rin kagamine',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/lpVig94.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/hELKWlV.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/Y9lTYa6.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/5DrvaOP.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/JLOKaQS.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'allura',
    source: 'voltron',
    nickname: '',
    img: 'https://i.imgur.com/JfSTl9R.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/prvioYC.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/iEYjtkf.jpg',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'keith kogane',
    source: 'voltron',
    nickname: '',
    img: 'https://i.imgur.com/utzQ3vq.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://zupimages.net/up/20/44/r64c.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/P3xRKFd.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/eKCvXgP.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/EvjWqiM.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hzf38fB.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/dQMfQcB.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LDt0Mrd.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jevYNw8.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'lance mcclain',
    source: 'voltron',
    nickname: '',
    img: 'https://i.imgur.com/47oKL1x.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/sh9pZ4T.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/JIqMKn5.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '330',
        data: [
          {
            link: 'https://i.imgur.com/UF2KI3w.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/yPjy8Sq.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/7ky4lVn.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-brun',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'matthew holt',
    source: 'voltron',
    nickname: '',
    img: 'https://i.imgur.com/kcRbtaU.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/VSTM54J.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'thorns',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/Gg9Cq6l.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/j2pjEhI.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ukcm0g5.png',
            credit: 'takeitezy',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fdgESw9.png',
            credit: 'takeitezy',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/85ctiAw.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'moyens',
      'c-brun',
      'y-jaune',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'shiro',
    source: 'voltron',
    nickname: '',
    img: 'https://i.imgur.com/iR3c6O8.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/vAvtFS3.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/g9nrLk9.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'c-blanc',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'wadanohara',
    source: 'wadanohara and the great blue sea',
    nickname: '',
    img: 'https://i.imgur.com/yAE6GCv.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/BVvPJnX.jpg',
            credit: 'diraxy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-brun',
      'y-gris',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'numa shichiro',
    source: 'warashibe tantei - numa shichiro',
    nickname: '',
    img: 'https://i.imgur.com/teQdIhp.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Dr3B5Hf.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'cecil palmer',
    source: 'welcome to nightvale',
    nickname: '',
    img: 'https://i.imgur.com/3XSVd5d.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/UimTGUO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gUdOZYH.png',
            credit: 'taku',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'mature',
      'clair',
      'courts',
      'c-noir',
      'c-blanc',
      'y-violet',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'yami yugi',
    source: 'yu-gi-oh!',
    nickname: '',
    img: 'https://i.imgur.com/lr2CyHu.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/22RdJ4S.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'c-violet',
      'c-noir',
      'y-violet',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'madotsuki',
    source: 'yume nikki',
    nickname: '',
    img: 'https://i.imgur.com/7CEedog.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ALK66G9.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-brun',
      'y-rouge',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'victor nikoforov',
    source: 'yuri!!! on ice',
    nickname: '',
    img: 'https://i.imgur.com/JCGaQa4.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Y2xujGS.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/yhWiIPb.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/RV2U51M.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'courts',
      'c-blanc',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'yuri katsuki',
    source: 'yuri!!! on ice',
    nickname: '',
    img: 'https://i.imgur.com/wH9A4sy.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/hg1Abus.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'yuri plisetsy',
    source: 'yuri!!! on ice',
    nickname: '',
    img: 'https://i.imgur.com/jxRlp0o.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/8iWxkWA.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7RrTnJC.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jQT8ZoF.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZK2u6fy.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/ochAwJ2.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/b2Myuzm.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GCBpKEB.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'phichit chulanon',
    source: 'yuri!!! on ice',
    nickname: '',
    img: 'https://i.imgur.com/BxMvhgR.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/phichi13.png',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'yusuke uramechi',
    source: 'yuyu hakusho',
    nickname: '',
    img: 'https://i.imgur.com/AoYTbQS.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/h0VJXVl.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/6RNCoDv.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'riku',
    source: 'kingdom hearts',
    nickname: '',
    img: 'https://i.imgur.com/dcsqJLD.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Qf0u0SD.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/pDGa00N.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZmimpEy.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-gris',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'sora',
    source: 'kingdom hearts',
    nickname: '',
    img: 'https://i.imgur.com/cmn57Ke.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CTdlX0J.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/zodwXuP.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/vZXMFt6.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ee5rUgj.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
   {
    name: 'ventus',
    source: 'kingdom hearts',
    nickname: '',
    img: 'https://i.imgur.com/eDYLfqz.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/eApCNK3.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3CX5KLu.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
   {
    name: 'aqua',
    source: 'kingdom hearts',
    nickname: '',
    img: 'https://i.imgur.com/qCUaUa1.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/6vqbUw8.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oU1A2az.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Tgbvhbp.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tuzRFO4.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'moyens',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'yusuke kitagawa',
    source: 'persona 5',
    nickname: '',
    img: 'https://i.imgur.com/Gdlk3iS.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/sJTNyqs.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/5ESBdY9.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-gris',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'ganyu',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/e2fdJY7.jpg',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/At1zlGr.png',
            credit: 'diraxy',
            source: 'mono',
            source_url: 'https://twitter.com/mono__02/status/1377640651316060160?s=20',
          },
          {
            link: 'https://i.imgur.com/C5oaexF.png',
            credit: 'azria',
            source: 'hts21',
            source_url: 'https://www.pixiv.net/en/artworks/87663187',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/2th6V4T.png',
            credit: 'azria',
            source: 'hts21',
            source_url: 'https://www.pixiv.net/en/artworks/87663187',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-violet',
      'fantastique',
      'cornes',
      'animal',
    ]
  },
  {
    name: 'a2',
    source: 'nier automata',
    nickname: '',
    img: 'https://i.imgur.com/u99Cacc.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-gris',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'abigail williams',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/elyLIuY.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/eb4M9vc.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PDNRtVI.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LVF4Xwx.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/G8fhr2p.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vHwjFk3.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0bpQnms.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/1nO296n.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/whH2VZI.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'ace',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/U1gbKdB.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/3KesAP2.png',
            credit: 'esté',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'adonis otogari',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/q1K8Xpu.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-violet',
      'y-orange',
      'moderne',
    ]
  },
  {
    name: 'aether',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/dfnzF8u.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/td70UyR.png',
            credit: 'lacrimosa',
            source: '',
            source_url: ''
          },
        ]
      },
       {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/X2lOPQB.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'aiji yanagi',
    source: 'collar x malice',
    nickname: '',
    img: 'https://i.imgur.com/bzWsSa2.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'akagi',
    source: 'kantai collection',
    nickname: '',
    img: 'https://i.imgur.com/tOdus6J.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-orange',
      'fantastique',
    ]
  },
  {
    name: 'akane aoi',
    source: 'jibaku shounen hanako-kun',
    nickname: '',
    img: 'https://i.imgur.com/osW4I8p.png',
    avatars: [
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'moyens',
      'c-brun',
      'y-orange',
      'moderne',
    ]
  },
  {
    name: 'akane awakusu',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/4cWkjRL.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'akechi gorou',
    source: 'persona 5',
    nickname: '',
    img: 'https://i.imgur.com/x1dZDtS.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'akihiko kaji',
    source: 'given',
    nickname: '',
    img: 'https://i.imgur.com/3QxEO0e.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'albedo',
    source: 'overlord',
    nickname: '',
    img: 'https://i.imgur.com/stlS9dQ.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-jaune',
      'fantastique',
      'cornes',
      'ailes',
    ]
  },
  {
    name: 'alex asano',
    source: 'crew of cuties',
    nickname: '',
    img: 'https://i.imgur.com/mDXCtaJ.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'amalia shera sharm',
    source: 'wakfu',
    nickname: '',
    img: 'https://i.imgur.com/sfFgtPP.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-vert',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'amber',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/jDrm3fQ.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'annette fantine dominic',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/NaxVdJf.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'longs',
      'c-roux',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'annie leondhart',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/STtXIKG.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'anri sonohara',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/t8lFNQa.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-rouge',
      'lunettes',
      'moderne',
    ]
  },
  {
    name: 'anzu',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/A8NncdF.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'aoba tsumugi',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/32sNand.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-jaune',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'aoi akane',
    source: 'jibaku shounen hanako-kun',
    nickname: '',
    img: 'https://i.imgur.com/zC1mxN7.png',
    avatars: [
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'aoi asahina',
    source: 'dangan ronpa',
    nickname: '',
    img: 'https://i.imgur.com/Vw5v2XW.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'aoi kanzaki',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/6IB1oCT.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-violet',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'aoki lapis',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/ldFBwIH.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'arashi narukami',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/jfdrlVE.png',
    avatars: [
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'archer',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/XZnxG6f.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-blanc',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'armin arlert',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/lFzyLUm.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/3NeRWjY.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'ashe duran',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/IvX1bMt.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-gris',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'shuten-douji',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/HEhRB8g.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'moyens',
      'c-violet',
      'y-violet',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'yu miaoyi',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/OxyAHSK.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-rouge',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'astesia',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/YiGmgQ6.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'athena cykes',
    source: 'ace attorney',
    nickname: '',
    img: 'https://i.imgur.com/MaQNtHq.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'ayame sarutobi',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/zDGwJMT.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'fantastique',
      'lunettes',
    ]
  },
  {
    name: 'balthus von albrecht',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/Jgmmrev.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'bayonetta',
    source: 'bayonetta',
    nickname: '',
    img: 'https://i.imgur.com/XQSWNUq.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'mature',
      'clair',
      'longs',
      'courts',
      'c-noir',
      'y-bleu',
      'fantastique',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'bernadetta von varley',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/xJAjQth.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'longs',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'blake belladonna',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/M9e1uIs.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-jaune',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'blaze',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/p1MN3De.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-bleu',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'boa hancock',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/rsSszZV.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'bort',
    source: 'houseki no kuni',
    nickname: '',
    img: 'https://i.imgur.com/DhUxOqs.png',
    avatars: [
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'camilla',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/jmLR0XA.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'caroline',
    source: 'persona 5',
    nickname: '',
    img: 'https://i.imgur.com/Rj0EN5l.png',
    avatars: [
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'moderne',
      'cacheoeil',
    ]
  },
  {
    name: 'caspar von berglietz',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/HYe0t1X.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'tamamo no mae',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/LOpo6Dc.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CdoEk3X.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DnIA6fa.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/OoycgIq.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-jaune',
      'fantastique',
      'animal,'
    ]
  },
  {
    name: 'marie-antoinette',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/X3zTqLy.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/8EaJgOk.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/0t5ai2B.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'merlin',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/Lv90Olq.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/hblfJDY.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ri922jB.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/n5uW5xI.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/YGvkjyP.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fWrqEJq.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'catherine rubens charon',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/Pix0GI3.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0uplC9L.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/M17tvuq.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0jUI91q.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'celica',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/Fme9WvS.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'ch\'en',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/AahZ2gl.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-rouge',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'chiaki morisawa',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/eOEzIVf.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-orange',
      'moderne',
    ]
  },
  {
    name: 'chikage kazama',
    source: 'hakuouki',
    nickname: '',
    img: 'https://i.imgur.com/czLGvSP.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-rouge',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'chuuya nakahara',
    source: 'bundou stray dogs',
    nickname: '',
    img: 'https://i59.servimg.com/u/f59/20/16/89/62/bloggi45.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'chikage rokujo',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/Ec0MPbm.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-roux',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'chizuru yukimura',
    source: 'hakuouki',
    nickname: '',
    img: 'https://i.imgur.com/AO3V5qX.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'moyens',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'chrom',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/21UY2Ot.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'cliffheart',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/QcnVPFD.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'c-gris',
      'y-bleu',
      'y-gris',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'constance von nuvelle',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/PFMjf1N.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'courts',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'corrin (male)',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/Ov43kUF.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-gris',
      'y-rouge',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'cul',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/tAfXFNk.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'dahlia orellana',
    source: 'crew of cuties',
    nickname: '',
    img: 'https://i.imgur.com/SgetWEE.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'decim',
    source: 'death parade',
    nickname: '',
    img: 'https://i59.servimg.com/u/f59/20/16/89/62/bloggi51.jpg',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'dante',
    source: 'devil may cry',
    nickname: '',
    img: 'https://i.imgur.com/WA1DCrd.jpg',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'dawn',
    source: 'pokemon',
    nickname: '',
    img: 'https://i.imgur.com/CFietgx.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'dedue morinaro',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/gIELDKh.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'djeeta',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/2JlIbbo.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'dorothea arnault',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/taMH7Zj.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/wiwpFRz.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/SfZjwHn.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/wUoT0Ej.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/AYvwO29.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/FIFVGoX.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vwsgGUe.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/lyb9lUE.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/EjEb9J5.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'douma',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/BmIs8gy.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'ema skye',
    source: 'ace attorney',
    nickname: '',
    img: 'https://i.imgur.com/m7WfX9E.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'emiya shirou',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/KbKipUs.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-roux',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'envy',
    source: 'fullmetal alchemist',
    nickname: '',
    img: 'https://i.imgur.com/UNvPESf.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-noir',
      'fantastique',
      'androgyne',
    ]
  },
  {
    name: 'evangelyne percedal',
    source: 'wakfu',
    nickname: '',
    img: 'https://i.imgur.com/MQz1NFv.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'longs',
      'c-blond',
      'y-vert',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'euphemia li britannia',
    source: 'code geass',
    nickname: '',
    img: 'https://i.imgur.com/QfMgCgk.png',
    avatars: [
      {
          width: '200',
          height: '320',
          data: [
            {
              link: 'https://imgur.com/9H2wo0t.png',
              credit: 'caldinia',
              source: ''
            },
          ]
        }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'executor',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/WwQ0zMa.png',
    avatars: [
      {
          width: '200',
          height: '320',
          data: [
            {
              link: 'https://imgur.com/4VP1cxS.png',
              credit: 'cheshireftw',
              source: ''
            },
            {
              link: 'https://imgur.com/ZrIomhy.png',
              credit: 'cheshireftw',
              source: ''
            },
            {
              link: 'https://imgur.com/RqR4lCC.png',
              credit: 'cheshireftw',
              source: ''
            },
          ]
        },
      {
          width: '250',
          height: '350',
          data: [
            {
              link: 'https://imgur.com/ME1asJH.png',
              credit: 'cheshireftw',
              source: ''
            },
            {
              link: 'https://imgur.com/hRuL7Bl.png',
              credit: 'cheshireftw',
              source: ''
            },
             {
              link: 'https://imgur.com/vBfy4tS.png',
              credit: 'cheshireftw',
              source: ''
            },
          ]
        }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-bleu',
      'moderne',
      'ailes',
    ]
  },
  {
    name: 'exusiai',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/WxYp3sS.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-rouge',
      'moderne',
      'ailes',
    ]
  },
  {
    name: 'eyjafjalla',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/xGhgrVx.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-rose',
      'fantastique',
      'cornes',
      'animal',
    ]
  },
  {
    name: 'ferdinand von ægir',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/VPOUuk0.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'courts',
      'c-roux',
      'y-orange',
      'fantastique',
    ]
  },
  {
    name: 'fischl',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/XGoqb4S.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-vert',
      'fantastique',
      'cacheoeil',
    ]
  },
  {
    name: 'flamebringer',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/BhkwBUB.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-rouge',
      'fantastique',
      'cornes',
      'oreilles',
    ]
  },
  {
    name: 'flayn',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/SeRDgYv.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'franziska von karma',
    source: 'ace attorney',
    nickname: '',
    img: 'https://i.imgur.com/Mt8LWUL.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-gris',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'fukase',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/4qaOT1F.png',
    avatars: [
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'courts',
      'c-rouge',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'gilgamesh',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/taXXADP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ytotBBI.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/eSG4vZo.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/axDI0c7.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'godot',
    source: 'ace attorney',
    nickname: '',
    img: 'https://i.imgur.com/YVvwTGP.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'mature',
      'sombre',
      'courts',
      'c-blanc',
      'moderne',
      'cacheoeil',
    ]
  },
  {
    name: 'gowther',
    source: 'nanatsu no taizai',
    nickname: '',
    img: 'https://i59.servimg.com/u/f59/20/16/89/62/gowthe11.png',
    avatars: [
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-jaune',
      'moderne',
      'lunettes',
      'androgyne',
    ]
  },
  {
    name: 'gon freecss',
    source: 'hunter x hunter',
    nickname: '',
    img: 'https://i.imgur.com/BuCF18X.png',
    avatars: [
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'courts',
      'c-noir',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'gou matsuoka',
    source: 'free!',
    nickname: '',
    img: 'https://i.imgur.com/vOLxBe2.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'greed / greeling',
    source: 'fullmetal alchemist',
    nickname: '',
    img: 'https://i.imgur.com/JLeaTn7.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'gyoumei himejima',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/9QamBlY.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-blanc',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'hajime saitou',
    source: 'hakuouki',
    nickname: '',
    img: 'https://i.imgur.com/7OFMb9U.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'hajime shino',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/rOh59on.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'moyens',
      'c-bleu',
      'y-bleu',
      'moderne',
      'androgyne',
    ]
  },
  {
    name: 'hanji zoe',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/17dcStf.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'fantastique',
      'moderne',
      'lunettes',
      'androgyne',
    ]
  },
  {
    name: 'hanneman von essar',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/kOt7Ge8.png',
    avatars: [
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-gris',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'haru okumura',
    source: 'persona 5',
    nickname: '',
    img: 'https://i.imgur.com/mnFH7fS.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'harugo misora',
    source: 'im: great priest imhotep',
    nickname: '',
    img: 'https://i90.servimg.com/u/f90/19/40/94/67/bloggi31.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'haruka nanase',
    source: 'free!!',
    nickname: '',
    img: 'https://i.imgur.com/juuuyEn.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'haruki nakayama',
    source: 'given',
    nickname: '',
    img: 'https://i.imgur.com/ljAhMpS.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'hazel kim',
    source: 'crew of cuties',
    nickname: '',
    img: 'https://i.imgur.com/myut2zI.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-marron',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'heisuke toudou',
    source: 'hakuouki',
    nickname: '',
    img: 'https://i.imgur.com/zIexYC0.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'courts',
      'c-brun',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'hellagur',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/hDAZPYE.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'himeru',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/iLr2YyB.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'hinata aoi',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/yN6rcgw.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-roux',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'hiyama kiyoteru',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/phlfS8a.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-rose',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'hoshiguma',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/WDQhfDt.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/FODRpEv.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://zupimages.net/up/21/17/vjaq.png',
            credit: 'sixtine',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PgCYOdC.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HoawgnN.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/B1iJAJa.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/XONUzn8.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/dC8jrGz.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Bpkalkt.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sKHYNff.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/55PPpTQ.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vGKqCGO.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'c-bleu',
      'y-jaune',
      'moderne',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'hubert von vestra',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/4UsiISj.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SjrvnqB.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/WIUhrGn.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/TgDPer4.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9g1giow.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8gkvZfK.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/23l1uvD.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'ia',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/KHl9chS.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'imhotep',
    source: 'im: great priest imhotep',
    nickname: '',
    img: 'https://i90.servimg.com/u/f90/19/40/94/67/bloggi32.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-noir',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'ichigo kurosaki',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/JmUIbqH.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-roux',
      'y-marron',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'ichika hoshino',
    source: 'collar x malice',
    nickname: '',
    img: 'https://i.imgur.com/BX32c1r.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'ifrit',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/rNpgdZs.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-orange',
      'moderne',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'ignatz victor',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/nvRXn0f.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'courts',
      'c-vert',
      'y-vert',
      'fantastique',
      'lunettes',
    ]
  },
  {
    name: 'ikki',
    source: 'amnesia',
    nickname: '',
    img: 'https://i.imgur.com/DRYhBDX.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'illumi zoldyck',
    source: 'hunter x hunter',
    nickname: '',
    img: 'https://i.imgur.com/4QsZvsh.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'fantastique',
      'androgyne',
    ]
  },
  {
    name: 'illyasviel von einzbern',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/RDUy3a3.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'imai nobume',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/zvzNCEc.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'longs',
      'c-bleu',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'ingrid galatea',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/c0ItaTG.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/VYJs7E2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IWTP28V.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NF5jWBW.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/1XcOgcv.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xjFBuAR.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/W5njm03.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'courts',
      'c-blond',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'inosuke hashirana',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/hJfcxbx.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/uh6bclJ.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/mLZmWrj.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/dRL6TrV.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/S1lhnot.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'ichirou yamada',
    source: 'hypnosis mic',
    nickname: '',
    img: 'https://i.imgur.com/KhZYuY3.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/HlSGScn.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/R92NY9v.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/ALJAl2l.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/1pNhHJD.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-vert',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'beatrice',
    source: 'umineko no naku koro ni',
    nickname: '',
    img: 'https://i.imgur.com/WiWlskF.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/QxuT6sU.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/21cBhgy.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/lp1l6GU.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/V5J4OBk.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'isaac foster',
    source: 'datsuriku no tenshi',
    nickname: '',
    img: 'https://i.imgur.com/UHnX2Al.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'coloree',
      'courts',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'izumi sena',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/9JF1lCF.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-gris',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'jason todd',
    source: 'batman',
    nickname: '',
    img: 'https://i.imgur.com/EaMTmM9.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'mature',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'jaune arc',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/1AEV2u0.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'jibril',
    source: 'no game no life',
    nickname: '',
    img: 'https://i.imgur.com/Q0zdO8F.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-rose',
      'y-jaune',
      'fantastique',
      'ailes',
      'animal',
    ]
  },
  {
    name: 'jean kirstein',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/dot1ief.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-marron',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'jeritza von hrym',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/wZXMEad.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-gris',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'jian yi',
    source: '19 days',
    nickname: '',
    img: 'https://i.imgur.com/lRwKer7.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'justine',
    source: 'persona 5',
    nickname: '',
    img: 'https://i.imgur.com/DbeejLh.png',
    avatars: [
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'moderne',
      'cacheoeil',
    ]
  },
  {
    name: 'kaburagi t. kotetsu',
    source: 'tiger & bunny',
    nickname: '',
    img: 'https://i.imgur.com/XyAyRvu.jpg',
    avatars: [
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'kaeya',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/Kzo3pJ2.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/1omnPKY.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/hkyXNhG.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/87T1qh4.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'kat',
    source: 'gravity rush',
    nickname: '',
    img: 'https://i.imgur.com/a0ptPLr.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-blond',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'kaito',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/tHFOrsw.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'kallen stadtfeld',
    source: 'code geass',
    nickname: '',
    img: 'https://i.imgur.com/kBbnCg7.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-vert',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'kamui',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/GShU8UR.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'kamui gakupo',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/4buo8CM.png',
    avatars: [
        {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Gv1arnQ.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'kanade tachibana',
    source: 'angel beats!',
    nickname: '',
    img: 'https://i.imgur.com/hoxXQlV.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'moderne',
      'ailes',
    ]
  },
  {
    name: 'kanae kochou',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/0fj7mHC.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'kanao tsuyuri',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/CW6wKZc.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'kanata shinkai',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/SaMd8cy.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'kanba takakura',
    source: 'mawaru penguindrum',
    nickname: '',
    img: 'https://i.imgur.com/kAMyRYs.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'kaoru hakaze',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/OHFIexX.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'kasuka heiwajima',
    source: 'durarara!',
    nickname: '',
    img: 'https://i.imgur.com/26o5DXo.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'katara',
    source: 'avatar: the last airbender',
    nickname: '',
    img: 'https://i.imgur.com/46Gd0Pk.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-brun',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'katsura kotaro',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/V5KNlDB.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'kei okazaki',
    source: 'collar x malice',
    nickname: '',
    img: 'https://i.imgur.com/0IGs3yH.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-vert',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'kent',
    source: 'amnesia',
    nickname: '',
    img: 'https://i.imgur.com/DKTXu4i.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'courts',
      'c-brun',
      'y-vert',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'keqing',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/eHyIZeJ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/OOdUEfs.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'y-rose',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'kida masaomi',
    source: 'durarara!',
    nickname: '',
    img: 'https://i.imgur.com/Z38r6mr.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'hijikata toshiro',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/6CqviPW.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'anthy himemiya',
    source: 'revolutionary girl utena',
    nickname: '',
    img: 'https://i.imgur.com/5OWmtMq.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-violet',
      'y-vert',
      'moderne',
      'fantastique',
      'lunettes',
    ]
  },
  {
    name: 'domino swift',
    source: 'motor crush',
    nickname: '',
    img: 'https://i.imgur.com/lPXw7Ln.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'lola del carmen',
    source: 'motor crush',
    nickname: '',
    img: 'https://i.imgur.com/Y9gfK1k.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-rose',
      'moderne',
    ]
  },
  {
    name: 'lottie person',
    source: 'snotgirl',
    nickname: 'snotgirl',
    img: 'https://i.imgur.com/1exTtVz.png',
    avatars: [
        {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Yw9nCmS.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
      width: '250',
      height: '350',
      data: [
        {
          link: 'https://i.imgur.com/1SdAxgq.png',
          credit: 'cheshireftw',
          source: '',
          source_url: '',
        },
      ]
    },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'caroline',
    source: 'snotgirl',
    nickname: '',
    img: 'https://i.imgur.com/a4HQ1fW.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'esther dumont',
    source: 'snotgirl',
    nickname: '',
    img: 'https://i.imgur.com/Q3qhZ5b.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'courts',
      'c-blond',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'misty sutton',
    source: 'snotgirl',
    nickname: '',
    img: 'https://i.imgur.com/VxSLOXJ.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'rosie person',
    source: 'snotgirl',
    nickname: '',
    img: 'https://i.imgur.com/z76QZor.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'virgil',
    source: 'snotgirl',
    nickname: '',
    img: 'https://i.imgur.com/eMO28jp.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'king bradley',
    source: 'fullmetal alchemist',
    nickname: '',
    img: 'https://i.imgur.com/KKOJBvj.png',
    avatars: [
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'y-rouge',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'kisuke urahara',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/RX1ljkQ.png',
    avatars: [
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'moyens',
      'c-blond',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'kohaku oukawa',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/RnbTJDU.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/QR0mWRL.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/tQCCe5b.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'kondo isao',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/0khID2r.png',
    avatars: [
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'kou mabuchi',
    source: 'ao haru ride',
    nickname: '',
    img: 'https://i.imgur.com/mWfSmaZ.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/UPcyHH5.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'kou minamoto',
    source: 'jibaku shounen hanako-kun',
    nickname: '',
    img: 'https://i.imgur.com/MPfntLB.png',
    avatars: [
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'kratos aurion',
    source: 'tales of symphonia',
    nickname: '',
    img: 'https://i.imgur.com/3vYYjrC.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-marron',
      'fantastique',
      'ailes',
    ]
  },
  {
    name: 'kronya',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/TyIioUV.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'moyens',
      'c-roux',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'kuro kiryuu',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/UbWtTpq.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'kurisu makise',
    source: 'steins gate',
    nickname: '',
    img: 'https://i.imgur.com/Oakvhzf.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/rKtCWQT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'c-brun',
      'y-bleu',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'kyo soma',
    source: 'fruits basket',
    nickname: '',
    img: 'https://i59.servimg.com/u/f59/20/16/89/62/kyo210.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/uTko0De.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-roux',
      'y-orange',
      'moderne',
    ]
  },
  {
    name: 'kyoujurou rengoku',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/MrMqLWd.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/eikakMw.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/24rpRLu.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'c-rouge',
      'y-orange',
      'fantastique',
    ]
  },
  {
    name: 'lancelot',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/by4VybX.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/bsBxQa7.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/AUVG6BF.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Vxjw5Nj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ix7IlQh.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Bqv7tWL.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GARQobz.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/y64xOpA.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/wnFmhoy.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-bleu',
      'fantastique',
      'lunettes',
    ]
  },
  {
    name: 'scáthach',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/nHgLCru.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/BWUsSVH.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'cú chulainn',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/rYtL9ny.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'lusamine',
    source: 'pokemon',
    nickname: '',
    img: 'https://i.imgur.com/4BP24jd.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'lappland',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/TGnCF7q.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/mYMYH06.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/Y9YJyDt.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/wMqjima.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/ioSSdnc.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/xVSbvyT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/v398Vr1.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'c-gris',
      'y-gris',
      'moderne',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'lavenza',
    source: 'persona 5',
    nickname: '',
    img: 'https://i.imgur.com/R37BgSM.png',
    avatars: [
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'law',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/ENDarkQ.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'len kagamine',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/6aA8hxT.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/dxVmq8L.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'lenalee lee',
    source: 'd. gray man',
    nickname: '',
    img: 'https://i.imgur.com/pnVg7Sa.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'courts',
      'c-noir',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'leo tsukinaga',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/taAhd3V.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'leonie pinelli',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/CZhVEMU.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/XDxI8Ir.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'courts',
      'c-roux',
      'y-orange',
      'fantastique',
    ]
  },
  {
    name: 'lie ren',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/siVfE7L.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'lilina',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/cfiZ4cM.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'lily',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/DKalUuH.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'linhardt von hevring',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/qQ0dya1.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/qYglXZ7.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/4d8TyuE.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-bleu',
      'fantastique',
    ]
  },
   {
    name: 'owen',
    source: 'house of wizard',
    nickname: '',
    img: 'https://i.imgur.com/QaoJaWu.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/MAboJod.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/jLql12q.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-gris',
      'y-jaune',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'lorentz gloucester',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/8K00xGl.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'courts',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'lisa',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/lwbmfgR.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'lloyd irving',
    source: 'tales of symphonia',
    nickname: '',
    img: 'https://i.imgur.com/vwWUa76.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'lucina',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/vgR8gNo.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'luffy',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/vdgJVMG.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'lumine',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/YPaeMnK.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'lunafreya nox fleuret',
    source: 'final fantasy xv',
    nickname: '',
    img: 'https://i.imgur.com/tZIGXfA.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'adra nos',
    source: 'final fantasy xiv',
    nickname: '',
    img: 'https://i.imgur.com/TzEBsO4.png',
    avatars: [      
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/9ioTSfP.jpg',
            credit: 'star',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'luo tianyi',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/15Fhwlp.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-gris',
      'y-orange',
      'fantastique',
    ]
  },
  {
    name: 'madara mikejima',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/buEsytk.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'reimu hakurei',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/t88b3lj.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://www.zupimages.net/up/21/17/nump.png',
            credit: 'sixtine',
            source: 'mochitoseaguri',
            source_url: 'https://twitter.com/mochitoseaguri',
          },
          {
            link: 'https://www.zupimages.net/up/21/16/l9nh.png',
            credit: 'sixtine',
            source: 'suika',
            source_url: 'https://twitter.com/floresuika',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'marisa kirisame',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/XrLv7Kl.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'mc cree',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/KPptY0x.jpg',
    avatars: [
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'baptiste',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/ZCLTJsD.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-noir',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'doomfist',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/BSwHGhy.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'chauve',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'lauren sinclair',
    source: 'la jacinthe violette',
    nickname: '',
    img: 'https://i.imgur.com/uMbsICp.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ggUgwMn.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'subaru sumeragi',
    source: 'tokyo babylon',
    nickname: '',
    img: 'https://i.imgur.com/XFz26CP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Pvtw4kT.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'kamui shiro',
    source: 'x 1999',
    nickname: '',
    img: 'https://i.imgur.com/DxmiOyC.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/PBDnRlX.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'magallan',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/gKGUg4T.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'c-blanc',
      'y-jaune',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'reaper',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/miGw3oH.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'mature',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'sigma',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/1fjnjYY.png',
    avatars: [
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-gris',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'entrapta',
    source: 'she-ra and the princesses of power',
    nickname: '',
    img: 'https://i.imgur.com/ISSb9QN.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-violet',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'flora',
    source: 'she-ra and the princesses of power',
    nickname: '',
    img: 'https://i.imgur.com/PznNCOg.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-blond',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'glimmer',
    source: 'she-ra and the princesses of power',
    nickname: '',
    img: 'https://i.imgur.com/MbYmK1z.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'mermista',
    source: 'she-ra and the princesses of power',
    nickname: '',
    img: 'https://i.imgur.com/BdAt0nG.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-bleu',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'scorpia',
    source: 'she-ra and the princesses of power',
    nickname: '',
    img: 'https://i.imgur.com/MmBCwjU.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'koushi sugawara',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i.imgur.com/xFmmp4T.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xLB1tnD.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-gris',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'xerxes break',
    source: 'pandora hearts',
    nickname: '',
    img: 'https://i90.servimg.com/u/f90/19/40/94/67/bloggi63.jpg',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/xerxes10.jpg',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'zero',
    source: 'drakengard 3',
    nickname: '',
    img: 'https://i.imgur.com/ljsyRnn.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/isiXxBs.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xCAyIwF.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/f82fJX9.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/MQOLD0w.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/LAaNox9.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9glVHiD.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HvPiy2A.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/FwLQ9c0.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/dFUXZwQ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/OIqeK3g.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rose',
      'fantastique',
      'cacheoeil',
    ]
  },
  {
    name: 'van fanel',
    source: 'vision of escaflown',
    nickname: '',
    img: 'https://i.imgur.com/9Z7KqnR.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Dl30ERb.gif',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'akutagawa ryunosuke',
    source: 'bungou stray dogs',
    nickname: '',
    img: 'https://i90.servimg.com/u/f90/19/40/94/67/bloggi64.jpg',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/akutag10.jpg',
            credit: 'ailou',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'c-blanc',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'bow',
    source: 'she-ra and the princesses of power',
    nickname: '',
    img: 'https://i.imgur.com/uuL2S7H.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'morgiana',
    source: 'magi - the labyrinth of magic',
    nickname: '',
    img: 'https://i59.servimg.com/u/f59/20/16/89/62/bloggi48.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SyWMHea.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5bmhfY0.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/V4egCei.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/mhjWzd4.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Kyi6ZBM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'mafuyu sato',
    source: 'given',
    nickname: '',
    img: 'https://i.imgur.com/hrFJ8Bs.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-orange',
      'moderne',
    ]
  },
  {
    name: 'magellan',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/gKGUg4T.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'c-blanc',
      'y-jaune',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'makomo',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/NNdGkCH.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'makoto tachibana',
    source: 'free!',
    nickname: '',
    img: 'https://i.imgur.com/ew7KH1F.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'mana walker',
    source: 'd. gray man',
    nickname: '',
    img: 'https://i.imgur.com/WXgsJn3.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'manuela casagrande',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/ICtimaO.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'mao isara',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/k0WwQme.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'marth',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/kC8kzcq.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'maya fey',
    source: 'ace attorney',
    nickname: '',
    img: 'https://i.imgur.com/j37GXzL.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'mayu',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/L2EWzEn.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'mc',
    source: 'amnesia',
    nickname: '',
    img: 'https://i.imgur.com/P69Cc8S.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'meiko',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/SQYjFWU.png',
    avatars: [
      {
          width: '200',
          height: '320',
          data: [
            {
              link: 'https://imgur.com/ZsQjOJD.png',
              credit: 'caldinia',
              source: ''
            },
          ]
        }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-rouge',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'meltlillith',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/dwqtUQZ.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'merli',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/2xlVoK2.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-violet',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'michiru kagemori',
    source: 'brand new animal',
    nickname: '',
    img: 'https://i.imgur.com/h6YchmG.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'moyens',
      'c-bleu',
      'y-bleu',
      'y-vert',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'midori takamine',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/cUegDSE.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'mika kagehira',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/d32lqNi.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'mikado ryuugamine',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/6cWOznB.png',
    avatars: [
      {
          width: '200',
          height: '320',
          data: [
            {
              link: 'https://imgur.com/5X1ZYBx.png',
              credit: 'caldinia',
              source: ''
            },
          ]
        }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'mikasa ackerman',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/rfYc5uZ.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'miki',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/8KHZVxI.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'c-roux',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'miles edgeworth',
    source: 'ace attorney',
    nickname: '',
    img: 'https://i.imgur.com/rO4YfwW.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-gris',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'miles morales',
    source: 'spider-man',
    nickname: '',
    img: 'https://i.imgur.com/mbIJMFr.png',
    avatars: [
    ],
    tags: [
      'homme',
      'enfant',
      'sombre',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'minato namikaze',
    source: 'naruto',
    nickname: '',
    img: 'https://i.imgur.com/52kuRcV.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'mineo enomoto',
    source: 'collar x malices',
    nickname: '',
    img: 'https://i.imgur.com/ptZLb6K.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-jaune',
      'moderne',
      'cacheoeil',
    ]
  },
  {
    name: 'mitsuru tenma',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/G9gpCnU.png',
    avatars: [
    ],
    tags: [
      'homme',
      'enfant',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'mona',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/sFC5AQz.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'mo guan shan',
    source: '19 days',
    nickname: '',
    img: 'https://i.imgur.com/UU2yoCe.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-rouge',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'mo qingxian',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/KsVBwGB.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'mostima',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/tcLY7u5.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'fantastique',
      'cornes',
      'ailes',
    ]
  },
  {
    name: 'muichirou tokito',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/2kYnV84.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'muzan kibutsuji (female)',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/IQG4gSP.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'muzan kibutsuji (male)',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/dpkMjna.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'nagisa hazuki',
    source: 'free!',
    nickname: '',
    img: 'https://i.imgur.com/yUUaYlZ.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-rose',
      'moderne',
    ]
  },
  {
    name: 'nai',
    source: 'karneval',
    nickname: '',
    img: 'https://i.imgur.com/Ccwf22q.png',
    avatars: [
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'courts',
      'c-blanc',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'nami',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/3vwREzq.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/IRLtINN.png',
            credit: 'aekko',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Gm2qbX8.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-roux',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
   {
    name: 'tohru honda',
    source: 'fruits basket',
    nickname: '',
    img: 'https://i.imgur.com/u8Qc6Jz.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/86iaSk4.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/XrczliB.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'namie yagiri',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/8l1o8OI.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'nazuna nito',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/U2ISoci.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-blond',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'neeko',
    source: 'league of legend',
    nickname: '',
    img: 'https://i.imgur.com/NjZxiyB.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'coloree',
      'moyens',
      'c-violet',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'nessa',
    source: 'pokemon',
    nickname: '',
    img: 'https://i.imgur.com/nVDgbZC.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Z4xxOSQ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HQeEr4x.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/JvgG41Q.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/korbO1v.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6JI1CXo.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/oSM111O.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5zsuwBH.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/AWIlIhf.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-brun',
      'c-bleu',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'nezumi',
    source: 'n°6',
    nickname: '',
    img: 'https://i.imgur.com/KIL5pd9.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'nill',
    source: 'dogs: bullets & carnage',
    nickname: '',
    img: 'https://i.imgur.com/mNhvH1y.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
      'ailes',
    ]
  },
  {
    name: 'ningguang',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/Q8Cf9wu.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/xXEUJwj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/9a65wb1.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/DdxtaNq.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/25W0KBa.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'nora valkyrie',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/cPjYRfU.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-roux',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'nunnally lamperouge',
    source: 'code geass',
    nickname: '',
    img: 'https://i.imgur.com/2cR8wuU.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'nyx',
    source: 'hades',
    nickname: '',
    img: 'https://i.imgur.com/CHCSdDy.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/GmOFKIi.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ykhELYT.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fFJRaWV.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'mature',
      'clair',
      'longs',
      'c-noir',
      'c-violet',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'obanai iguro',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/6VZ8F5E.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-bleu',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'okita sougo',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/uB7GEma.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'olivia',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/FiTrv5i.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SbMKXpi.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5qxQRjs.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/crTVnQS.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/4vAg8Et.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/l4ftWmQ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ST2lRlL.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'olivia armstrong',
    source: 'fullmetal alchemist',
    nickname: '',
    img: 'https://i.imgur.com/Woz4Krb.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'ozymandias',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/hH5NPJM.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/9eXoidB.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ffyIQG8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0lhqk2J.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/l8p5xnW.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/w7ghkj7.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/WEp4Xxj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/EPgl5tn.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6xUIGG3.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sEqEsY1.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/KmLjgl5.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-brun',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'padparadscha',
    source: 'houseki no kuni',
    nickname: '',
    img: 'https://i.imgur.com/JJTwygU.png',
    avatars: [
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'paya',
    source: 'the legend of zelda',
    nickname: '',
    img: 'https://i.imgur.com/OMK6wud.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-marron',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'pearl fey',
    source: 'ace attorney',
    nickname: '',
    img: 'https://i.imgur.com/Ybr9SJl.png',
    avatars: [
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'moyens',
      'c-brun',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'penny polendina',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/2sPfgLd.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'perona',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/h8pCAPk.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'petra ral',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/W2QMskQ.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-roux',
      'y-marron',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'phoenix wright',
    source: 'ace attorney',
    nickname: '',
    img: 'https://i.imgur.com/8pcV5Mu.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'platinum',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/7CAghI5.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'pramanix',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/QKNP1EC.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'c-gris',
      'y-gris',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'ptilosis',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/I1yJq82.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'c-gris',
      'y-orange',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'qrow branwen',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/PFRL18L.png',
    avatars: [
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'asra',
    source: 'the arcana',
    nickname: '',
    img: 'https://i90.servimg.com/u/f90/19/40/94/67/bloggi46.jpg',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'ryuuko matoi',
    source: 'kill la kill',
    nickname: '',
    img: 'https://i.imgur.com/meJex7q.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'c-rouge',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'sucrose',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/v6v6s8R.jpg',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/VGTg0oa.png',
            credit: 'azria',
            source: 'diana marmol',
            source_url: 'https://twitter.com/dianammarmol/status/1381697855992266761/photo/1',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/MdJvqeM.png',
            credit: 'azria',
            source: 'diana marmol',
            source_url: 'https://twitter.com/dianammarmol/status/1381697855992266761/photo/1',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-jaune',
      'fantastique',
      'animal',
      'lunettes',
    ]
  },
  {
    name: 'durandal',
    source: 'honkai impact 3rd',
    nickname: '',
    img: 'https://i.imgur.com/SXC8UIW.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'mei raiden',
    source: 'honkai impact 3rd',
    nickname: '',
    img: 'https://i.imgur.com/UTKOP3u.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
           {
            link: 'https://i.imgur.com/2SE7esM.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vhNGwBj.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/K4ynO47.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/I7qjCtB.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'y-jaune',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'semiramis',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/mT5zm5T.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
           {
            link: 'https://i.imgur.com/WKnh3Wd.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/bfu8O5e.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/icw6Vbo.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VNSQ3fk.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2xEOu0j.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/YLPy2Zi.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GyBXUDP.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/aJeS81B.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/J4kCA1M.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/BDre8N6.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-jaune',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'edea lee',
    source: 'bravely default',
    nickname: '',
    img: 'https://i.imgur.com/8Mn7o2l.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'agnes oblige',
    source: 'bravely default',
    nickname: '',
    img: 'https://i.imgur.com/dG0nsX2.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'magnola arch',
    source: 'bravely default',
    nickname: '',
    img: 'https://i.imgur.com/PVXRTzJ.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'junko enoshima',
    source: 'dangan ronpa',
    nickname: '',
    img: 'https://i.imgur.com/3lb2Qr3.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'tooru',
    source: 'miss kobayashi\'s dragon maid',
    nickname: '',
    img: 'https://i.imgur.com/UA0it0p.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-rouge',
      'moderne',
      'cornes', 
      'animal',
    ]
  },
  {
    name: 'coalt',
    source: 'miss kobayashi\'s dragon maid',
    nickname: '',
    img: 'https://i.imgur.com/3peFkvq.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'c-bleu',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'elsa of arendelle',
    source: 'frozen',
    nickname: '',
    img: 'https://i.imgur.com/slLMl1K.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/7oIciqh.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/yKgziLj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6FwP1UJ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'kagerou imaizumi',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/IXoWirG.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'roland fortis',
    source: 'vanitas no carte',
    nickname: '',
    img: 'https://i90.servimg.com/u/f90/19/40/94/67/bloggi36.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'astolfo granatum',
    source: 'vanitas no carte',
    nickname: '',
    img: 'https://i90.servimg.com/u/f90/19/40/94/67/bloggi40.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'moyens',
      'c-rose',
      'y-violet',
      'fantastique',
      'androgyne',
    ]
  },
  {
    name: 'noe archiviste',
    source: 'vanitas no carte',
    nickname: '',
    img: 'https://i90.servimg.com/u/f90/19/40/94/67/bloggi35.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'moyens',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'vanitas',
    source: 'vanitas no carte',
    nickname: '',
    img: 'https://i90.servimg.com/u/f90/19/40/94/67/bloggi45.jpg',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'rachel gardner',
    source: 'satsuriku no tenshi',
    nickname: '',
    img: 'https://i.imgur.com/Mlodpv1.png',
    avatars: [
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'rangiku matsumoto',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/nKBEiU3.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'raphael kirsten',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/VC6f9W4.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'reagan bishop',
    source: 'crew of cuties',
    nickname: '',
    img: 'https://i.imgur.com/C1jy2dv.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'rei ryuugazaki',
    source: 'free!',
    nickname: '',
    img: 'https://i.imgur.com/rcgfTYp.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-violet',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'rei sakuma',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/ay9DTvp.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'reiner braun',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/sDJAsSN.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'rhea',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/Bv41bh7.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://sd-6.archive-host.com/membres/up/fb421ef8c974ec7441e9b542aa5f10b206b1c639/PRD/Rheaprd1.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'beatrice',
    source: 're:zero',
    nickname: '',
    img: 'https://i.imgur.com/ZlpxtNp.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/L2yeYqP.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'medusa',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/4BroGa4.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-rose',
      'fantastique',
      'cacheoeil',
    ]
  },
  {
    name: 'ringo oginome',
    source: 'mawaru penguindrum',
    nickname: '',
    img: 'https://i.imgur.com/WGB2UOk.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'ritsu sakuma',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/e2ENi1X.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/WL1UUi0.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/BnC9cQZ.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XAvMeck.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/UIFGFFG.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9t7IwFD.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/PgCtWsz.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/wAM9pmr.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8PuO7Gr.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'ayase mayoi',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/YXVY5Wv.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/GMuPY4W.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Nf7ggxF.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },          
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-vert',
      'y-bleu',
      'moderne',
      'androgyne',
    ]
  },
  {
    name: 'ritsuka fujimaru',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/Z7jibRD.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'riza hawkeye',
    source: 'fullmetal alchemist',
    nickname: '',
    img: 'https://i.imgur.com/RqUNKEU.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'road kamelot',
    source: 'd. gray man',
    nickname: '',
    img: 'https://i.imgur.com/i1v7aLl.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'courts',
      'c-noir',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'robin',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/FmZMX9s.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'robin (female)',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/GfittgI.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'robin (male)',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/3bHWn60.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'rosa',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/0OFB3W5.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'y-bleu',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'roy mustang',
    source: 'fullmetal alchemist',
    nickname: '',
    img: 'https://i.imgur.com/t6YBpLg.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'ruby rose',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/ZmAHrjm.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'rukia kuchiki',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/iVElyDM.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-violet',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'ruri hirijibe',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/ENQoPHS.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'rutile',
    source: 'houseki no kuni',
    nickname: '',
    img: 'https://i.imgur.com/aUlSG91.png',
    avatars: [
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'ryuuji sakamoto',
    source: 'persona 5',
    nickname: '',
    img: 'https://i.imgur.com/7Jg4JMB.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'attila',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/8BBDSi9.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'moyens',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'seele vollerei',
    source: 'honkai impact 3rd',
    nickname: '',
    img: 'https://i.imgur.com/s1MmnqH.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'satori tendou',
    source: 'haikyuu!!',
    nickname: '',
    img: 'https://i59.servimg.com/u/f59/20/16/89/62/bloggi44.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'sakura yae',
    source: 'honkai impact 3rd',
    nickname: '',
    img: 'https://i.imgur.com/K7b0ThS.jpg',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/8A5ZX0s.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HlZl9px.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-rose',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'sabito',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/alJy75v.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'sakura',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/2ykAnQ1.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'sakura matou',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/OL2pKmI.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'sakura nanamine',
    source: 'jibaku shounen hanako-kun',
    nickname: '',
    img: 'https://i.imgur.com/hCEoD71.png',
    avatars: [
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-vert',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'samatoki aohitsugi',
    source: 'hypnosis mic',
    nickname: '',
    img: 'https://i.imgur.com/fKA6rqP.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'sanaki',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/4AbLgk1.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'sanemi shinazugawa',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/Z4zrubj.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'sanetoshi watase',
    source: 'mawaru penguidrum',
    nickname: '',
    img: 'https://i.imgur.com/iBHVLod.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'sanji',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/IuduP1r.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'sanosuke harada',
    source: 'hakuouki',
    nickname: '',
    img: 'https://i.imgur.com/pmc8JEQ.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-orange',
      'fantastique',
    ]
  },
  {
    name: 'saria',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/F2h4GvE.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/grQEO8e.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/l5cOM0e.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-orange',
      'fantastique',
      'longs',
      'cornes',
    ]
  },
  {
    name: 'sasha braus',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/kDcJTBo.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'sasuke uchiha',
    source: 'naruto',
    nickname: '',
    img: 'https://i.imgur.com/avGCnzq.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ksQDZBO.jpg',
            credit: 'turbo mwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'schwartz',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/tD9ubVz.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-jaune',
      'moderne',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'seeu',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/3qZtilu.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'seteth',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/ZlV3hgQ.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-vert',
      'y-vert',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'shamir nevrand',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/GGoPoJB.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'sonia',
    source: 'pokemon',
    nickname: '',
    img: 'https://i.imgur.com/qoqphYC.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'sheeda',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/tj20WSZ.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'mashu kyrielight',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/CIJaFPJ.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'shining',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/iPKTJrP.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-marron',
      'fantastique',
      'moderne',
      'cornes',
      'oreilles',
    ]
  },
  {
    name: 'shinji hirako',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/2qAplEF.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'courts',
      'c-blond',
      'y-marron',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'shinpachi shimura',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/DEjdiJ8.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'fantastique',
      'lunettes',
    ]
  },
  {
    name: 'shinra kiritani',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/HVWi8pj.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'shinsuke takasugi',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/XYcUmSn.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'fantastique',
      'cacheoeil',
    ]
  },
  {
    name: 'shion',
    source: 'n°6',
    nickname: '',
    img: 'https://i.imgur.com/pGNuu9y.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'shirley fenette',
    source: 'code geass',
    nickname: '',
    img: 'https://i.imgur.com/TQdocfh.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'shouma takakura',
    source: 'mawaru penguindrum',
    nickname: '',
    img: 'https://i.imgur.com/2wtmdbj.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'shu itsuki',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/heqKkCb.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'silence',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/gR66sH7.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-orange',
      'y-jaune',
      'fantastique',
      'animal',
      'lunettes',
    ]
  },
  {
    name: 'silverash',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/rovJ7u3.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
            {
            link: 'https://imgur.com/sOobaUI.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
            {
            link: 'https://imgur.com/JxcgT4g.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'c-gris',
      'y-gris',
      'fantastique',
      'moderne',
      'animal',
    ]
  },
   {
    name: 'phantom',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/WgHkKKM.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/NS0MRoJ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
            {
            link: 'https://imgur.com/4iAkGDV.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
            {
            link: 'https://imgur.com/xiOAOAh.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'c-gris',
      'y-gris',
      'fantastique',
      'moderne',
      'animal',
    ]
  },
   {
    name: 'feater',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/rfiESn3.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/3SK8J4Z.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
            {
            link: 'https://imgur.com/TLvvQVA.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
            {
            link: 'https://imgur.com/8VAlaAL.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-gris',
      'y-rouge',
      'fantastique',
      'moderne',
      'animal',
    ]
  },
    {
    name: 'ambriel',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/NOQDlnk.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://imgur.com/ernYSMM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
            {
            link: 'https://imgur.com/tnAlITJ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
            {
            link: 'https://imgur.com/aKX5ep7.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/lwpOJcA.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-rouge',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'skadi',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/NYRI84w.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/K0Lr8Y1.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://imgur.com/8bX02Kv.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://imgur.com/4HjrOXP.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/w9z85pb.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/nOyUlsb.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'siege',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/5N16e9m.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'c-blond',
      'y-marron',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'sora',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/96LtA1P.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-rose',
      'moderne',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'sothis',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/qgBKWOP.png',
    avatars: [
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'longs',
      'c-vert',
      'y-vert',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'souji okita',
    source: 'hakuouki',
    nickname: '',
    img: 'https://i.imgur.com/Cs2bfUi.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'souma kanzaki',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/yNZ9U4W.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'sousuke mitsuba',
    source: 'jibaku shounen hanako-kun',
    nickname: '',
    img: 'https://i.imgur.com/idflJYj.png',
    avatars: [
    ],
    tags: [
      'homme',
      'enfant',
      'clair',
      'moyens',
      'c-rose',
      'y-rose',
      'moderne',
      'androgyne',
    ]
  },
  {
    name: 'subaru akehoshi',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/ZmZqSCK.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/bdia394.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/RbyVT7W.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-roux',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'suki',
    source: 'avatar: the last airbender',
    nickname: '',
    img: 'https://i.imgur.com/9whGujX.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'suzaku kururugi',
    source: 'code geass',
    nickname: '',
    img: 'https://i.imgur.com/BtKApig.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-vert',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'sylvain jose gautier',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/BC3Wr1q.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xWkolL9.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3No40Vt.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/RVG7m0U.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/t5janI2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fSryXn6.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CZseDPL.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
       {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/3VAFRrL.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'c-roux',
      'y-orange',
      'fantastique',
    ]
  },
  {
    name: 'tae shimura',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/oY9rPu4.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'tenshi hinanawi',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/cwbQQ0V.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'tae takemi',
    source: 'persona 5',
    nickname: '',
    img: 'https://i.imgur.com/RKhkAWZ.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'takeru sasazuka',
    source: 'collar x malice',
    nickname: '',
    img: 'https://i.imgur.com/b2gGB6k.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-vert',
      'y-rose',
      'moderne',
    ]
  },
  {
    name: 'takumi',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/Unt0tMY.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/BCjBKbb.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/drUYJ9c.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/PZV757J.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/uAQ6DD2.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'tanjirou kamado',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/88c8sOz.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/lH7ELr2.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'tatsumi kazehaya',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/HzvOIlt.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'c-vert',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'tengen uzui',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/VXpNMvI.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'texas',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/HQEVhTP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/mwwXqix.gif',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/0psPewk.gif',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-jaune',
      'moderne',
      'animal',
    ]
  },
  {
    name: 'tharja',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/G2wNjiA.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/IPEC6z5.png',
            credit: 'chupp',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'tiki',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/jN8saTm.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'longs',
      'c-vert',
      'y-vert',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'toma',
    source: 'amnesia',
    nickname: '',
    img: 'https://i.imgur.com/4V9h8Ij.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'tomoya mashiro',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/BDiYjpE.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'toshiro hitsugaya',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/IJ74eCq.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-blanc',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'toshizo hijikata',
    source: 'hakuouki',
    nickname: '',
    img: 'https://i.imgur.com/7nsyvOK.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'touri himemiya',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/X8koNbv.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'tsukasa suou',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/9oykQsu.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'tsukumo',
    source: 'karneval',
    nickname: '',
    img: 'https://i.imgur.com/3NtVrDi.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-violet',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'tsukuyo',
    source: 'gintama',
    nickname: '',
    img: 'https://i.imgur.com/BjnK8Ov.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'ugutsu murata',
    source: 'given',
    nickname: '',
    img: 'https://i.imgur.com/bVGq82j.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'ukyo',
    source: 'amnesia',
    nickname: '',
    img: 'https://i.imgur.com/c59lC58.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'ulquiorra',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/tvSqO0O.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/6PktyxL.png',
            credit: 'espérance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'coloree',
      'moyens',
      'c-noir',
      'y-vert',
      'fantastique',
      'moderne',
      'cornes',
    ]
  },
  {
    name: 'vaati',
    source: 'the legend of zelda',
    nickname: '',
    img: 'https://i.imgur.com/rapAd1O.png',
    avatars: [
    ],
    tags: [
      'homme',
      'enfant',
      'coloree',
      'longs',
      'c-violet',
      'y-rouge',
      'fantastique',
      'oreilles',
    ]
  },
  {
    name: 'venti',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/suWzaHv.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/N9sAWWf.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DGozPhI.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/LbcUtf5.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'c-bleu',
      'y-vert',
      'fantastique',
      'androgyne',
    ]
  },
  {
    name: 'violet evergarden',
    source: 'violet evergarden',
    nickname: '',
    img: 'https://i.imgur.com/q5HqWLu.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'vinegar doppio',
    source: 'jojo\'s bizarre adventure',
    nickname: '',
    img: 'https://i.imgur.com/PzjICgz.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-marron',
      'moderne',
      'androgyne',
    ]
  },
  {
    name: 'vivi',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/mQFboPX.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'vorona',
    source: 'durarara!!',
    nickname: '',
    img: 'https://i.imgur.com/z43PMMA.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'vy2',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/mMQQVD4.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'w',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/Ps9qtP6.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/EQq3Lzx.png',
            credit: 'diraxy',
            source: '幽木MIX',
            source_url: 'https://twitter.com/MIX7373/status/1379490527826034690?s=20',
          }
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'c-gris',
      'y-orange',
      'moderne',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'wakasagihime',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/Acuku0F.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-bleu',
      'moderne',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'widowmaker',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/GpUJNFn.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'longs',
      'c-violet',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'wang yuanji',
    source: 'dynasty warriors',
    nickname: '',
    img: 'https://i.imgur.com/xZyOVhe.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-orange',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'wataru hibiki',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/YrytzkM.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'winry rockbell',
    source: 'fullmetal alchemist',
    nickname: '',
    img: 'https://i.imgur.com/SRAxrWP.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'worick arcangelo',
    source: 'gangsta.',
    nickname: '',
    img: 'https://i.imgur.com/Wpxb6RR.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-bleu',
      'moderne',
      'cacheoeil',
    ]
  },
  {
    name: 'xiangling',
    source: 'genshin impact',
    nickname: '',
    img: 'https://i.imgur.com/uSscOKU.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'yang xiao long',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/iVycS3m.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/qw9v6g1.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'yukihira soma',
    source: 'food wars',
    nickname: '',
    img: 'https://i59.servimg.com/u/f59/20/16/89/62/bloggi47.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'yuno',
    source: 'black clover',
    nickname: '',
    img: 'https://i.imgur.com/bbso45b.jpg',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-jaune',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'yellow diamond',
    source: 'houseki no kuni',
    nickname: '',
    img: 'https://i.imgur.com/XdWH3cz.png',
    avatars: [
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'ymir',
    source: 'attack on titan',
    nickname: '',
    img: 'https://i.imgur.com/pC9ue7z.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'yogi',
    source: 'karneval',
    nickname: '',
    img: 'https://i.imgur.com/3ejraOY.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'yoruichi',
    source: 'bleach',
    nickname: '',
    img: 'https://i.imgur.com/HN4RsIU.png',
    avatars: [
    ],
    tags: [
      'femme',
      'mature',
      'sombre',
      'longs',
      'c-violet',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'yuezheng longya',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/6TTw7rj.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'c-blanc',
      'y-turquoise',
      'fantastique',
    ]
  },
  {
    name: 'yuu kanda',
    source: 'd. gray man',
    nickname: '',
    img: 'https://i.imgur.com/cmsv1wU.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'fantastique',
    ]
  },
  {
    name: 'yuri leclerc',
    source: 'fire emblem: three houses',
    nickname: '',
    img: 'https://i.imgur.com/UMKvKXF.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'fantastique',
      'androgyne',
    ]
  },
  {
    name: 'yuuta aoi',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/9X75DQZ.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-roux',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'yuzuki yukari',
    source: 'vocaloid',
    nickname: '',
    img: 'https://i.imgur.com/xKya6eq.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'yuzuru fushimi',
    source: 'ensemble stars!',
    nickname: '',
    img: 'https://i.imgur.com/KrWDZFa.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'zas.m21',
    source: 'girls frontline',
    nickname: '',
    img: 'https://i.imgur.com/JG48qcb.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/qQAzt3D.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VjDmH7M.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/lbGAyIu.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/7LwNoHm.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ygo8rsE.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ux2AQ4T.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-bleu',
      'y-orange',
      'moderne',
    ]
  },
  {
    name: 'ak-12',
    source: 'girls frontline',
    nickname: '',
    img: 'https://i.imgur.com/CGPvljq.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/lFrPp7r.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },          
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/gAYDh7P.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },          
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-gris',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'lee-enfield',
    source: 'girls frontline',
    nickname: '',
    img: 'https://i.imgur.com/bw3JghP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Jg9FKAf.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },          
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Wqw9Sm7.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },          
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-vert',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'zelos wilder',
    source: 'tales of symphonia',
    nickname: '',
    img: 'https://i.imgur.com/kQbNiS8.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-bleu',
      'fantastique',
      'ailes',
    ]
  },
  {
    name: 'zenitsu agatsuma',
    source: 'demon slayer',
    nickname: '',
    img: 'https://i.imgur.com/WZUCD21.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/tKwzL6a.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/0RhUMQX.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'zhan zheng xi',
    source: '19 days',
    nickname: '',
    img: 'https://i.imgur.com/MzlgnZH.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/zwSOCaQ.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/khPZN06.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7R0N9Xe.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-gris',
      'moderne',
    ]
  },
  {
    name: 'zhong hui',
    source: 'dynasty warriors',
    nickname: '',
    img: 'https://i.imgur.com/TLVcfjt.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
  {
    name: 'zima',
    source: 'arknights',
    nickname: '',
    img: 'https://i.imgur.com/I2Jeo0z.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'c-rouge',
      'y-bleu',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'zoro roronoa',
    source: 'one piece',
    nickname: '',
    img: 'https://i.imgur.com/vVMmAVd.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/DZNLzoU.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          }
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/bJgoBXK.png',
            credit: 'palourde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-vert',
      'y-noir',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'fyodor dostoevsky',
    source: 'bungou stray dogs',
    nickname: '',
    img: 'https://i.imgur.com/SW09ufc.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i59.servimg.com/u/f59/20/16/89/62/fyodor10.jpg',
            credit: 'ailou',
            source: '',
            source_url: '',
          }
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'susie',
    source: 'dead by daylight',
    nickname: '',
    img: 'https://i.imgur.com/44SVhmr.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/pozh63c.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/4uGNznB.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QoHtXg4.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2ZPVETV.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/UCPh1KP.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'moderne',
    ]
  },
  {
    name: 'yun-jin lee',
    source: 'dead by daylight',
    nickname: '',
    img: 'https://i.imgur.com/oYUwWn2.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Lbnh2kq.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          }
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'reze',
    source: 'chainsaw man',
    nickname: '',
    img: 'https://i.imgur.com/cyfzclW.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/nCrTAi9.png',
            credit: 'felix geyer',
            source: 'liz',
            source_url: 'https://www.pixiv.net/users/12676463',
          },
          {
            link: 'https://i.imgur.com/JntxC3U.png',
            credit: 'felix geyer',
            source: 'eba',
            source_url: 'https://www.pixiv.net/en/users/26729452',
          }
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'makima',
    source: 'chainsaw man',
    nickname: '',
    img: 'https://i.imgur.com/Went1Ub.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/n4CvLk0.png',
            credit: 'diraxy',
            source: 'ASK',
            source_url: 'https://twitter.com/askziye/status/1289156817537228801?s=20',
          },
          {
            link: 'https://i.imgur.com/fgutNZx.png',
            credit: 'azria',
            source: '@makimasansuki',
            source_url: 'https://twitter.com/makimasansuki/status/1382358454925332480/photo/1',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/IE4rAu8.png',
            credit: 'azria',
            source: '@makimasansuki',
            source_url: 'https://twitter.com/makimasansuki/status/1382358454925332480/photo/1',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/M3fGRyn.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'c-rouge',
      'c-roux',
      'c-brun',
      'y-orange',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'noi',
    source: 'dorohedoro',
    nickname: '',
    img: 'https://i.imgur.com/T6gLN2Z.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/KXK3V7Q.png',
            credit: 'azria',
            source: 'naruysae',
            source_url: 'https://twitter.com/naruysae',
          }
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/RYhhM8Q.png',
            credit: 'azria',
            source: 'naruysae',
            source_url: 'https://twitter.com/naruysae',
          }
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'c-gris',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'europa',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/AWg1355.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/8RZw7c7.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/D6iFrYh.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IbcnxQt.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/akESPXT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/TKqrGaM.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'seox',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/jn5ApJ9.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/PAdIfjM.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '200',
        data: [
          {
            link: 'https://i.imgur.com/W7Jr3wk.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-gris',
      'y-bleu',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'romeo',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/cdUIc6c.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/lObnpbq.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/HCBRwlb.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
   {
    name: 'feather',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/cvbzByw.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/HMiI6A4.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/1tq1WAp.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'jotaro kujo',
    source: 'jojo\'s bizarre adventure',
    nickname: '',
    img: 'https://i.imgur.com/RNYpvF9.png',
    avatars: [
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i59.servimg.com/u/f59/20/16/89/62/jotaro11.jpg',
            credit: 'ailou',
            source: '',
            source_url: '',
          }
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'joseph joestar',
    source: 'jojo\'s bizarre adventure',
    nickname: '',
    img: 'https://i.imgur.com/wFJj664.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i90.servimg.com/u/f90/19/40/94/67/joseph11.jpg',
            credit: 'ailou',
            source: '',
            source_url: '',
          }
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'ookurikara',
    source: 'touken ranbu',
    nickname: '',
    img: 'https://i.imgur.com/ddtGwe3.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/C36Pmkg.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/49RzTle.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ByzEVdb.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/X0rNMgn.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PH7LW6v.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sH8STor.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/TOpqIkl.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/f6QznMZ.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/39qSIpM.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/uwpcZRb.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          }
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-brun',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'gally',
    source: 'gunnm',
    nickname: '',
    img: 'https://i.imgur.com/CFlK0kO.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/JqrylUZ.png',
            credit: 'diraxy',
            source: 'vinne',
            source_url: 'https://twitter.com/vinneart/status/1235326700705263622?s=20',
          }
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/pqnwYjE.png',
            credit: 'diraxy',
            source: 'vinne',
            source_url: 'https://twitter.com/vinneart/status/1235326700705263622?s=20',
          }
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'ciri riannon',
    source: 'the witcher',
    nickname: 'cirilla fiona elen riannon',
    img: 'https://i.imgur.com/ocYDDfE.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/sBcOamM.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/z9ceaVf.jpg',
            credit: 'stella',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VOLpKxG.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/catdlOt.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9gtgZdk.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'yui kimura',
    source: 'dead by daylight',
    nickname: '',
    img: 'https://i.imgur.com/4ZWvr0P.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/670Evk5.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'kate denson',
    source: 'dead by daylight',
    nickname: '',
    img: 'https://i.imgur.com/8Uss9ci.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/4aY7EOh.jpg',
            credit: 'turbomwet',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'todoroki shoto',
    source: 'boku no hero academia',
    nickname: '',
    img: 'https://i.imgur.com/HngROEG.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/kB630GT.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Lqa9A42.jpg',
            credit: 'diraxy',
            source: 'Amarouhi',
            source_url: 'https://www.zerochan.net/2236166',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/KRtI5Cv.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/qcOenc7.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'c-blanc',
      'y-bleu',
      'moderne',
    ]
  },
   {
    name: 'ashido mina',
    source: 'boku no hero academia',
    nickname: '',
    img: 'https://i.imgur.com/s5BT296.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/vTk1yWr.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/LaVTFWs.png',
            credit: 'clyde',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'moyens',
      'c-rose',
      'y-jaune',
      'moderne',
      'cornes',
    ]
  },
     {
    name: 'arato endo',
    source: 'beatless',
    nickname: '',
    img: 'https://i.imgur.com/SuPosBg.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/akWIqXC.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/M1mV9SK.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'yukako yamagishi',
    source: 'jojo\'s bizarre adventure',
    nickname: '',
    img: 'https://i.imgur.com/W2xFUUr.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/rTypBR1.png',
            credit: 'sweety',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/lFVS7IT.png',
            credit: 'kooerry',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'c-blanc',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'aradia megido',
    source: 'homestuck',
    nickname: '',
    img: 'https://i.imgur.com/zfvtBwV.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/1kYPc8q.png',
            credit: 'kooerry',
            source: 'xiao-tong',
            source_url: 'https://www.zerochan.net/1767142',
          },
          {
            link: 'https://i.imgur.com/q0qE6wY.png',
            credit: 'kooerry',
            source: 'leslie hung',
            source_url: 'https://www.zerochan.net/1422947',
          },
          {
            link: 'https://i.imgur.com/ZtsogSm.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/TaCHgsS.png',
            credit: 'kooerry',
            source: 'xiao-tong',
            source_url: 'https://www.zerochan.net/1767142',
          },
          {
            link: 'https://i.imgur.com/cTPh8Gs.png',
            credit: 'kooerry',
            source: 'leslie hung',
            source_url: 'https://www.zerochan.net/1422947',
          },
          {
            link: 'https://i.imgur.com/mwjmjKS.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'longs',
      'c-noir',
      'y-jaune',
      'y-blanc',
      'fantastique',
      'moderne',
      'cornes',
      'oreilles',
    ]
  },
  {
    name: 'kurloz makara',
    source: 'homestuck',
    nickname: '',
    img: 'https://i.imgur.com/WTi4GYu.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0phVsh4.png',
            credit: 'kooerry',
            source: 'durnoykon',
            source_url: 'https://www.zerochan.net/1672468',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/ykX7WX3.png',
            credit: 'kooerry',
            source: 'durnoykon',
            source_url: 'https://www.zerochan.net/1672468',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'coloree',
      'moyens',
      'c-noir',
      'y-blanc',
      'fantastique',
      'moderne',
      'cornes',
      'oreilles',
    ]
  },
  {
    name: 'terezi pyrope',
    source: 'homestuck',
    nickname: '',
    img: 'https://i.imgur.com/psqMMRx.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/wR1wABH.png',
            credit: 'diraxy',
            source: 'sunny',
            source_url: 'https://ikimaru.tumblr.com/post/148061657395/yay-more-trolls-nepeta-x-x-terezi',
          },
          {
            link: 'https://i.imgur.com/XetCUdj.png',
            credit: 'diraxy',
            source: 'sunny',
            source_url: 'https://ikimaru.tumblr.com/post/158795496930/scourge-sisterss-look-for-the-treasure-its',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/agHRJ1b.png',
            credit: 'diraxy',
            source: 'sunny',
            source_url: 'https://ikimaru.tumblr.com/post/148061657395/yay-more-trolls-nepeta-x-x-terezi',
          },
          {
            link: 'https://i.imgur.com/EbTuuEB.png',
            credit: 'diraxy',
            source: 'sunny',
            source_url: 'https://ikimaru.tumblr.com/post/158795496930/scourge-sisterss-look-for-the-treasure-its',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'moyens',
      'c-noir',
      'y-rouge',
      'fantastique',
      'moderne',
      'cornes',
      'oreilles',
    ]
  },
  {
    name: 'daenerys targaryen',
    source: 'game of thrones',
    nickname: '',
    img: 'https://i.imgur.com/7WHKFU6.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/aUcc2Tm.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'yennefer vengerberg',
    source: 'the witcher',
    nickname: '',
    img: 'https://i.imgur.com/oEZIQxR.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/8uiy3JI.jpg',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'lalatina dustiness',
    source: 'konosuba',
    nickname: '',
    img: 'https://i.imgur.com/U1AWcq6.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'aqua',
    source: 'konosuba',
    nickname: '',
    img: 'https://i.imgur.com/Ok19V4k.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'rimuru tempest',
    source: 'tensei shitara slime datta ken',
    nickname: '',
    img: 'https://i.imgur.com/a7w1OK6.jpg',
    avatars: [
    ],
    tags: [
      'non-binaire',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'fantastique',
    ]
  },
   {
    name: 'zenos',
    source: 'one punch man',
    nickname: '',
    img: 'https://i.imgur.com/a5cP0CR.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/PGmaBPn.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'fubuki',
    source: 'one punch man',
    nickname: '',
    img: 'https://i.imgur.com/2DSP3Wv.jpg',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/P0ariYc.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/r1hzoXq.png',
            credit: 'lacrimosa',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/8Yc75FU.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-vert',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'lucifer',
    source: 'helltaker',
    nickname: '',
    img: 'https://i.imgur.com/2DSP3Wv.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'karkat vantas',
    source: 'homestuck',
    nickname: '',
    img: 'https://i.imgur.com/FUdkrE1.jpg',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'coloree',
      'courts',
      'c-noir',
      'y-rouge',
      'y-jaune',
      'moderne',
      'fantastique',
      'cornes',
      'oreilles',
    ]
  },
  {
    name: 'midorikawa lettuce',
    source: 'tokyo mew mew',
    nickname: '',
    img: 'https://i.imgur.com/LdvYGsf.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-vert',
      'y-vert',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'fujiwara zakuro',
    source: 'tokyo mew mew',
    nickname: '',
    img: 'https://i.imgur.com/U24BARO.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'moderne',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'aizama mint',
    source: 'tokyo mew mew',
    nickname: '',
    img: 'https://i.imgur.com/vHCONxm.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'moderne',
      'fantastique',
      'ailes',
    ]
  },
  {
    name: 'momomiya ichigo',
    source: 'tokyo mew mew',
    nickname: '',
    img: 'https://i.imgur.com/ui2jihz.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-rose',
      'moderne',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'frankenstein',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/VbirZZt.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rose',
      'y-bleu',
      'fantastique',
      'animal',
      'cornes',
    ]
  },
  {
    name: 'koakuma',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/6ChaG9V.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'moderne',
      'animal',
      'oreilles',
    ]
  },
  {
    name: 'rumia',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/Ip2DBK9.png',
    avatars: [
       {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://www.zupimages.net/up/21/16/p18c.png',
            credit: 'sixtine',
            source: 'sh',
            source_url: 'https://twitter.com/_shinh',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'enfant',
      'clair',
      'courts',
      'c-blond',
      'y-rouge',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'nima',
    source: 'rossdraws',
    nickname: '',
    img: 'https://i.imgur.com/O45uqH8.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-blanc',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'kirigiri kyouko',
    source: 'dangan ronpa',
    nickname: '',
    img: 'https://i.imgur.com/NPbQPrw.jpg',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/WAOgq7J.png',
            credit: 'flore',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'midna',
    source: 'the legend of zelda',
    nickname: '',
    img: 'https://i.imgur.com/XOGk43P.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'longs',
      'c-roux',
      'y-orange',
      'fantastique',
    ]
  },
  {
    name: 'yachie kicchou',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/7zaRy8a.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-rouge',
      'fantastique',
      'animal',
      'cornes',
    ]
  },
  {
    name: 'yuuka kazami',
    source: 'touhou project',
    nickname: '',
    img: 'https://i.imgur.com/oBkVy8g.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-vert',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'izumi shimomura',
    source: 'ajin',
    nickname: '',
    img: 'https://i.imgur.com/TKrHfzc.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'soldier 76',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/M3gVqB5.jpg',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-blond',
      'moderne',
    ]
  },
  {
    name: 'sombra',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/TzDhgLW.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'courts',
      'longs',
      'c-noir',
      'c-violet',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'brigitte',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/Rztey1l.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'reinhardt',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/xsf7SZW.jpg',
    avatars: [
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'longs',
      'c-blanc',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'mei',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/5EsIkX6.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'lucio',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/58oTlsN.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'longs',
      'c-brun',
      'c-vert',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'ashe',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/VfhiRd8.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'moira',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/bpiv2GU.jpg',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-roux',
      'y-rouge',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'tracer',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/Rw0EN6t.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
      'lunettes',
    ]
  },
  {
    name: 'zarya',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/0YvpU13.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/eC3NIIW.png',
            credit: 'silvermoondust',
            source: 'zassterwheatie',
            source_url: 'https://zassterwheatie.tumblr.com/post/169164008010/its-been-very-cold-so-naturally-i-felt-like',
          },
          {
            link: 'https://i.imgur.com/G3JeNQ5.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/kY4Ob0C.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/1QGetc7.png',
            credit: 'silvermoondust',
            source: 'Rionaru',
            source_url: 'https://npc016.tumblr.com/post/145278746050/zarya-just-zarya',
          },
          {
            link: 'https://i.imgur.com/aTazFjJ.png',
            credit: 'silvermoondust',
            source: 'Rafaerucore',
            source_url: 'https://rafaerucore.tumblr.com/post/190084481606/part-3-of-casual-overwatch-in-which-i-struggled',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://imgur.com/N6qhcwL.png',
            credit: 'silvermoondust',
            source: 'zassterwheatie',
            source_url: 'https://zassterwheatie.tumblr.com/post/169164008010/its-been-very-cold-so-naturally-i-felt-like',
          },
          {
            link: 'https://i.imgur.com/ggt2XET.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Qt63exw.png',
            credit: 'silvermoondust',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ktidlod.png',
            credit: 'silvermoondust',
            source: 'Rionaru',
            source_url: 'https://npc016.tumblr.com/post/145278746050/zarya-just-zarya',
          },
          {
            link: 'https://i.imgur.com/nPD88Kk.png',
            credit: 'silvermoondust',
            source: 'Rafaerucore',
            source_url: 'https://rafaerucore.tumblr.com/post/190084481606/part-3-of-casual-overwatch-in-which-i-struggled',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-rose',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'symmetra',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/NYTXthx.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-brun',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'hanzo',
    source: 'overwatch',
    nickname: '',
    img: 'https://i.imgur.com/fP5P4kj.png',
    avatars: [
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'ashe',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/iwCbUFO.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/IeqtjW4.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/383FJme.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/rTZQdXp.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/OwkxFbx.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/kMJ3Rhw.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/AzRkl9J.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/59SVSKQ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fj7vTyV.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zyTtduQ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gIYU0iT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/B4HPAOS.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HA64NCi.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CfEp2JP.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/b27JJJp.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'adora',
    source: 'she-ra and the princesses of power',
    nickname: 'she-ra',
    img: 'https://i.imgur.com/emhPUkC.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xZKVLop.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/SlRo6AU.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HuDjfAO.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'cinder fall',
    source: 'rwby',
    nickname: '',
    img: 'https://i.imgur.com/VlBZqN6.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/724e6vu.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Bb6vgrc.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NBwyF2Y.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xTjkwmX.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HoZAkPJ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/z4EEdPh.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-rouge',
      'y-jaune',
      'moderne',
    ]
  },
  {
    name: 'masrur',
    source: 'magi - the labyrinth of magic',
    nickname: '',
    img: 'https://i.imgur.com/wlk1Q9Y.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/5XRYVGK.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CDT6NRb.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Yd8VdEw.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IXu3Nic.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/URNu9JI.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/JKGDQCr.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'aerith',
    source: 'final fantasy',
    nickname: '',
    img: 'https://i.imgur.com/SdN0VKm.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/N8ez9gA.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sCyPFFX.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3KhlaGU.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/B0cTgHB.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NDGYGJv.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/seXrhyr.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/aZDIyv3.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/dhNj2B2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/MxS37Le.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'navier',
    source: 'remarried empress',
    nickname: '',
    img: 'https://i.imgur.com/tPHxjBL.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/XseCZwX.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/MtW8aXq.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VN7zHYT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/t4hd6oc.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0BgV1JJ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Z0lUi24.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jyPKrgf.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zIzGZXh.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/w88CsqM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'katarina',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/knpeXys.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ncvIM4B.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jPEdyXD.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VnKHEN2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/WaFbgai.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/GRwL5sD.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XhRWfUj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/3mUkLZr.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/69W34A7.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HqcNNSW.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'lucilius',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/ztcbiwm.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/CNGyGIk.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/cKm07fM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/qsEZgGa.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/noljUWB.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/tbF2S7j.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'seofon',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/p37R6ho.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/uI8AYwG.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/2yUDNql.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'tifa lockhart',
    source: 'final fantasy',
    nickname: '',
    img: 'https://i.imgur.com/VFnFieM.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/WaQjnID.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/JIYU1PI.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/DmijRDK.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QMrLwXk.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/YH79sX8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8yIdZuQ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2eUtt94.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/OwQjILk.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zi7wqmD.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'asuna yuuki',
    source: 'sword art online',
    nickname: '',
    img: 'https://i.imgur.com/xa2DeAE.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/HJXyCWU.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/v48lyjU.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/M4s69Or.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/MbM6w9I.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/koRMrBP.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/aRXuCJ6.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-orange',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'heles',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/69P973i.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/6a5X3eF.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PMnim2B.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/rZpY79v.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gUTI8Tw.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/whps8MU.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/h5dLddI.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-orange',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'jean bart',
    source: 'azur lane',
    nickname: '',
    img: 'https://i.imgur.com/cCXdK2y.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/QQTTLpY.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hDXZz2V.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IrPAsMG.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ZQVw62O.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/cPjOWdb.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/EiI0Rl5.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/JBaJqI9.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/BzWRTxg.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/RzQlbq6.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'gawain',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/ROEH95R.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/STsvr9f.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/PdUuBgq.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/WRP0Bt8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QVFaaQq.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/RLhArx2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IzCFWug.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/pIPqiUb.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/i0oIJeM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/BqHXwIV.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/wrhonao.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/x9N3NKM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IJh9KmC.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/6rre61t.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NR5u3pc.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gQL3deK.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'miss fortune',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/vLQX5Xj.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/YkNx0ka.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XZkpvYr.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/MEnmkxi.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/jHX6suq.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XIhRXj8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/mcyZ4St.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'tawil',
    source: 'wixoss',
    nickname: '',
    img: 'https://i.imgur.com/5s76e42.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/RFD0IOz.png',
            credit: 'diraxy',
            source: 'byte',
            source_url: 'https://www.pixiv.net/en/artworks/64167577',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/bs7qBG1.png',
            credit: 'diraxy',
            source: 'byte',
            source_url: 'https://www.pixiv.net/en/artworks/64167577',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-vert',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'ulith',
    source: 'wixoss',
    nickname: '',
    img: 'https://i.imgur.com/RX1vsI9.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/fMu29lP.png',
            credit: 'diraxy',
            source: 'ときち',
            source_url: 'https://www.pixiv.net/en/artworks/55244357',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/3JjISef.png',
            credit: 'diraxy',
            source: 'ときち',
            source_url: 'https://www.pixiv.net/en/artworks/55244357',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-blanc',
      'y-rouge',
      'fantastique',
      'ailes',
    ]
  },
  {
    name: 'catra',
    source: 'she-ra and the princesses of power',
    nickname: '',
    img: 'https://i.imgur.com/f6HxvmL.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/SlRo6AU.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HuDjfAO.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-brun',
      'y-bleu',
      'y-jaune',
      'fantastique',
      'animal',
    ]
  },
  {
    name: 'sothe',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/yzomB1e.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0zPEEk7.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Z5di2Ss.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-vert',
      'y-marron',
      'fantastique',
    ]
  },
   {
    name: 'ike',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/6HBZSp3.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/6fI438N.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/sW6QL0n.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'kwon jinie',
    source: 'the breaker',
    nickname: '',
    img: 'https://i.imgur.com/NtOF7Zp.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/5gkG35E.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/JWSiZ3D.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-rouge',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'ranulf',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/m5vmJF4.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/A0ThYaK.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/xzIRzrI.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-vert',
      'y-vert',
      'fantastique',
      'animal',
    ]
  },
    {
    name: 'tibarn',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/Rfb2F9R.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/an9qquD.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/BRhBPAN.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'mature',
      'clair',
      'moyens',
      'c-noir',
      'y-vert',
      'fantastique',
      'oreilles',
      'ailes',
    ]
  },
  {
    name: 'cordelia',
    source: 'fire emblem',
    nickname: '',
    img: 'https://i.imgur.com/9Atae1N.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/w3xzJZR.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/wf5zLA1.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8SdcJb7.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'frey',
    source: 'fisheye placebo',
    nickname: '',
    img: 'https://i.imgur.com/XNYG5pQ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/dpCzr2L.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/lLLv6hF.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-gris',
      'c-bleu',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'crocell reeden',
    source: 'magna carta 2',
    nickname: '',
    img: 'https://i.imgur.com/oSVcCwy.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/onnEzkj.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/frOcGPq.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'kalix clovis',
    source: 'the thorn that pierces me',
    nickname: '',
    img: 'https://i.imgur.com/i1oBAi0.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0zI3zF8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/uzAQArh.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/u3tJd3l.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/clN6mCM.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7GMBzoE.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ehKT64U.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'kai\'sa',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/KIr8Qpg.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/hisHBBF.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VxHHrLC.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/1ELNhuB.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/I1E87nC.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/26cD0kN.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ltKdlsT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ql5vBMY.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/WxAUX4e.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QwiYZp1.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-violet',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'sheherazade',
    source: 'magi - the labyrinth of magic',
    nickname: '',
    img: 'https://i.imgur.com/36GNGo3.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/2G2yyeI.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/uOs5luW.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5QYR6yn.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-vert',
      'fantastique',
    ]
  },
   {
    name: 'ritsu tainaka',
    source: 'k-on',
    nickname: '',
    img: 'https://i.imgur.com/AL34M8G.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/yU85Bcr.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'guren ichinose',
    source: 'owari  no seraph',
    nickname: '',
    img: 'https://i.imgur.com/9DnehIh.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Ug5CaYJ.png',
            credit: 'patd\'amande',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-violet',
      'moderne',
    ]
  },
  {
    name: 'shinoa hiiragi',
    source: 'owari  no seraph',
    nickname: '',
    img: '',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-violet',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'rin',
    source: 'seven knights',
    nickname: '',
    img: 'https://i.imgur.com/d1YWZZD.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0MVNU3d.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8uyERcx.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/27i6xxa.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'yoko nakajima',
    source: 'juuni kokuki',
    nickname: '',
    img: 'https://i.imgur.com/p1bI8UC.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/UQNU6ma.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/6Tj92Lh.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-roux',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'belial',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/c2Ssd8r.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/lNNwRQY.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/T1z7NLK.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/csEg5AT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NOCPMbD.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/f7qjtYy.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/W3n3OSM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'cleopatra',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/rW1DlLB.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ZNTQvvF.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/sWxeTBY.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Tr70Ij1.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/pwvv4dv.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/NflpdSU.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gEONGU0.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/remIXi3.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VcYZjsD.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HSnZu1r.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-vert',
      'c-bleu',
      'y-vert',
      'y-bleu',
      'fantastique',
    ]
  },
  {
    name: 'diarmuid',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/tFzYthw.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/gKp7dpk.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Cs0IZFz.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QwbpFVq.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/H8zpZJo.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/SBJOfbi.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ckehFR3.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/X6sTmya.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ktBcXrj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/leJ1KDT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fnnuhTS.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ztJuysv.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'courts',
      'c-brun',
      'y-jaune',
      'fantastique',
    ]
  },
  {
    name: 'diana',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/WHtc6wk.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/IDQmP3e.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ID5avQA.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/xkzbBz1.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/IBvlq6B.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/5XZyh5F.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vLmusuw.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'kayle',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/7z6wVmS.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/FSg2Uv3.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/krHdzUm.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/kZyghUw.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'fantastique',
    ]
  },
  {
    name: 'myron alexius',
    source: 'magi - the labyrinth of magic',
    nickname: '',
    img: 'https://i.imgur.com/XhK9FMl.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0Sqy0rF.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/49m4Rpm.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/796EfPj.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/knhKDHz.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fUcF4rH.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/33ZgprJ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/WaDtQ18.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/OlsJY8M.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/cFHTf0X.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-rose',
      'fantastique',
    ]
  },
  {
    name: 'circe',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/H8ROZfe.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/P5ALfwM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/iIchtog.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/ERNKtSw.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/4qg1ajV.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/utbIc8V.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/junSkEG.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-jaune',
      'fantastique',
      'ailes',
      'oreilles',
    ]
  },
  {
    name: 'gray fullbuster',
    source: 'fairy tail',
    nickname: '',
    img: 'https://i.imgur.com/cwQQoYb.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/tSW7ofD.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/wpFsTEq.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7N91hTM.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-gris',
      'fantastique',
    ]
  },
  {
    name: 'vira',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/D8tu4Pj.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/XDtFUTQ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/bB6hlSS.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QWSmESu.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/115hyGT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/RWtpWkP.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Xp4nenS.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/HlN3QgH.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/N6iAmI8.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vC6DRf3.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/jduFZwY.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/9syUlvG.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/hlEzCLQ.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2sqjFPV.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/gXE6EqF.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Ic6iqs0.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/XLOhtwd.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/QPta7Vs.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0FkcTK2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'rosetta',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/KRk7IX8.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/u1jTzTT.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/vdOLxaK.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/J5zTf1x.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/e3y83ju.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/W95wz5T.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/4mLvfZX.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/klZqMv2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/46CEu02.jpg',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/eQl62KF.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-violet',
      'fantastique',
    ]
  },
  {
    name: 'lux',
    source: 'league of legends',
    nickname: '',
    img: 'https://i.imgur.com/KCTxtrn.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/qJV08Cr.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Y2vtdeu.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/bCTvmkf.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'fantastique',
    ]
  },
   {
    name: 'oda nobunaga',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/L7aTD04.png',
    avatars: [
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'maou nobunaga',
    source: 'fate/series',
    nickname: '',
    img: 'https://i.imgur.com/b92WxEH.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/16V2Wg7.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/lHAhozF.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
          {
            link: 'https://sd-6.archive-host.com/membres/up/fb421ef8c974ec7441e9b542aa5f10b206b1c639/PRD/ode200320.png',
            credit: 'kapuccino',
            source: '',
            source_url: '',
          }
        ]
      },
       {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/qTjAXtg.jpeg',
            credit: 'nana',
            source: '',
            source_url: '',
          },
          {
            link: 'https://sd-6.archive-host.com/membres/up/fb421ef8c974ec7441e9b542aa5f10b206b1c639/PRD/odenobunaga250350.png',
            credit: 'kappucino',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'mature',
      'clair',
      'longs',
      'c-rouge',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'miranda priestly',
    source: 'the devil wears prada',
    nickname: '',
    img: 'https://i.imgur.com/Cgy2uAl.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xUUAq4L.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
       {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/CKr1gv9.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'mature',
      'clair',
      'courts',
      'c-blanc',
      'y-marron',
      'moderne',
    ]
  },
   {
    name: 'ava ire',
    source: 'ava\'s demon',
    nickname: '',
    img: 'https://i.imgur.com/hmktM3G.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/UBuKhHD.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
       {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/W6RJkA2.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'enfant',
      'jeune',
      'sombre',
      'longs',
      'c-rouge',
      'c-brun',
      'y-jaune',
      'y-rouge',
      'moderne',
      'cornes',
      'oreilles',
    ]
  },
  {
    name: 'ace trappola',
    source: 'twisted wonderland',
    nickname: '',
    img: 'https://i.imgur.com/VkLWZWs.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/XCJmY9S.png',
            credit: 'takeitezy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-roux',
      'y-rouge',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'xie bi\'an',
    source: 'identity v',
    nickname: '',
    img: 'https://i.imgur.com/xJ5TSyH.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/gRgBaci.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'fantastique',
    ]
  },
  {
    name: 'mugen',
    source: 'samourai champloo',
    nickname: '',
    img: 'https://i.imgur.com/9wzzhNl.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/PsNwzt3.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'sombre',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
   {
    name: 'yuki',
    source: 'black survival',
    nickname: '',
    img: 'https://i.imgur.com/64eO2y9.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/xDTlZrY.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8JTdOj3.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/KlYSCKV.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/H3p1aUm.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/leg1K4q.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'hyun woo',
    source: 'black survival',
    nickname: '',
    img: 'https://i.imgur.com/WpRWXRz.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/vO9N15L.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/VTEGJVt.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/Gci1MBg.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-rouge',
      'y-marron',
      'moderne',
    ]
  },
   {
    name: 'sissela',
    source: 'black survival',
    nickname: '',
    img: 'https://i.imgur.com/lWKLnXV.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/tK4IJ24.png',
            credit: 'felix geyer',
            source: 'obex117',
            source_url: 'https://www.pixiv.net/en/users/15871016',
          },
          {
            link: 'https://i.imgur.com/MtNQj9X.png',
            credit: 'felix geyer',
            source: 'ban',
            source_url: 'https://www.pixiv.net/en/users/38219177',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'moyens',
      'c-gris',
      'y-vert',
      'moderne',
      'cacheoeil',
    ]
  },
  {
    name: 'li dailin',
    source: 'black survival',
    nickname: '',
    img: 'https://i.imgur.com/Z7eAnuc.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/LmB3NqO.png',
            credit: 'felix geyer',
            source: 'poper',
            source_url: 'https://www.pixiv.net/en/users/17312525',
          },
          {
            link: 'https://i.imgur.com/qlZVmnk.png',
            credit: 'felix geyer',
            source: 'ho nyang',
            source_url: 'https://www.pixiv.net/en/users/30413979',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-orange',
      'moderne',
    ]
  },
   {
    name: 'jan',
    source: 'black survival',
    nickname: '',
    img: 'https://i.imgur.com/jErp7bA.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0BqlUZI.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/lhXjdZC.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'sombre',
      'moyens',
      'c-blanc',
      'y-orange',
      'moderne',
    ]
  },
  {
    name: 'arda',
    source: 'black survival',
    nickname: '',
    img: 'https://i.imgur.com/wcyCYsv.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/t94ps9V.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-brun',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'mia corvere',
    source: 'the nevernight chronicle',
    nickname: '',
    img: 'https://i.imgur.com/t4d28kr.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ZWktAVi.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
   {
    name: 'vivy',
    source: 'vivy fluorite eyes song',
    nickname: '',
    img: 'https://i.imgur.com/pi7Db3f.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/uh5NacL.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-bleu',
      'y-bleu',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'claudia',
    source: 'dragon prince',
    nickname: '',
    img: 'https://i.imgur.com/KoyKtCR.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.ibb.co/KVqxnzJ/avatar.jpg',
            credit: 'wisukii',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/bnoCKYI.gif',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-vert',
      'fantastique',
    ]
  },
  {
    name: 'rayla',
    source: 'dragon prince',
    nickname: '',
    img: 'https://i.imgur.com/oRU2Zrx.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Zg2TTU0.gif',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-violet',
      'fantastique',
      'cornes',
    ]
  },
  {
    name: 'rin',
    source: 'shelter',
    nickname: '',
    img: 'https://i.imgur.com/bU9E2gV.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/ecsnYVX.png',
            credit: 'takeitezy',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/CZZxWZu.png',
            credit: 'takeitezy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'longs',
      'c-rose',
      'c-brun',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'cinderella',
    source: 'sinoalice',
    nickname: '',
    img: 'https://i.imgur.com/qjRsJ1o.png',
    avatars: [
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/ntqkRe0.png',
            credit: 'takeitezy',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'sombre',
      'longs',
      'c-rose',
      'c-brun',
      'y-bleu',
      'y-violet',
      'fantastique',
      'moderne',
    ]
  },
   {
    name: 'q',
    source: 'control',
    nickname: '',
    img: 'https://i.imgur.com/jaPc46W.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/Tj18B1j.png',
            credit: 'lio',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-vert',
      'c-bleu',
      'y-bleu',
      'y-rouge',
      'fantastique',
      'cornes',
      'oreilles',
    ]
  },
  {
    name: 'soul eater evans',
    source: 'soul eater',
    nickname: '',
    img: 'https://i.imgur.com/wcxAAAq.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/3CXmdiJ.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'y-rouge',
      'moderne',
    ]
  },
  {
    name: 'maka albarn',
    source: 'soul eater',
    nickname: '',
    img: 'https://i.imgur.com/1IDqbZG.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/5rHt0ii.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blond',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'woo jin kwak',
    source: 'unlucky mansion',
    nickname: '',
    img: 'https://i.imgur.com/ExTj11X.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/0UhBN98.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/fMSwJYm.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'courts',
      'c-noir',
      'y-marron',
      'moderne',
    ]
  },
  {
    name: 'eun ju kang',
    source: 'unlucky mansion',
    nickname: '',
    img: 'https://i.imgur.com/vSWhWyv.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/mFgsOxW.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/FIZvNb4.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-marron',
      'moderne',
    ]
  },
   {
    name: 'ichihara yuuko',
    source: 'xxxholic',
    nickname: '',
    img: 'https://i.imgur.com/PxVyblx.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/k4nhGPZ.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/eXlafak.png',
            credit: 'stella',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/pdi8y0D.png',
            credit: 'cheshireftw',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '200',
        height: '400',
        data: [
          {
            link: 'https://i.imgur.com/1wC9tSp.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
      ],
    },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-noir',
      'y-rouge',
      'y-noir',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'tokushirou tsurumi',
    source: 'golden kamuy',
    nickname: '',
    img: 'https://i.imgur.com/wCzhmNj.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/BlS03ao.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/KaiOjWB.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/TmN1nEY.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
           {
            link: 'https://i.imgur.com/S5BS8Xe.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
   {
    name: 'revy',
    source: 'black lagoon',
    nickname: '',
    img: 'https://i.imgur.com/c2tdTzZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/qXhm3dE.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/fP1aemX.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-violet',
      'c-brun',
      'y-jaune',
      'moderne',
    ]
  },
 {
    name: 'hajime tsukishima',
    source: 'golden kamuy',
    nickname: '',
    img: 'https://i.imgur.com/xo68co4.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/rNvRFFf.png',
            credit: 'felix geyer',
            source: 'hinna',
            source_url: 'https://twitter.com/hinna_hinna_hin',
          },
          {
            link: 'https://i.imgur.com/ZcvN9go.png',
            credit: 'felix geyer',
            source: 'hinna',
            source_url: 'https://twitter.com/hinna_hinna_hin',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
       {
    name: 'tokishige usami',
    source: 'golden kamuy',
    nickname: '',
    img: 'https://i.imgur.com/4WH1NbP.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/r9elxvD.png',
            credit: 'felix geyer',
            source: 'hinna',
            source_url: 'https://twitter.com/hinna_hinna_hin',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-noir',
      'y-noir',
      'moderne',
      'fantastique',
    ]
  },
      {
      name: 'toru',
      source: 'stray dog - vanrah',
      img: 'https://i.imgur.com/mYydUL8.png',
      nickname: '',
      avatars: [
        {
          width: '200',
          height: '320',
          data: [
            {
              link: 'https://i.imgur.com/Y9b0eOL.png',
              credit: 'patd\'amande',
              source: ''
            },
            {
              link: 'https://i.imgur.com/AoN8utw.png',
              credit: 'patd\'amande',
              source: ''
            },
          ]
        }
      ],
      tags: [
        'homme',
        'jeune',
        'clair',
        'courts',
        'c-brun',
        'y-rouge',
        'fantastique'
      ]
    },
      {
      name: 'huijin park',
      source: 'jungle juice',
      nickname: '',
      img: 'https://i.imgur.com/wnxxCo7.png',
      avatars: [
        {
          width: '200',
          height: '320',
          data: [
            {
              link: 'https://i.imgur.com/mAptLbJ.png',
              credit: 'cheshireftw',
              source: ''
            },
          ]
        },
         {
          width: '250',
          height: '350',
          data: [
            {
              link: 'https://i.imgur.com/2xLpoTg.png',
              credit: 'cheshireftw',
              source: ''
            },
          ]
        }
      ],
      tags: [
        'femme',
        'jeune',
        'clair',
        'longs',
        'c-noir',
        'y-rose',
        'y-rouge',
        'moderne'
      ]
    },
      {
      name: 'alex',
      source: 'black survival',
      img: 'https://i.imgur.com/xFJBk1J.png',
      nickname: '',
      avatars: [
        {
          width: '200',
          height: '320',
          data: [
            {
              link: 'https://i.imgur.com/WFRmHSi.png',
              credit: 'cheshireftw',
              source: ''
            },
          ]
        },
         {
          width: '250',
          height: '350',
          data: [
            {
              link: 'https://i.imgur.com/fRMIDsx.png',
              credit: 'cheshireftw',
              source: ''
            },
          ]
        }
      ],
      tags: [
        'homme',
        'jeune',
        'clair',
        'courts',
        'c-blond',
        'y-bleu',
        'moderne',
        'lunettes',
      ]
    },
    {
      name: 'rozzi',
      source: 'black survival',
      img: 'https://i.imgur.com/MbfDpz7.png',
      nickname: '',
      avatars: [
        {
          width: '200',
          height: '320',
          data: [
            {
              link: 'https://i.imgur.com/yABINE9.png',
              credit: 'felix geyer',
              source: ''
            },
          ]
        },
      ],
      tags: [
        'femme',
        'jeune',
        'clair',
        'courts',
        'c-noir',
        'y-vert',
        'moderne'
      ]
    },
       {
      name: 'silvia',
      source: 'black survival',
      img: 'https://i.imgur.com/zznRXt5.png',
      nickname: '',
      avatars: [
        {
          width: '200',
          height: '320',
          data: [
            {
              link: 'https://i.imgur.com/XxQtUZe.png',
              credit: 'felix geyer',
              source: 'https://www.artstation.com/mlind'
            },
          ]
        },
      ],
      tags: [
        'femme',
        'jeune',
        'clair',
        'courts',
        'c-noir',
        'y-bleu',
        'moderne'
      ]
    },
      {
    name: 'eivor (male version)',
    source: 'assassin\'s creed',
    nickname: '',
    img: 'https://i.imgur.com/wuCoNWn.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/7Kj3HSO.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/YNGBk2a.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'moyens',
      'c-blond',
      'y-vert',
      'fantastique',
    ]
  },
   {
    name: 'ichigo',
    source: 'darling in the franxx',
    nickname: '',
    img: 'https://i.imgur.com/HP2kWkx.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/BpopFIk.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'courts',
      'c-bleu',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'ikuno',
    source: 'darling in the franxx',
    nickname: '',
    img: 'https://i.imgur.com/t2Ri500.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/uTO6yLA.png',
            credit: 'diraxy',
           source: 'yoneyama mai',
            source_url: 'https://twitter.com/yoneyamai/status/1176341624802009089?s=20',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/PnpWbbI.png',
            credit: 'diraxy',
            source: 'yoneyama mai',
            source_url: 'https://twitter.com/yoneyamai/status/1176341624802009089?s=20',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-noir',
      'c-violet',
      'y-vert',
      'moderne',
    ]
  },
  {
    name: 'miku',
    source: 'darling in the franxx',
    nickname: '',
    img: 'https://i.imgur.com/fmN0VQ1.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/5PXvLoa.png',
            credit: 'diraxy',
            source: 'yoneyama mai',
            source_url: 'https://twitter.com/yoneyamai/status/1176341624802009089?s=20',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/w7prz8C.png',
            credit: 'diraxy',
            source: 'yoneyama mai',
            source_url: 'https://twitter.com/yoneyamai/status/1176341624802009089?s=20',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-orange',
      'c-brun',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'kokoro',
    source: 'darling in the franxx',
    nickname: '',
    img: 'https://i.imgur.com/QbZvb32.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/urPLHNS.png',
            credit: 'diraxy',
            source: 'yoneyama mai',
            source_url: 'https://twitter.com/yoneyamai/status/1176341624802009089?s=20',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/yrbk3qJ.png',
            credit: 'diraxy',
            source: 'yoneyama mai',
            source_url: 'https://twitter.com/yoneyamai/status/1176341624802009089?s=20',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-bleu',
      'moderne',
    ]
  },
  {
    name: 'claudine saijo',
    source: 'revue starlight',
    nickname: '',
    img: 'https://i.imgur.com/upMxfRZ.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/M8EeINe.png',
            credit: 'diraxy',
            source: 'selisuke',
            source_url: 'https://twitter.com/selisuke/status/1381606405019299841?s=20',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/j15mz38.png',
            credit: 'diraxy',
            source: 'selisuke',
            source_url: 'https://twitter.com/selisuke/status/1381606405019299841?s=20',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blond',
      'y-marron',
      'moderne',
      'fantastique',
    ]
  },
  {
    name: 'sesshomaru',
    source: 'inuyasha',
    nickname: '',
    img: 'https://i.imgur.com/B0Gm8VB.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/yT0FZDO.png',
            credit: 'diraxy',
            source: 'mmmilk_',
            source_url: 'https://twitter.com/milk23148885/status/1384432683304951812?s=20',
          },
          {
            link: 'https://i.imgur.com/QjEqmHk.png',
            credit: 'diraxy',
            source: 'mmmilk_',
            source_url: 'https://twitter.com/milk23148885/status/1364597480017420290?s=20',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/DHnCmAA.png',
            credit: 'diraxy',
            source: 'mmmilk_',
            source_url: 'https://twitter.com/milk23148885/status/1384432683304951812?s=20',
          },
          {
            link: 'https://i.imgur.com/syK8qaG.png',
            credit: 'diraxy',
            source: 'mmmilk_',
            source_url: 'https://twitter.com/milk23148885/status/1364597480017420290?s=20',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'fantastique',
      'oreilles',
    ]
  },
        {
    name: 'takiyasha hime',
    source: 'onmyouji',
    nickname: '',
    img: 'https://i.imgur.com/OBUTDR9.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/N6rOE3F.png',
            credit: 'esperance',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'coloree',
      'clair',
      'longs',
      'c-blanc',
      'y-jaune',
      'fantastique',
    ]
  },
    {
    name: 'fluttershy',
    source: 'my little pony',
    nickname: '',
    img: 'https://i.imgur.com/RvQgn8B.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/V1YRFUr.png',
            credit: 'caldinia',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-rose',
      'y-bleu',
      'fantastique',
      'moderne'
    ]
  },
      {
    name: 'sucre',
    source: 'off',
    nickname: '',
    img: 'https://i.imgur.com/a5vNQ8r.png',
    avatars: [
      {
          width: '200',
          height: '320',
          data: [
            {
              link: 'https://i.imgur.com/m6kT6Si.png',
              credit: 'azria',
              source: ''
            },
          ]
        },
      {
          width: '250',
          height: '350',
          data: [
            {
              link: 'https://i.imgur.com/c0Vx4Cz.png',
              credit: 'azria',
              source: ''
            },
          ]
        }
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'moyens',
      'c-blanc',
      'fantastique',
    ]
  },
  {
    name: 'ciel phantomhive',
    source: 'black butler',
    nickname: '',
    img: 'https://i.imgur.com/kEeaWZl.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/1Cn85oY.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
      {
        width: '250',
        height: '350',
        data: [
          {
            link: 'https://i.imgur.com/GmCtPna.png',
            credit: 'azria',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'jeune',
      'enfant',
      'clair',
      'courts',
      'c-gris',
      'y-bleu',
      'fantastique',
      'cacheoeil',
    ]
  },
  {
    name: 'oshino meme',
    source: 'bakemonogatari',
    nickname: '',
    img: 'https://i.imgur.com/whyAsIm.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/7Z6UZP9.png',
            credit: 'felix geyer',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'homme',
      'mature',
      'clair',
      'courts',
      'c-blond',
      'y-noir',
      'moderne',
    ]
  },
  {
    name: 'hayan',
    source: 'white blood',
    nickname: '',
    img: 'https://i.imgur.com/hvViAdR.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://images2.imgbox.com/66/29/Xim4PNVD_o.gif',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://images2.imgbox.com/1f/ef/BycoZgtF_o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://images2.imgbox.com/47/87/fy6f6qc4_o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://images2.imgbox.com/ad/b5/dtAOqKJB_o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://images2.imgbox.com/6e/3d/ftUku7oQ_o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://images2.imgbox.com/ab/80/76ucm5Nh_o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://images2.imgbox.com/e5/ba/187yEJc3_o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://images2.imgbox.com/bd/62/S5JYIttI_o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://images2.imgbox.com/1d/8e/R5rFGoTH_o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://images2.imgbox.com/69/ed/X9Qs3oX2_o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://images2.imgbox.com/96/d0/Qlt6eH3J_o.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      },
    ],
    tags: [
      'femme',
      'jeune',
      'clair',
      'longs',
      'c-blanc',
      'y-bleu',
      'c-brun',
      'y-marron',
      'fantastique',
      'moderne',
    ]
  },
  {
    name: 'siegfried',
    source: 'granblue fantasy',
    nickname: '',
    img: 'https://i.imgur.com/Xa2ZENb.png',
    avatars: [
      {
        width: '200',
        height: '320',
        data: [
          {
            link: 'https://i.imgur.com/pCBokzO.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/0MPCJp2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/CBEwYst.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/OXc1oqY.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/7kYiKMz.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/wqIzagK.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/csSb1wN.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/OoAqLN2.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/Wl9SfBb.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/zMVlYdi.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/2dTmHj8.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
          {
            link: 'https://i.imgur.com/8G8kDGP.png',
            credit: 'visenya',
            source: '',
            source_url: '',
          },
        ]
      }
    ],
    tags: [
      'homme',
      'jeune',
      'clair',
      'longs',
      'c-brun',
      'y-marron',
      'fantastique',
    ]
  },
];



  export const getNbOfFaceclaims = () => {
    let count = 0;
    charactersData.map((character) => {
      count += 1;
    });
    return count;
  };



  export const getNumberOfAvatars = () => {
    let count = 0;
    charactersData.map((character) => {
      character.avatars.map((size: any) => {
        if (size.data) {
          count += size.data.length;
        }
      });
    });
    return count;
  };
