import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { getNbOfFaceclaims, getNumberOfAvatars } from '../../../data/data';
import '../index.css';

const HomeMobile = (): JSX.Element => {
  const history = useHistory();
  const [tab, setTab] = useState('accueil');
  useEffect(() => {
    document.body.classList.add('body-bg');
    if (history.location.pathname === '/') {
      setTab('accueil');
    } else {
      setTab('accueil');
    }
  }, []);


  const copyText = (str: string) => {
    if (document) {
      //@ts-ignore
      document.getElementById(str).select();
      document.execCommand('copy');
    }
  }

  return (
    <div className="mobile">
      <div className="title">Hall of Fame</div>
      <div className="navigation">
        <div className="nav-item" onClick={() => { setTab('accueil') }}>
          <div className="nav-img"><img src="https://i.imgur.com/tYVMTrB.png" /></div>
          <div className="nav-name">accueil</div>
        </div>
        <a href="https://www.hall-of-fame.fr/avatars">
          <div className="nav-item">
            <div className="nav-img"><img src="https://i.imgur.com/A4x7r9c.png" /></div>
            <div className="nav-name">avatars</div>
          </div>
        </a>
        <a href="https://www.hall-of-fame.fr/faceclaims">
          <div className="nav-item">
            <div className="nav-img"><img src="https://i.imgur.com/BXTPKGA.png" /></div>
            <div className="nav-name">faceclaims</div>
          </div>
        </a>
        <div className="nav-item" onClick={() => { setTab('faq') }}>
          <div className="nav-img"><img src="https://i.imgur.com/WHSYirS.png" /></div>
          <div className="nav-name">faq</div>
        </div>
        <div className="nav-item" onClick={() => { setTab('participer') }}>
          <div className="nav-img"><img src="https://i.imgur.com/GGKNKHY.png" /></div>
          <div className="nav-name">participer</div>
        </div>
        <div className="nav-item" onClick={() => { setTab('partenariats') }}>
          <div className="nav-img"><img src="https://i.imgur.com/EpY0eCH.png" /></div>
          <div className="nav-name">partenariats</div>
        </div>
        <div className="nav-item" onClick={() => { setTab('credits') }}>
          <div className="nav-img"><img src="https://i.imgur.com/xLIYwly.png" /></div>
          <div className="nav-name">crédits</div>
        </div>
      </div>
      <div className="content-overflow">

        {tab === 'accueil' && (
          <>
            Ô jeune voyageur des contrées roleplayistes, te voilà arrivé dans le Hall of Fame ! Il serait normal que tu t'interroges sur la raison de ta venue.
                Et bien sache que Hall of Fame, ce n'est autre qu'<strong>un répertoire de ressources</strong> destiné à la formidable communauté du roleplay francophone. Pour l'instant,
                tu as accès en <em>libre-service à des avatars</em> ainsi qu'un répertoire de faceclaims et bientôt, tu pourras également trouver ton bonheur parmi <em>les codes</em> proposés par nos créateurs.

                <br /> <br />

                En bref, Hall of Fame, c'est des <strong>personnes formidables</strong> qui prennent de leur temps pour créer du contenu utilisable par tous.

                <br /><br />

                En cas de doutes sur le fonctionnement de ce répertoire, je t'invite à aller lire l'ensemble de <em>la FAQ</em> ainsi que les pages relatives à chaque libre service.
                Elles te donneront toutes les informations nécessaires pour pouvoir utiliser les ressources de HoF.

                <div className="line" />

            <div className="tab-container">
              <div className="infos">
                <div className="infos-nb"><span style={{ color: '#f34a50' }}>{getNumberOfAvatars()}</span> avatars</div>
                <div className="infos-nb"><span style={{ color: '#f34a50' }}>{getNbOfFaceclaims()}</span> faceclaims</div>
                <div className="infos-nb"><span style={{ color: '#f34a50' }}>31</span> graphistes</div>
                <br />
                <strong>Dernière mise à jour: </strong> 16/04/2021<br />
                <strong>Statut de l'envoi d'avatars et de codes: </strong> ouvert<br />
                <strong>Date d'ouverture: </strong> 27/11/2020<br />
                <strong>Thème: </strong> version 2.0 par <em>azria</em><br /><br />

                <div className="line2" /><br />

                <strong><span style={{ color: '#f34a50' }}>DESCRIPTIF DE LA DERNIERE MAJ</span></strong><br />
                    — Nouveau thème {"(version 2.0)"} par azria.<br />
                    — Ajout de <strong>350+</strong> avatars<br />
                    — Ajout de <strong>200+</strong> faceclaims<br />
                    — <strong>17</strong> nouveaux graphistes !<br />
                    — Le site a été entièrement recodé: les performances de chargement d'image notamment devraient être bien améliorées
                  </div>
            </div>
          </>
        )}

        {tab === 'faq' && (
          <>
            C'est ici qu'on référence toutes les questions que vous pouvez vous poser à propos du Hall of Fame. Si vous avez des questions qui ne sont
            pas référencées ici, n'hésitez pas à faire un tour sur notre <strong>serveur Discord</strong> et à nous la poser !<br /><br />

            <em>Dois-je créditer les artistes et/ou Hall of Fame pour utiliser les ressources du site ?</em><br />
                Bien que très apprécié, le crédit n'est <strong>pas obligatoire.</strong> Vous pouvez sans problème utiliser un avatar sans en créditer
                l'artiste ou Hall of Fame, mais c'est toujours un plus de le faire.<br />
                Toutefois, notez qu'il est <strong>strictement interdit</strong> de se prétendre le créateur d'une ressource trouvée sur Hall of Fame tout comme il est
                interdit d'enlever le crédit qu'un auteur a pu apposer sur sa ressource (ex: une fiche de rp portant le crédit de l'auteur).<br /><br />

            <em>Ai-je le droit de modifier un avatar ou un code en libre-service ?</em><br />
                En ce qui concerne <strong>les avatars</strong>, il est strictement interdit de les modifier.<br />
                Pour <strong>les codes</strong> en revanche, vous pouvez sans problème effectuer des modifications mineures (telles que changer les couleurs
                ou les images). En cas de contre-indication, c'est-à-dire un créateur ne souhaitant pas voir son code modifié, cela est précisé dans le
                libre-service du code en lui-même.<br /><br />

            <em>Un serveur Discord ? Qui peut le rejoindre et à quoi il sert ?</em><br />
                Le serveur discord d'Hall of Fame a de multiples utilités et est au cœur de notre site. <strong>Tout le monde</strong> y est le bienvenu,
                même si vous ne savez ni coder ni grapher. C'est une zone d'échange destinée à rassembler la communauté d'Hall of Fame. C'est dessus que
                vous pourrez faire des <strong>partenariats</strong>, nous faire remonter des <strong>suggestions</strong>, <strong>partager</strong> vos créations pour qu'elles soient rajoutées sur le site
                ou encore simplement discuter avec d'autres personnes issues de la communauté rp francophone.<br />
                Rejoindre le discord vous permet aussi de vous tenir
                au courant des mises-à-jour ainsi que profiter de sneak-peeks des prochaines créations qui vont être mises en ligne.<br /><br />

            <em>Est-ce qu'Hall of Fame gagne la propriété de ma création une fois celle-ci mise en ligne ?</em><br />
                Non, les avatars et codes restent l'entière propriété de leurs auteurs.<br /><br />

            <em>Comment est-ce que je peux commander un avatar ou un code ?</em><br />
                Hall of Fame étant un répertoire de ressources en libre-service, nous n'offrons <strong>pas</strong> de système de commandes. Bien qu'il
                ne soit pas exclus qu'un tel service soit intégré à Hall of Fame dans le futur, ce n'est pour l'instant pas le cas.<br />
                En revanche, en ce qui concerne <strong>les avatars</strong>, vous pouvez toujours proposer une <strong>suggestion</strong> sur le discord et si un graphiste en a l'envie,
                vous pourriez voir apparaître un avatar du personnage que vous avez demandé dans le libre-service.<br />
                Pour <strong>les codes</strong>, il en va de même : vous pouvez suggérer des types de codes à rajouter (par exemple un code d'échanges téléphoniques).<br />
                Notez que les suggestions ne sont rien de plus que des suggestions et rien ne garantit qu'elles vont être suivies.
                </>
        )}

        {tab === 'participer' && (
          <>
            Sache que si tu souhaites partager des créations sur Hall of Fame, la première étape c'est de <strong>recevoir mon amour</strong> inconditionnel !
                Merci beaucoup, c'est grâce aux gens comme vous que Hall of Fame peut grandir. ♡<br /><br />

                La deuxième étape, c'est de <em>rejoindre</em> notre serveur discord.<br /><br />

            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/91_Discord_logo_logos-512.png" alt="discord" style={{ width: '40px', height: '40px', marginRight: '20px' }} />

              <div>
                <div style={{ display: 'block' }}><strong>SERVEUR DISCORD : </strong> <a href="https://discord.gg/fs8Du8U2TF" target="_blank">Hall of Fame</a></div>
                    Toutes les informations nécessaires sont disponibles sur le Discord.<br /><br />
              </div>
            </div>

                Vous trouverez toutes les infos dont vous aurez besoin sur le discord dans les catégories <em>AVATARS</em> et <em>CODES</em>. Pensez à bien lire
                les règles relatives à la catégorie et vous devriez avoir toutes les armes en main pour partager vos créations.<br /><br />

                Malheureusement, vous ne pouvez pas envoyer vos créations par mail : tout passe par le discord pour des soucis d'organisation et de simplicité.
                </>
        )}


        {tab === 'partenariats' && (
          <>
            Déjà, un <em>grand merci</em> de vouloir soutenir le projet Hall of Fame et de vouloir participer à donner l'accès à nos ressources à la plus grande communauté possible ! ♡<br /><br />

                Il n'existe que deux types de partenariats sur HoF : <strong>les meilleurs copains</strong> qui sont affichés en évidence sur toutes les pages et <strong>les petits potes</strong> qui sont affichés
                en bas de la page d'accueil. Ils sont également un peu spéciaux puisqu'il n'y a pas d'échange de fiches sur Hall of Fame, on s'échange juste <em>nos boutons</em> (et un peu d'amour aussi).
                <br />
            <div className="line" />
                Pour faire la demande de partenariat, c'est direction le serveur <strong>Discord</strong>. Si vous ne souhaitez pas rejoindre le discord, vous pouvez m'envoyer la demande directement à <em>Lauranosaure#5258</em>. Vous pouvez également envoyer la demande à <em>halloffame-rpg@outlook.com</em> mais la réponse risque d'être un poil plus longue. Vous trouverez juste
                en dessous <strong>un formulaire</strong> qui rassemble les infos nécessaires pour le partenariat.<br />
            <em><span style={{ color: 'grey', fontSize: '10px' }}>Notez que nous ne proposons pas d'échanges de fiches au vu de la nature de HoF. Pour que nous mettions à jour votre bouton, pingez-moi de la même manière que pour un
                ajout, sur discord ou par email.</span></em><br /><br />
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/91_Discord_logo_logos-512.png" alt="discord" style={{ width: '40px', height: '40px', marginRight: '20px' }} />

              <div>
                <div style={{ display: 'block' }}><strong>SERVEUR DISCORD : </strong> <a href="https://discord.gg/fs8Du8U2TF" target="_blank">Hall of Fame</a></div>
                    Toutes les informations nécessaires sont disponibles sur le Discord.<br /><br />
              </div>
            </div>
            <div className="line" />

            <strong>Nom du forum : </strong>nom<br />
            <strong>Lien du forum : </strong>lien<br />
            <strong>Type de partenariat désiré : </strong> [ ] MEILLEURS COPAINS (top) [ ] PETITS POTES (footer)<br />
            <strong>Lien vers vos boutons : </strong> lien<br />
            <strong>Où serions-nous affichés ? </strong> (footer, pa, etc)<br />
            <strong>Un petit mot en plus ? </strong> un mot d'amour, une suggestion, le champ est libre ! ♡<br /><br />
            <div className="line" />

            <div style={{ textAlign: 'center' }}><em>50*50</em><br />

              <a href="https://www.hall-of-fame.fr/"><img src="https://i.imgur.com/cVZDJfK.png" alt="hall of fame" /></a><br />
            
              <em>88*31</em><br />

              <a href="https://www.hall-of-fame.fr/"><img src="https://i.imgur.com/q9iayiI.png" alt="hall of fame" /></a><br />
             

              <em>100*35</em><br />

              <a href="https://www.hall-of-fame.fr/"><img src="https://i.imgur.com/2heJSKg.png" alt="hall of fame" /></a><br />
              
              <em>250*60</em><br />

              <a href="https://www.hall-of-fame.fr/"><img src="https://i.imgur.com/JU6wyjf.png" alt="hall of fame" /></a><br />
              
              <em>468*60</em><br />

              <a href="https://www.hall-of-fame.fr/"><img src="https://i.imgur.com/RXdB6C1.png" alt="hall of fame" /></a><br />
              </div>
          </>
        )}


        {tab === 'credits' && (
          <>
            Hall of Fame, c'est un projet né dans la tête d'<strong>Azria</strong> il y a un petit moment déjà. Je remercie avec plein d'amour
                  les meilleures des meilleures, <strong>Palourde</strong> et <strong>Cheshireftw</strong> qui ont rejoint l'aventure en cours de route
                  et qui sont une source de motivation constante ainsi que des mains de fées. C'est grâce à elles que j'ai pas eu la flemme et que
                  le projet est pas sorti un an plus tard :-)<br /><br />

                  Et si HoF existe, c'est aussi en tant que successeur spirituel de <em>Penguin Factory</em> dont je n'arrêterai jamais de chanter les louanges :
                  merci à Pleasance et Mim de nous avoir vendu du rêve il y a des années au point que tout le monde se souvienne encore d'eux maintenant.<br /><br />

                  Hall of Fame, ce serait vraiment <em>(vraiment)</em> rien sans toutes les personnes qui <strong>contribuent</strong> au projet. Actuellement,
                  c'est pas moins de <strong>31</strong> personnes au cœur d'or qui ont participé à HoF. Donc c'est avec joie que je
                  vous les présente toutes : <br /><br />

            <div className="row-container">
              <img src="https://i.imgur.com/dS3vzQ8.png" alt="crédits" />
              <div className="credits-container">
                <strong>ailou - </strong> pas de site web<br />
                <strong>azria - </strong> pas de site web<br />
                <strong>cheshireftw - </strong> <a href="https://mahoutokoro.forumactif.com/" target="_blank">mahoutokoro</a><br />
                <strong>chupp - </strong> <a href="https://zheroes.forumactif.com/" target="_blank">zheroes</a><br />
                <strong>clyde - </strong> pas de site web<br />
                <strong>diraxy - </strong> <a href="https://www.instagram.com/diraxiel/" target="_blank">instagram</a><br />
                <strong>espérance - </strong> pas de site web<br />
                <strong>fcksleeping - </strong> pas de site web<br />
                <strong>felix geyer - </strong> pas de site web<br />
                <strong>flore - </strong> pas de site web<br />
                <strong>grimy - </strong> pas de site web<br />
                <strong>kenzatosaure - </strong> <a href="https://www.instagram.com/kenzatosaure/" target="_blank">instagram</a><br />
                <strong>kooerry - </strong> pas de site web<br />
                <strong>krakhot - </strong> <a href="https://www.kobehs.org/" target="_blank">kobe high school</a><br />
                <strong>lacrimosa - </strong> pas de site web<br />
                <strong>lio - </strong> pas de site web<br />
                <strong>milou - </strong> pas de site web<br />
                <strong>moineau - </strong> <a href="https://viae-miscellea.forumactif.com/" target="_blank">viae miscellea</a><br />
                <strong>morgyn - </strong> pas de site web<br />
                <strong>nana - </strong> <a href="https://spiritedaway.forumactif.com/" target="_blank">spirited away</a><br />
                <strong>palourde - </strong> <a href="https://aurumregis.forumactif.com/" target="_blank">aurum regis</a><br />
                <strong>patd'amande - </strong> pas de site web<br />
                <strong>sed - </strong> <a href="https://mahoutokoro.forumactif.com/" target="_blank">mahoutokoro</a><br />
                <strong>sixtine - </strong> <a href="https://chroma.forumsrpg.com/" target="_blank">chroma</a><br />
                <strong>star - </strong> pas de site web<br />
                <strong>stella - </strong> pas de site web<br />
                <strong>sweety - </strong> <a href="https://sandman.forumactif.com/" target="_blank">sandman</a><br />
                <strong>taku - </strong> pas de site web<br />
                <strong>tarik - </strong> pas de site web<br />
                <strong>turbomwet - </strong> <a href="https://spiritedaway.forumactif.com/" target="_blank">spirited away</a><br />
                <strong>wisukii - </strong> pas de site web<br />
              </div>
            </div><br />
                  L'intégralité du thème a été graphé par <strong>Azria</strong>. De plus, l'intégralité du code de Hall of Fame (qu'il s'agisse de celui
                  de l'accueil ou celui des libres-services) a été codé par <strong>Azria</strong> également. Je remercie tous les faceclaims directory qu'on
                  peut retrouver sur tumblr pour l'inspiration qu'ils m'ont donnés pour coder le libre-service de HoF.<br /><br />

            <em>Hall of Fame 2020—2021 ©</em>
          </>
        )}

        {tab === '404' && (
          <>
            Oops, la page que tu recherches n'existe pas!
                </>
        )}

      </div>
    </div>
  )
};

export default HomeMobile;