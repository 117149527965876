import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './index.css';
import HomeContent from './homeContent';
import HomeMobile from './homeMobile';

const Home = (): JSX.Element => {
  const history = useHistory();
  const [tab, setTab] = useState('accueil');
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  useEffect(() => {
    document.body.classList.add('body-bg');
    if (history.location.pathname === '/') {
      setTab('accueil');
    } else {
      setTab('accueil');
    }
  }, []);

  return (
    <>{ tab === 'travaux' ? (
    <Travaux>
      Coucou je fais la maj c'est en travaux. {':)'}<br/>
      Je bidouille et je casse tout, c'est normal.
    </Travaux>
    ) : (
    <> { isTabletOrMobile ? (
      <>
        <MobileBg>
          <MobileHeader src="https://i.imgur.com/06VotG6.png" alt="header" />
          <RipContainer />
          <MobileContainer className="container">
            <HomeMobile />
          </MobileContainer>
        </MobileBg>
      </>
    ) : (
    <ResponsiveContainer>
      <MainContainer>
        <Container>
          <div className="rip" />
          <div className="container">
            <div className="bestPartos">
            <div className="bestPartosContainer">
              <a href="https://aurumregis.forumactif.com/" target="_blank"><img src="https://i.imgur.com/3z4zUTv.png"/></a>
              <a href="https://chroma.forumsrpg.com/" target="_blank"><img src="https://zupimages.net/up/21/01/6ljh.png"/></a>
              <a href="https://spiritedaway.forumactif.com/" target="_blank"><img src="https://i.imgur.com/NacxZJt.png"/></a>
              <a href="https://mahoutokoro.forumactif.com/"><img src="https://zupimages.net/up/21/08/3lz9.png" /></a>
              <a href="https://sandman.forumactif.com/"><img src="https://i.servimg.com/u/f30/18/70/23/91/tm/50x50_10.png" /></a>
              <a title="kobehs" href="https://www.kobehs.org/" target="_blank"><img src="https://sig.grumpybumpers.com/host/Kobehs50x50.gif" /></a>
              <a href="https://aurora-millenarius.forumactif.org/"><img src="https://i.imgur.com/ArrVQ1d.png" /></a>
              <a href="https://dusk-lumiris.forumactif.com/"><img src="https://www.zupimages.net/up/21/06/qwvt.png"/></a>
              <a href="https://striders.forumactif.com/"><img src="https://i.imgur.com/a4Kz4cZ.png" /></a>
              <a href="https://fe-legions.forumactif.com/" target="blank"><img src="https://www.aht.li/3602617/felparto5050.png"/></a>
              <a href="https://hyleros.forumactif.com/" target="_blank"><img src="https://i.imgur.com/OfaNb6k.png"/></a>
              <a><img src="https://i.imgur.com/4zIEXos.png" /></a>
            </div>
            </div>
            <a href="https://discord.gg/fs8Du8U2TF" target="_blank"><div className="discord">
              <div className="discordbox">
                Rejoins les 50 membres et plus de HoF! A quoi ça sert ? Partager, s'entraider, faire des suggestions...
              </div>
            </div>
            </a>
            <HomeContent />
          </div>
        </Container>
      </MainContainer>
    </ResponsiveContainer>
    )}
    </>
    )}
    </>
  );
};

const RipContainer = styled.div`
  width: 100%;
  height: 67px;
  background: url('https://i.imgur.com/73vxlS0.png') no-repeat;
  margin-top: -67px;
  z-index: 2;
  position: absolute;
`;

const MobileContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #231d20;
  display: flex;
  flex-direction: column;
  /* overflow: auto !important; */
`;

const MobileHeader = styled.img`
  width: 100%;
  height: auto;
  z-index: 1;
`;

const MobileBg = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #231d20;
`;

const Travaux = styled.div`
  width: 100vw;
  height: 100vh;
  background: grey;
  color: white;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResponsiveContainer = styled.div`
  /* display: flex;
  flex-direction:  */
`;

const Container = styled.div`
  width: 732px;
  height: 95%;
  max-height: 1000px;
  bottom: 0;
  /* background: url('https://i.imgur.com/qECOtxA.png') no-repeat, #231d20; */
  box-sizing: border-box;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
`;

export default Home;